import React from "react";
import {Card} from "react-bootstrap";
import {euroFormatter} from "../../../global";
import {useTranslation} from "react-i18next";

const OnlineReservationPriceTable = ({onlineReservation}) => {
  const {t} = useTranslation("reservations");

  return (
    <Card>
      <Card.Body>
        <Card.Title><b>Preisübersicht</b></Card.Title>
        <Card.Text>
          {t("overview.parkingSlotPrice")}: {euroFormatter(onlineReservation.day_price)}<br/>
          {t("overview.personPrice")}: {euroFormatter(onlineReservation.person_price)} <br/>
          {t("overview.localTaxPrice")}: {euroFormatter(onlineReservation.local_tax_price)} <br/>
          {t("overview.dogPrice")}: {euroFormatter(onlineReservation.dog_price)}<br/>
          {t("overview.totalPrice")}: {euroFormatter(onlineReservation.price)}<br/>
          <small>{t("priceTable.inclIva")}</small>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default OnlineReservationPriceTable;