import React, {Suspense} from "react"
import ReactDOM from "react-dom"
import "bootstrap/dist/js/bootstrap.bundle.min"
import "./styles.scss";
import "../src/i18n"
import App from "../src/App";

ReactDOM.render(
  <>
    <Suspense fallback={"loading"}>
      <App/>
    </Suspense>
  </>, document.getElementById("root"))