import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "src/config/axios";
import {Button, Col, Row} from "react-bootstrap";
import Card from "src/components/Cards/Basic";
import InputText from "src/components/Forms/InputText";
import SelectBox from "src/components/Forms/SelectBox";
import {Formik, Form} from "formik";
import CountrySelectBox from "src/components/Forms/CountrySelectBox";
import MunicipalitySelectBox from "src/components/Forms/MunicipalitySelectBox";
import Sidebar from "../../Components/Sidebar";
import {showErrorToast, showSuccessToast} from "src/components/Toasts/";
import {keepState} from "../../../../../components/UiComponents/Buttons/BackButton/helper";
import BackButton from "../../../../../components/UiComponents/Buttons/BackButton";
import history from "../../../../../global/history";

const EditGuest = () => {
  const {bookingId, id} = useParams();
  const [booking, setBooking] = useState();
  const [guestTemplate, setGuestTemplate] = useState({settings: {}});
  const [guest, setGuest] = useState();

  const getBooking = () => {
    axios.get(`/api/v1/bookings/${bookingId}.json`)
    .then(function (response) {
      setBooking(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const getGuest = () => {
    axios.get(`/api/v1/bookings/${bookingId}/guests/${id}.json`)
    .then(function (response) {
      setGuest(response.data);

      setGuestTemplate(guestTemplate => {
        return {...guestTemplate, ...response.data};
      });
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const getGuestTemplate = () => {
    axios.get(`/api/v1/guests/template.json`)
    .then(function (response) {
      setGuestTemplate(response.data);

      if (id) {
        getGuest();
      }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const fillForm = () => {
    const form = {};
    if (guestTemplate != null) {
      form.first_name = guestTemplate.first_name ?? "";
      form.last_name = guestTemplate.last_name ?? "";
      form.gender = guestTemplate.gender ?? "";
      form.document_type_id = guestTemplate.document_type?.id;
      form.birth_date = guestTemplate.birth_date ?? "";
      form.citizenship = guestTemplate.citizenship ?? "";
      form.document_nr = guestTemplate.document_nr ?? "";
      form.birth_place = guestTemplate.birth_place ?? "";
      form.document_place_of_issue = guestTemplate.document_place_of_issue ?? "";
    }
    return form;
  };

  useEffect(() => {
    getBooking();
    getGuestTemplate();
  }, []);

  const submit = (guest) => {
    guest.citizenship_id = guest.citizenship?.id;
    guest.document_type = guest.document_type_id;

    if (guest.citizenship.iso_code === "IT") {
      guest.birth_place_id = guest.birth_place?.id;
      guest.document_place_of_issue_id = guest.document_place_of_issue?.id;
    } else {
      guest.birth_place_id = null;
      guest.document_place_of_issue_id = null;
    }

    let request;

    if (id) {
      request = axios.put(`/api/v1/bookings/${bookingId}/guests/${id}.json`, {guest});
    } else {
      request = axios.post(`/api/v1/bookings/${bookingId}/guests.json`, {guest});
    }

    request.then(function () {
      history.push(`/dashboard/bookings/${bookingId}`, keepState(history));
      showSuccessToast();
    })
    .catch(function (error) {
      showErrorToast(error.response.data);
    });
  };

  return (
    <>
      {booking &&
        <>
          <Row>
            <Col xs={12} sm={7}>
              <Card title={
                <div className="d-flex w-100 align-items-center text-center">
                  <BackButton route={`/dashboard/bookings/${bookingId}`}/> <p className='mb-0'>Gast Daten</p>
                </div>
              }>
                <div className="table-responsive">
                  <Formik
                    initialValues={fillForm()}
                    enableReinitialize={true}
                    validate={(values) => {
                      const errors = {};

                      if (values.first_name === "") {
                        errors.first_name = "Vorname ist ein Pflichtfeld ";
                      }

                      if (values.last_name === "") {
                        errors.last_name = "Nachname ist ein Pflichtfeld ";
                      }

                      if (values.document_nr === "") {
                        errors.document_nr = "Dokumntnr. ist ein Pflichtfeld ";
                      }

                      if (values.birth_date === "") {
                        errors.birth_date = "Geburtsdatum ist ein Pflichtfeld ";
                      }

                      return errors;
                    }}
                    onSubmit={(data, {setSubmitting}) => {
                      setSubmitting(true);

                      submit(data);
                    }}
                  >
                    {({values, isSubmitting}) => (
                      <Form autoComplete="false">
                        <InputText label="Vorname" name="first_name" required/>
                        <InputText label="Nachname" name="last_name" required/>
                        <SelectBox label="Geschlecht" name="gender" options={guestTemplate?.settings.genders}
                                   option_value="id" option_name="name" required/>
                        <InputText label="Geburtsdatum" type="date" name="birth_date" required/>
                        <SelectBox label="Dokumententyp" name="document_type_id"
                                   options={guestTemplate?.settings.document_types} option_value="id" option_name="name"
                                   required/>
                        <InputText label="Dokumentnr." name="document_nr" required/>
                        <CountrySelectBox label="Staatsbürgerschaft" name="citizenship" required/>

                        {values.citizenship?.iso_code === "IT" &&
                          <>
                            <MunicipalitySelectBox label="Dokumentausstellungsort" name="document_place_of_issue"
                                                   required/>
                            <MunicipalitySelectBox label="Geburtsort" name="birth_place"
                                                   required/>
                          </>
                        }
                        <Button disabled={isSubmitting} variant="primary" type="submit">
                          Gast hinzufügen
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </div>
              </Card>
            </Col>
            <Col xs={12} sm={5}>
              <Sidebar booking={booking}/>
            </Col>
          </Row>
        </>
      }
    </>
  );
};

export default EditGuest;
