import React, {useContext, useEffect, useState} from "react";
import Card from "../../../../components/Cards/Basic";
import Dashboard, {DashboardContext} from "../../index";
import axios from "../../../../config/axios";
import {Link, useParams} from "react-router-dom";
import {Button, Col, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import Sidebar from "../Components/Sidebar";
import {showSuccessToast, showErrorToast} from "src/components/Toasts";
import {dateFormatter, dateTimeFormatter, euroFormatter} from "../../../../global";
import SmartPowerBookingModule from "./SmartPowerBookingModule";
import BackButton from "../../../../components/UiComponents/Buttons/BackButton";
import {keepState, modifyState} from "../../../../components/UiComponents/Buttons/BackButton/helper";
import history from "../../../../global/history";
import ConfirmDeleteDialog from "../../../../components/Modal/ConfirmDelete";
import PayInvoiceDialog from "../../../../components/Modal/PayInvoiceDialog";
import {useTranslation} from "react-i18next";
import {faEye as DetailsIcon, faPencil as EditIcon} from "@fortawesome/free-solid-svg-icons";
import TableIconButton from "../../../../components/UiComponents/Buttons/TableIconButton";
import classNames from "classnames";
import useModal from "../../../../components/Modal/hooks/useModal";
import BookingCheckoutDialog from "././BookingCheckoutDialog";

const BookingDetails = ({optin_id}) => {
  const {id = optin_id} = useParams() ?? {};
  const [booking, setBooking] = useState(null);
  const [guests, setGuests] = useState([]);
  const {settings} = useContext(DashboardContext);
  const [selectedDeleteInvoice, setSelectedDeleteInvoice] = useState();
  const [selectedPayInvoice, setSelectedPayInvoice] = useState();
  const {t} = useTranslation("dashboard");
  const {isShow: showCheckoutDialog, toggle: toggleCheckoutDialog} = useModal();
  const getBooking = () => {
    axios.get(`/api/v1/bookings/${id}.json`)
      .then(function (response) {
        setBooking(response.data);
        setGuests(response.data.guests);

        if (response.data.smart_power_booking) {
          axios.get(`/api/v1/smart_power_bookings/${response.data.smart_power_booking_id}.json`).then(function (response) {
            setBooking((prev) => {
              console.log("new ", {...prev, smart_power_booking: response.data})
              return {...prev, smart_power_booking: response.data}
            });
          })
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const activate = () => {
    axios.post(`/api/v1/bookings/${booking.id}/activate.json`)
      .then(function (response) {
        setBooking(response.data);
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  };

  const extend = () => {
    axios.post(`/api/v1/bookings/${booking.id}/extend_booking.json`)
      .then(function (response) {
        setBooking(response.data);
        history.push(`/dashboard/bookings/${response.data.id}/edit`);
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  };

  const checkout = () => {
    axios.post(`/api/v1/bookings/checkout/${booking.nfc_card_uid}.json`)
      .then(function (response) {
        setBooking(response.data);
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  };

  const revertCheckout = () => {
    axios.post(`/api/v1/bookings/${booking.id}/revert_checkout.json`)
      .then(function (response) {
        setBooking(response.data);
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  };

  useEffect(() => {
    getBooking();
  }, []);


  const bookingTitle = <div className="d-flex w-100 align-items-center text-center">
    <BackButton fallbackRoute='/dashboard/bookings'/> <p className='mb-0'>Buchung
    von {booking?.buyer ? booking.buyer.full_name : booking?.id}</p>
  </div>

  return (
    <>
      {booking && (
        <>
          <Row>
            <Col>
              {!booking.paid && <Button className="mb-3" variant="primary"
                                        onClick={() => history.push(`/dashboard/bookings/${id}/guests/new`, keepState(history))}>Gast
                hinzufügen</Button>}
            </Col>
          </Row>
          <Row>
            <Col xs={12} sm={8}>
              <Card title={bookingTitle}>
                <div className="ui-table-container">
                  <table cellSpacing="0">
                    <thead>
                    <tr>
                      <th>Name</th>
                      <th>Land</th>
                      <th>Geburtstag</th>
                      <th>CheckIn</th>
                      <th>CheckOut</th>
                      <th>Optionen</th>
                    </tr>
                    </thead>
                    <tbody>
                    {guests && guests.map(guest => (
                      <tr key={guest.id}>
                        <td>{guest.full_name}</td>
                        <td>{guest.citizenship.name}</td>
                        <td>{dateFormatter(guest.birth_date)}</td>
                        <td>{guest.local_tax_report &&
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                {guest.local_tax_report.reported_at ? "Meldung abgeschlossen" : "Meldung wird gesendet"}
                              </Tooltip>
                            )}
                          >
                          <span className={classNames({
                            "text-success": guest.local_tax_report.reported_at,
                            "text-warning": !guest.local_tax_report.reported_at
                          })}>
                            {dateTimeFormatter(guest.local_tax_report.checkin_at)}
                          </span>
                          </OverlayTrigger>}
                          {booking.state === "active" && !guest.local_tax_report && <a>Check-In durchführen</a>}
                        </td>
                        <td>{guest.local_tax_report &&
                          <OverlayTrigger
                            placement="top"
                            overlay={(
                              <Tooltip>
                                {guest.local_tax_report.departure_confirmed_at ? "Meldung abgeschlossen" : "Meldung wird gesendet"}
                              </Tooltip>
                            )}
                          >
                          <span className={classNames({
                            "text-success": guest.local_tax_report.departure_confirmed_at,
                            "text-warning": !guest.local_tax_report.departure_confirmed_at
                          })}>
                            {dateTimeFormatter(guest.local_tax_report.checkout_at)}
                          </span>
                          </OverlayTrigger>
                        }
                        </td>
                        <td>
                          {(booking.state === "draft" || booking.state === "active") && <TableIconButton
                            icon={EditIcon}
                            variant={"primary"}
                            onClick={() => history.push(`/dashboard/bookings/${booking.id}/guests/${guest.id}/edit`, modifyState(history, location.pathname))}
                          />}
                        </td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
                {!booking.paid &&
                  <Button className="mr-2" variant="primary" onClick={activate}>Aktivieren</Button>}
                {booking.paid && booking.state === "active" &&
                  <Button className="mr-2" variant="primary" disabled={booking.checkout_date}
                          onClick={extend}>Verlängern</Button>}
                {booking.state === 'active' &&
                  <Button className="mr-2" variant="primary"
                          onClick={() => toggleCheckoutDialog()}>Checkout</Button>}
                {booking.checkout_date &&
                  <Button className="mr-2" variant="primary"
                          disabled={(new Date(booking.checkout_date).getMonth() !== new Date().getMonth())}
                          onClick={() => revertCheckout()}>Checkout rückgängig machen</Button>}
              </Card>
              {settings?.smart_power?.enabled && (
                <SmartPowerBookingModule booking={booking} setBooking={setBooking}/>
              )}
            </Col>
            <Col xs={12} sm={4}>
              <Sidebar booking={booking}/>

              {booking.state === "active" &&
                <Card title={`Rechnung`}>
                  <Button className="mr-2" variant="primary" href={`/dashboard/bookings/${booking.id}/invoices/new`}>Rechnung
                    erstellen</Button>
                </Card>
              }

              {(booking.invoices?.map((invoice) => (
                <Card title={`Rechnung`} key={invoice.id}>
                  <ul>
                    <li>Status: {invoice.status === "paid" ? "Bezahlt" : "Unbezahlt"}</li>
                    {invoice.status === "paid" && <li>Datum: {dateTimeFormatter(invoice.paid_at)}</li>}
                    {invoice.status === "paid" &&
                      <li>Bezahlt mit: {t("invoice.payment_types." + invoice.payment_type)}</li>}
                    {invoice.sum_days > 0 && <li>Tagespreis {euroFormatter(invoice.sum_days)}</li>}
                    {invoice.sum_persons > 0 && <li>Gäste: {euroFormatter(invoice.sum_persons)}</li>}
                    {invoice.sum_local_tax > 0 && <li>Ortstaxe: {euroFormatter(invoice.sum_local_tax)}</li>}
                    {invoice.sum_dogs > 0 && <li>Preis Hunde: {euroFormatter(invoice.sum_dogs)}</li>}
                    {invoice.sum_deposit_card > 0 && <li>Kartenkaution: {euroFormatter(invoice.sum_deposit_card)}</li>}
                    {invoice.sum_smart_power > 0 && <li>Stromkosten: {euroFormatter(invoice.sum_smart_power)}</li>}
                    <li>Gesamtpreis: {euroFormatter(invoice.total_sum)}</li>
                  </ul>

                  {(<Button variant={"primary"} className={"mr-1"} onClick={() => {
                      setSelectedPayInvoice(invoice)
                    }}>
                      {invoice.status === "unpaid" ? "Bezahlen" : "Zahlung ändern"}
                    </Button>
                  )}
                  {invoice.status === "unpaid" && (
                    <>
                      <Button variant={"secondary"} href={`/dashboard/bookings/${id}/invoices/${invoice.id}`}
                              className={"mr-1"}>Bearbeiten
                      </Button>
                      <Button variant={"danger"} onClick={() => {
                        setSelectedDeleteInvoice(invoice)
                      }}>Löschen
                      </Button>
                    </>
                  )}
                </Card>
              )))}
            </Col>
          </Row>
          <ConfirmDeleteDialog show={!!selectedDeleteInvoice} handleClose={() => setSelectedDeleteInvoice(null)}
                               handleDelete={() => {
                                 axios.delete(`/api/v1/invoices/${selectedDeleteInvoice.id}.json`).then(function (response) {
                                   setSelectedDeleteInvoice(null);
                                   getBooking();
                                   showSuccessToast("Erfolgreich gelöscht")
                                 }, function (error) {
                                   console.log("error ", error.response)
                                 })
                               }}/>

          <PayInvoiceDialog show={!!selectedPayInvoice} invoice={selectedPayInvoice} onSubmit={() => {
            getBooking();
            setSelectedPayInvoice(null)
          }} onClose={() => {
            setSelectedPayInvoice(null)
          }}/>

          {showCheckoutDialog && <BookingCheckoutDialog
            booking={booking}
            handleClose={toggleCheckoutDialog}
            handleSubmit={() => {
              getBooking();
              toggleCheckoutDialog()
            }}
          />}
        </>
      )}
    </>
  )
};

export default BookingDetails;
