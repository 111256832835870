import axios from "../config/axios";

export function downloadFile(url, filename) {
  axios.get(url, {responseType: 'blob'})
    .then(function (response) {
      const href = URL.createObjectURL(response.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    })
    .catch(function (error) {
      console.log(error);
    });
}
