import moment from "moment";

export const subdomain = window.location.host.split(".")[1] ? window.location.host.split(".")[0] : "";

export const domain = "mh-camping.com";

export function dateTimeFormatterWithDay(dateTime, locale = 'de') {
  return moment(dateTime).locale(locale).format("dd, DD.MM.YYYY - HH:mm");
}

export function dateTimeFormatter(dateTime) {
  return moment(dateTime).format("DD.MM.YYYY - HH:mm");
}

export function dateFormatter(dateTime) {
  return moment(dateTime).format("DD.MM.YYYY");
}

export function timeFormatter(dateTime) {
  return `${moment(dateTime).format('HH:mm')} Uhr`;
}

export function euroFormatter(number) {
  return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(number);
}