import {useEffect, useState} from "react";
import axios from "../../../../../config/axios";

export const useOnlineReservations = () =>{
  const [onlineReservations, setOnlineReservations] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchCollection = (filter) => {
    setLoading(true);
    axios.get(`/api/v1/online_reservations.json`, { params: filter })
      .then(function(response) {
        setOnlineReservations(response.data);
        setLoading(false);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const update = async (onlineReservation) => {
    const response = await axios.put(`/api/v1/online_reservations/${onlineReservation.id}.json`, {online_reservation: onlineReservation})

    setOnlineReservations(prev => prev.map(item => { item.id === response.data.id ? response.data : item }))
  };

  return { collection: onlineReservations.entries, update, fetchCollection, pagination: onlineReservations.pagination, loading: loading }
}