import React, {useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "src/config/axios";
import {FieldArray, Form, Formik} from "formik";
import InputText from "src/components/Forms/InputText";

import {useParams} from "react-router-dom";
import useModal from "../../../../../components/Modal/hooks/useModal";

const EditParkingSlotDialog = ({show, toggle, parking_slot, handleSuccess = null}) => {
  const formRef = useRef();
  const {business_id, id} = useParams();


  if (!show || !parking_slot) {
    return <></>
  }

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header>
        <Modal.Title>Stellplatz {parking_slot.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={parking_slot}
          enableReinitialize={true}
          onSubmit={(values, {setSubmitting}) => {
            setSubmitting(true);
            axios.put(`/api/v1/parking_slots/${parking_slot.id}.json`, values)
              .then(function () {
                if (handleSuccess) {
                  handleSuccess();
                }
                toggle();
              })
              .catch(function (e) {
                console.error(e);
              });
          }}
        >
          {({values, handleChange, setFieldValue, setFieldTouched}) => (
            <Form>
              <InputText name="name" label="Name"/>
              <InputText name="day_price" label="Tagespreis" hint={"Wenn leer, dann wird der festgelegte Tagespreis verrechnet"}/>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Schließen
        </Button>
        <Button variant="primary" onClick={() => formRef.current.handleSubmit()}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditParkingSlotDialog;