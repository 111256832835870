import i18next from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import dashboardDE from "../public/locales/dashboard/de.json";

import reservationsDE from "../public/locales/reservations/de.json";
import reservationsIT from "../public/locales/reservations/it.json";
import reservationsEN from "../public/locales/reservations/en.json";

import commonDE from "../public/locales/common/de.json";
import commonIT from "../public/locales/common/it.json";
import commonEN from "../public/locales/common/en.json";

import errorsDE from "../public/locales/errors/de.json";
import errorsIT from "../public/locales/errors/it.json";
import errorsEN from "../public/locales/errors/en.json";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "de",
    supportedLngs: ["de", "it", "en"],
    ns: ["dashboard", "reservations", "common", "errors"],
    detection: {
      order: ["path"]
    },
    resources: {
      de: {
        dashboard: dashboardDE,
        reservations: reservationsDE,
        common: commonDE,
        errors: errorsDE
      },
      it: {
        reservations: reservationsIT,
        common: commonIT,
        errors: errorsIT
      },
      en: {
        reservations: reservationsEN,
        common: commonEN,
        errors: errorsEN
      }
    }
  })
