import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import axios from "../../../../config/axios";
import OnlineReservationSteps from "../../OnlineReservationSteps";
import history from "../../../../global/history";
import {showValidationErrorToast} from "../../../../components/Toasts";
import useOnlineReservation from "../../hooks/useOnlineReservation";
import {dateFormatter, euroFormatter} from "../../../../global";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {linkBuilder} from "../../../../global/navigationHelper";

const OnlineReservationOverviewPage = () => {
  const {onlineReservation} = useOnlineReservation();
  const { token } = useParams();
  const {t} = useTranslation("reservations");

  const handleSubmit = () => {
    axios.post(`/online_reservations/${token}/request_reservation.json`)
      .then(function (response) {
        window.dataLayer.push({
          'event': 'bookingConfirmation',
          'revenue': onlineReservation.price,
          'transactionId': token
        });

        history.push(`/reservations/thank-you`)
      })
      .catch(function (error) {
        showValidationErrorToast(error);
      });
  };

  if (!onlineReservation) {
    return <></>
  }

  return (
    <>
      <OnlineReservationSteps step={3}/>
      <Row className={"mb-3"}>
        <Col>
          <Row>
            <Col>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.arrival")}:</span>
                <span>{dateFormatter(onlineReservation.from)}</span>
              </div>
            </Col>
            <Col>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.departure")}:</span>
                <span>{dateFormatter(onlineReservation.to)}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={"d-flex justify-content-between"}>
                <span>{t("editContactDetails.first_name.label")}:</span>
                <span>{onlineReservation.contact_first_name}</span>
              </div>
            </Col>
            <Col>
              <div className={"d-flex justify-content-between"}>
                <span>{t("editContactDetails.last_name.label")}:</span>
                <span>{onlineReservation.contact_last_name}</span>
              </div>
            </Col>
          </Row>
          <Row className={"mb-3"}>
            <Col>
              <div className={"d-flex justify-content-between"}>
                <span>{t("editContactDetails.email.label")}:</span>
                <span>{onlineReservation.contact_email}</span>
              </div>
            </Col>
            <Col>
              <div className={"d-flex justify-content-between"}>
                <span>{t("editContactDetails.phone.label")}:</span>
                <span>{onlineReservation.contact_phone}</span>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <h4>{t("overview.priceOverview")}</h4>
              <div className={"d-flex justify-content-between"}>
                <span>{t("common:online_reservation.parking_slot")}:</span>
                <span>{onlineReservation.parking_slot?.name || t("overview.noParkingSlotSelected")}</span>
              </div>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.parkingSlotPrice")}:</span>
                <span>{euroFormatter(onlineReservation.day_price)}</span>
              </div>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.personPrice")}:</span>
                <span>{euroFormatter(onlineReservation.person_price)}</span>
              </div>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.localTaxPrice")}:</span>
                <span>{euroFormatter(onlineReservation.local_tax_price)}</span>
              </div>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.dogPrice")}:</span>
                <span>{euroFormatter(onlineReservation.dog_price)}</span>
              </div>
              <div className={"d-flex justify-content-between"}>
                <span>{t("overview.totalPrice")}:</span>
                <span>{euroFormatter(onlineReservation.price)}</span>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={3}>

        </Col>
      </Row>
      <Row>
        <Col md={9}>
          <div className={"d-flex justify-content-between mb-3"}>
            <Link to={linkBuilder(`/reservations/${onlineReservation.token}/contact-details`)}>
              <Button variant={"secondary"}>
                {t("common:components.button.back")}
              </Button>
            </Link>
            <Button onClick={handleSubmit}>
              {t("overview.bookNow")}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OnlineReservationOverviewPage;