import React, {useState} from "react";
import {Button, Card, Col, Container, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import InputText from "../../../../components/Forms/InputText";
import {Link, useParams} from "react-router-dom";
import OnlineReservationSteps from "../../OnlineReservationSteps";
import history from "../../../../global/history";
import {showErrorToast} from "../../../../components/Toasts";
import useOnlineReservation from "../../hooks/useOnlineReservation";
import OnlineReservationPriceTable from "../../OnlineReservationPriceTable";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {linkBuilder, navigateTo} from "../../../../global/navigationHelper";

const EditOnlineReservationContactDetailsPage = () => {
  const {onlineReservation, update} = useOnlineReservation();
  const {t} = useTranslation("reservations");
  const [dateRange, setDateRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      key: "selection",
    },
  ]);

  const handleDateRangeChange = (item) => {
    console.log(item.selection);
    setDateRange([item.selection]);
  };

  const handleSubmit = async (values) => {
    try {
      const onlineReservation = await update(values);
      navigateTo(`/reservations/${onlineReservation.token}/overview`)
    } catch (e) {
      console.error(e)
      showErrorToast(e.message);
    }
  };


  if (!onlineReservation) {
    return <></>
  }

  return (
    <>
      <OnlineReservationSteps step={2}/>
      <Formik
        enableReinitialize={true}
        initialValues={{...onlineReservation}}
        onSubmit={handleSubmit}
        validationSchema={() => {
          return Yup.object({
            contact_first_name: Yup.string().required(t("errors:errors.general.required")),
            contact_last_name: Yup.string().required(t("errors:errors.general.required")),
            contact_email: Yup.string().required(t("errors:errors.general.required")),
            contact_phone: Yup.string().required(t("errors:errors.general.required"))
          })
        }
        }>
        {({}) => (
          <Form autoComplete="false">
            <Row className={"mb-3"}>
              <Col md={9}>
                <Row>
                  <Col>
                    <h2>{t("editContactDetails.title")}</h2>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <InputText name="contact_first_name" label={t("editContactDetails.first_name.label")} required={true}/>
                  </Col>
                  <Col xs={12} sm={6}>
                    <InputText name="contact_last_name" label={t("editContactDetails.last_name.label")} required={true}/>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={6}>
                    <InputText name="contact_email" label={t("editContactDetails.email.label")}
                               hint={t("editContactDetails.email.hint")} type={"email"} required={true}/>
                  </Col>
                  <Col xs={12} sm={6}>
                    <InputText name="contact_phone" label={t("editContactDetails.phone.label")} hint={t("editContactDetails.phone.hint")}
                               placeholder={t("editContactDetails.phone.placeholder")} type={"tel"} required={true}/>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <OnlineReservationPriceTable onlineReservation={onlineReservation}/>
              </Col>
            </Row>
            <Row>
              <Col md={9}>
                <div className={"d-flex justify-content-between mb-3"}>
                  <Link to={linkBuilder(`/reservations/${onlineReservation.token}/booking-details`)}>
                    <Button variant={"secondary"}>
                      {t("common:components.button.back")}
                    </Button>
                  </Link>
                  <Button type={"submit"}>
                    {t("common:components.button.next")}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EditOnlineReservationContactDetailsPage;