import React, {useContext, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {Form, Formik} from "formik";
import OnlineReservationSteps from "../../OnlineReservationSteps";
import SelectBox from "../../../../components/Forms/SelectBox";
import useOnlineReservation from "../../hooks/useOnlineReservation";
import {showErrorToast} from "../../../../components/Toasts";
import * as Yup from 'yup';
import {dateFormatter} from "../../../../global";
import {Form as BTForm} from "react-bootstrap"
import {Link} from "react-router-dom";
import OnlineReservationPriceTable from "../../OnlineReservationPriceTable";
import OnlineReservationDateRangePicker from "../../OnlineReservationDateRangePicker";
import {OnlineReservationContext} from "../../index";
import {navigateTo} from "../../../../global/navigationHelper";
import {useTranslation} from "react-i18next";

const EditOnlineReservationBookingDetailsPage = () => {
  const {update, onlineReservation} = useOnlineReservation();
  const {t} = useTranslation("reservations");
  const {business} = useContext(OnlineReservationContext);

  const [showDateRange, setShowDateRange] = useState(false);

  const handleSubmit = async (values) => {
    try {
      const onlineReservation = await update(values);
      navigateTo(`/reservations/${onlineReservation.token}/contact-details`)
    } catch (e) {
      console.error(e)
      showErrorToast(e.message);
    }
  };

  if (!onlineReservation) {
    return <></>
  }

  return (
    <>
      <OnlineReservationSteps step={1}/>
      <Formik
        enableReinitialize={true}
        initialValues={{...onlineReservation}}
        onSubmit={handleSubmit}
        validationSchema={() => {
          return Yup.object({
            parking_slot_id: Yup.number().required(t("errors:errors.general.required")),
            amount_guests: Yup.number().min(1, t("errors:errors.general.required")).required(),
            amount_kids: Yup.number().min(0, t("errors:errors.general.required")).required(),
            amount_dogs: Yup.number().min(0, t("errors:errors.general.required")).required()
          })
        }
        }>
        {({values, setFieldValue}) => {
          function addChildBox(childNr){
            let ages = [{name: t("editBookingDetails.kidsAge", {count: 0}), value: 0}];
            for(let i = 1; i <= 17; i++) {
              ages.push({name: t("editBookingDetails.kidsAge", {count: i}), value: i});
            }

            return (
              <SelectBox
                name={`kid_${childNr}_age`}
                label={`Kind ${childNr}`}
                placeholder={"Alter eingeben"}
                options={ages}
                option_name={"name"}
                option_value={"value"}
                required={true}
                onChange={(e) => {
                  update({...onlineReservation, [`kid_${childNr}_age`]: e.target.value})
                }}
              />
            )
          }

          return (
            <Form autoComplete="false">
              <Row>
                <Col>
                  <h2>{t("editBookingDetails.title")}</h2>
                </Col>
              </Row>
              <Row>
                <Col md={9}>
                  <BTForm.Group className="mb-3">
                    <BTForm.Label>{t("editBookingDetails.stay")}</BTForm.Label>
                    <div onClick={() => {
                      setShowDateRange(!showDateRange)
                    }}>
                      <BTForm.Control
                        value={`${dateFormatter(onlineReservation.from)} - ${dateFormatter(onlineReservation.to)}`}
                        onChange={() => {
                        }}
                      />
                    </div>
                  </BTForm.Group>

                  {showDateRange && (
                    <OnlineReservationDateRangePicker
                      from={new Date(values.from)}
                      to={new Date(values.to)}
                      setFieldValue={setFieldValue}
                      onChange={(from, to) => {
                        update({...onlineReservation, from: from, to: to})
                      }}
                    />
                  )}
                  <Row className={"align-items-end"}>
                    <Col className={"mb-3"}>
                      <SelectBox label={t("editBookingDetails.selectParkingSlot")}
                                 options={values.configuration.available_parking_slots}
                                 option_value="id"
                                 option_name="name"
                                 name="parking_slot_id"
                                 className={"mb-0"}
                                 required={true}
                                 onChange={(e) => {
                                   update({...onlineReservation, parking_slot_id: e.target.value})
                                 }}
                      />
                      <Link target={"_blank"} to={{pathname: business?.camp_site_map?.url}}>
                        {t("editBookingDetails.downloadCampsite")}
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <SelectBox name="amount_guests" label={t("editBookingDetails.amountAdults")}
                                 type={"number"}
                                 required={true}
                                 options={[
                                   {name: t("common:online_reservation.amountAdults", {count: 1}), value: 1},
                                   {name: t("common:online_reservation.amountAdults", {count: 2}), value: 2},
                                   {name: t("common:online_reservation.amountAdults", {count: 3}), value: 3},
                                   {name: t("common:online_reservation.amountAdults", {count: 4}), value: 4},
                                   {name: t("common:online_reservation.amountAdults", {count: 5}), value: 5},
                                   {name: t("common:online_reservation.amountAdults", {count: 6}), value: 6}]}
                                 option_name={"name"}
                                 option_value={"value"}
                                 onChange={(e) => {
                                   update({...onlineReservation, amount_guests: e.target.value || 0})
                                 }}
                      >
                      </SelectBox>
                    </Col>
                    <Col>
                      <SelectBox name="amount_kids" label={t("editBookingDetails.amountKids")}
                                 type={"number"}
                                 required={true}
                                 options={[
                                   {name: t("common:online_reservation.amountKids", { count: 0 }), value: 0},
                                   {name: t("common:online_reservation.amountKids", { count: 1 }), value: 1},
                                   {name: t("common:online_reservation.amountKids", { count: 2 }), value: 2},
                                   {name: t("common:online_reservation.amountKids", { count: 3 }), value: 3},
                                   {name: t("common:online_reservation.amountKids", { count: 4 }), value: 4}]}
                                 option_name={"name"}
                                 option_value={"value"}
                                 onChange={(e) => {
                                   update({...onlineReservation, amount_kids: e.target.value || 0})
                                 }}
                      >
                      </SelectBox>
                    </Col>
                  </Row>
                  {onlineReservation.amount_kids > 0 && (
                    <Row>
                      <Col>
                        { onlineReservation.amount_kids >= 1 && addChildBox(1) }
                        { onlineReservation.amount_kids >= 2 && addChildBox(2) }
                        { onlineReservation.amount_kids >= 3 && addChildBox(3) }
                        { onlineReservation.amount_kids >= 4 && addChildBox(4) }
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <SelectBox name="amount_dogs" label={t("editBookingDetails.amountDogs")}
                                 type={"number"}
                                 required={true}
                                 options={[
                                   {name: t("common:online_reservation.amountDogs", { count: 0 }), value: 0},
                                   {name: t("common:online_reservation.amountDogs", { count: 1 }), value: 1},
                                   {name: t("common:online_reservation.amountDogs", { count: 2 }), value: 2},
                                   {name: t("common:online_reservation.amountDogs", { count: 3 }), value: 3},
                                   {name: t("common:online_reservation.amountDogs", { count: 4 }), value: 4}]}
                                 option_name={"name"}
                                 option_value={"value"}
                                 onChange={(e) => {
                                   update({...onlineReservation, amount_dogs: e.target.value || 0})
                                 }}
                      >
                      </SelectBox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={"d-flex justify-content-between mb-3"}>
                        <div></div>
                        <Button type={"submit"}>
                          {t("common:components.button.next")}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <OnlineReservationPriceTable onlineReservation={onlineReservation}/>
                </Col>
              </Row>
            </Form>
          )}}
      </Formik>
    </>
  );
};

export default EditOnlineReservationBookingDetailsPage;