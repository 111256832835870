import React, {useEffect, useState} from "react";
import {Pagination} from "react-bootstrap";

const SimplePagination = ({ pagination, onClick }) => {
  const [items, setItems] = useState([]);

  const maxVisiblePages = 4;

  useEffect(() => {
    let pageItem = [];

    if (pagination) {
      const startPage = Math.ceil(Math.max(1, pagination.current - (maxVisiblePages / 2)));
      const lastPage = Math.min(pagination.pages, (pagination.current) + maxVisiblePages / 2);

      for (let page = startPage; page <= lastPage; page++) {
        pageItem.push(<Pagination.Item key={page} active={page === pagination.current} activeLabel="" onClick={() => onClick(page)}>{page}</Pagination.Item>);
      }
      setItems(pageItem);
    }
  }, [pagination]);

  if (!pagination || pagination.pages === 1) {
    return <></>;
  }

  return (
    <Pagination>
      <Pagination.Prev disabled={!pagination.prev} onClick={() => onClick(pagination.prev)}/>
      {items && items.map((item) => (item))}
      <Pagination.Next disabled={!pagination.next} onClick={() => onClick(pagination.next)}/>
    </Pagination>
  );
};

export default SimplePagination;
