import {dateTimeFormatter, euroFormatter} from "../../../../global";
import React, {useEffect, useState} from "react";
import InfoList from "../../../../components/InfoList";
import InfoListItem from "../../../../components/InfoListItem";
import {Button, Dropdown, Modal, Spinner} from "react-bootstrap";
import axios from "../../../../config/axios";
import OnlineReservationDeclineDialog from "./OnlineReservationDeclineDialog";
import {useTranslation} from "react-i18next";

const OnlineReservationDetailsDialog = ({onlineReservationId, handleClose, handleSubmit}) => {
  const [showDeclineDialog, setShowDeclineDialog] = useState(false);
  const {t} = useTranslation("dashboard");

  const [onlineReservation, setOnlineReservation] = useState(null);

  useEffect(() => {
    axios.get(`api/v1/online_reservations/${onlineReservationId}.json`).then(function (response) {
      setOnlineReservation(response.data);
    });
  }, [])

  const accept = async () => {
    await axios.post(`/api/v1/online_reservations/${onlineReservation.id}/accept.json`)
  };

  return (
    <>
      <Modal onHide={handleClose} show={!showDeclineDialog}>
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {onlineReservation ? (
            <InfoList>
              <InfoListItem name={"ID"} value={onlineReservation.id}/>
              <InfoListItem name={"Status"} value={t(`online_reservation.statuses.${onlineReservation.status}`)}/>
              {onlineReservation.reservation_code && <InfoListItem name={t("online_reservation.reservation_code")}
                                                                   value={t(onlineReservation.reservation_code)}/>}
              <InfoListItem name={"Stellplatz"} value={onlineReservation?.parking_slot?.name}/>
              <InfoListItem name={"Anreise"} value={dateTimeFormatter(onlineReservation.from)}/>
              <InfoListItem name={"Abreise"} value={dateTimeFormatter(onlineReservation.to)}/>
              <InfoListItem name={"Anzahl Gäste (14+ Jahre)"} value={onlineReservation.amount_guests}/>
              <InfoListItem name={"Anzahl Gäste (<14 Jahre)"}
                            value={onlineReservation.amount_kids}/>
              <InfoListItem name={"Anzahl Hunde"} value={onlineReservation.amount_dogs}/>
              <InfoListItem name={"Gesamtpreis"} value={euroFormatter(onlineReservation.price)}/>
              <hr/>
              <InfoListItem name={"Kontaktperson"} value={onlineReservation.contact_person}/>
              <InfoListItem name={"E-Mail"} value={onlineReservation.contact_email}/>
              <InfoListItem name={"Telefonnummer"} value={onlineReservation.contact_phone}/>
            </InfoList>
          ) : (
            <div className={"d-flex justify-content-center"}>
            <Spinner className={"text-center"} animation="border" variant="primary"/>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          { onlineReservation?.status !== "redeemed" &&
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic">
                Status ändern
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {onlineReservation?.status !== "accepted" && (
                  <Dropdown.Item onClick={async () => {
                    await accept()
                    handleSubmit()
                  }}>
                    Annehmen
                  </Dropdown.Item>
                )}
                {onlineReservation?.status !== "declined" && (
                  <Dropdown.Item onClick={() => {
                    setShowDeclineDialog(true)
                  }}>Stornieren
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          }
        </Modal.Footer>
      </Modal>
      <OnlineReservationDeclineDialog onlineReservationId={onlineReservationId} setIsShow={setShowDeclineDialog}
                                      isShow={showDeclineDialog} handleSubmit={handleSubmit} handleClose={() => {
        setShowDeclineDialog(false)
      }}/>
    </>
  )
}

export default OnlineReservationDetailsDialog;