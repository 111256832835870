import React, { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import axios from "src/config/axios";
import { Form, Formik } from "formik";
import InputText from "src/components/Forms/InputText";

import {useParams} from "react-router-dom";

const EditPowerPillarDialog = ({ show, toggle, shelly = {}, handleSuccess = null }) => {
  const formRef = useRef();
  const { id } = useParams();

  const fillForm = () => {
    const form = {};
    form.name = shelly.name ?? "";

    return form;
  };

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header>
        <Modal.Title>{!shelly ? "Erstellen" : "Bearbeiten"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={fillForm()}
          enableReinitialize={true}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            let request;

            if (shelly.id) {
              request = axios.put(`/api/v1/admin/businesses/${id}/power_pillars/${shelly.id}.json`, values);
            }
            else {
              request = axios.post(`/api/v1/admin/businesses/${id}/power_pillars.json`, values);
            }

            request.then(function() {
              toggle();

              if (handleSuccess) {
                handleSuccess();
              }
            })
              .catch(function(e) {
                console.error(e);
              });
          }}
        >
          {({  }) => (
            <Form autoComplete="false">
              <InputText name="name" label="Name"/>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Schließen
        </Button>
        <Button variant="primary" onClick={() => formRef.current.handleSubmit()}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditPowerPillarDialog;