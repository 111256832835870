import React from "react";

const Legend = ({ children }) => {
  return (
    <div className="border rounded d-flex flex p-2 flex-wrap">
      {children.map((child, index) => (
        <div className="d-flex" key={index}>
          {child}
          {children.length !== index + 1 && <div className="mx-3">|</div> }
        </div>
      ))}
    </div>
  );
};

export default Legend;
