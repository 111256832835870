import React from "react";
import {Button} from "react-bootstrap";
import {showErrorToast, showSuccessToast} from "src/components/Toasts";
import {useState} from "react";
import axios from "../../../../../config/axios";
import Card from "src/components/Cards/Basic";
import EditSmartPowerBookingModuleDialog from "./EditDialog";
import {dateTimeFormatter, euroFormatter} from "../../../../../global";

const SmartPowerBookingModule = ({booking, setBooking}) => {
  const smartPowerBooking = booking?.smart_power_booking;
  const [showSmartPowerDialog, setShowSmartPowerDialog] = useState(false);


  function deleteSmartPowerBooking() {
    axios.delete(`/api/v1/smart_power_bookings/${smartPowerBooking.id}.json`, {})
      .then(function (response) {
        setBooking((prevState) => {
          return { ...prevState, smart_power_booking: null }
        });
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  }

  function pay() {
    axios.post(`/api/v1/smart_power_bookings/${smartPowerBooking.id}/pay.json`)
      .then(function (response) {
        history.go(0);
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  }

  function deactivate() {
    axios.post(`/api/v1/smart_power_bookings/${smartPowerBooking.id}/deactivate.json`)
      .then(function (response) {
        setBooking((prevState) => {
          return { ...prevState, smart_power_booking: response.data }
        });
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  }

  if (!booking) {
    return <></>
  }

  if (smartPowerBooking?.type_of === 'consumption') {
    return (
      <>
        <Card title={"Strom"}>
          <p>Verbrauch: {smartPowerBooking.usage} Watt Stunden </p>
          <p>Preis: {euroFormatter(smartPowerBooking.price)}</p>
          <p>Steckdose: {smartPowerBooking.shelly_channel ? smartPowerBooking.shelly_channel.formatted_location : "Keine Steckdose ausgewählt"}</p>

          {!smartPowerBooking.paid && (
            <>
              <div className="mb-2">
                <Button className="mr-1" onClick={() => setShowSmartPowerDialog(true)}>Bearbeiten</Button>
                {smartPowerBooking.shelly_channel_id && <Button onClick={deactivate}>Ausschalten</Button>}
              </div>
              {booking.paid && <Button className="mr-1" onClick={pay}>Bezahlen</Button>}
              <Button variant={"danger"} onClick={deleteSmartPowerBooking}>Löschen</Button>
            </>
          )}
        </Card>
      </>
    );
  }

  return (
    <>
      <Card title={"Strom"}>
        {!smartPowerBooking && (
          <Button onClick={() => setShowSmartPowerDialog(true)}>Strom freischalten</Button>
        )}

        {smartPowerBooking && (
          <>
            Von: {dateTimeFormatter(smartPowerBooking.from)}<br/>
            Bis: {dateTimeFormatter(smartPowerBooking.to)}<br/>
            { !smartPowerBooking.paid && <>Preis: {euroFormatter(smartPowerBooking.price)}</>}
            <div className="mt-2">
              {booking.paid && !smartPowerBooking.paid && <Button className="mr-1" onClick={pay}>Bezahlen</Button>}
              <Button className="mr-1" onClick={() => setShowSmartPowerDialog(true)}>Bearbeiten</Button>
              {smartPowerBooking.shelly_channel_id && <Button onClick={deactivate}>Ausschalten</Button>}
              { !smartPowerBooking.paid && <Button variant={"danger"} onClick={deleteSmartPowerBooking}>Löschen</Button> }
            </div>
          </>
        )}
      </Card>
      <EditSmartPowerBookingModuleDialog
        show={showSmartPowerDialog}
        handleClose={() => setShowSmartPowerDialog(false)}
        booking={booking}
        handleSubmit={(smartPowerBooking) => {
          setBooking((prevState) => {
            return { ...prevState, smart_power_booking: smartPowerBooking }
          });
          setShowSmartPowerDialog(false);
        }}
      />
    </>
  );
}


export default SmartPowerBookingModule;