// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InfoListItem-module__item___N7hps {\n    display: flex;\n    justify-content: space-between;\n}\n\n.InfoListItem-module__name___3TTxv {\n    flex: 1 1;\n    text-align: left;\n    margin-right: 10px;\n}\n\n.InfoListItem-module__value___2wgJG {\n    flex: 1 1;\n}", "",{"version":3,"sources":["InfoListItem.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,SAAO;IACP,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,SAAO;AACX","file":"InfoListItem.module.css","sourcesContent":[".item {\n    display: flex;\n    justify-content: space-between;\n}\n\n.name {\n    flex: 1;\n    text-align: left;\n    margin-right: 10px;\n}\n\n.value {\n    flex: 1;\n}"]}]);
// Exports
exports.locals = {
	"item": "InfoListItem-module__item___N7hps",
	"name": "InfoListItem-module__name___3TTxv",
	"value": "InfoListItem-module__value___2wgJG"
};
module.exports = exports;
