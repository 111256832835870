import {Button, Form as BTForm} from "react-bootstrap";
import Card from "../../../../components/Cards/Basic";
import {FieldArray, Form, Formik} from "formik";
import InputText from "../../../../components/Forms/InputText";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "../../../../config/axios";
import {showSuccessToast} from "../../../../components/Toasts";
import history from "../../../../global/history";

const EditBookingInvoice = () => {
  const {bookingId, id} = useParams();
  const [invoice, setInvoice] = useState({id: null, items: [{description: "", quantity: 1, unit_price: 0}]});

  useEffect(() => {
    if (id) {
      fetchInvoice();
    }
  }, []);

  function fetchInvoice() {
    axios.get(`/api/v1/invoices/${id}.json`).then(function (response) {
      setInvoice(response.data);
    })
  }

  function updateInvoice(data) {
    if (id) {
      axios.put(`/api/v1/invoices/${id}.json`, {invoice: data}).then(function (response) {
        setInvoice(response.data);
        showSuccessToast();
        history.push(`/dashboard/bookings/${bookingId}`)
      })
    } else {
      axios.post(`/api/v1/bookings/${bookingId}/invoices.json`, {invoice: data}).then(function (response) {
        setInvoice(response.data);
        showSuccessToast();
        history.push(`/dashboard/bookings/${bookingId}`)
      })
    }
  }

  if (!invoice) {
    return <></>;
  }

  console.log("invoice ", invoice)

  return (
    <Card title="Rechnung">
      <Formik
        initialValues={{...invoice, items_attributes: invoice.items}}
        enableReinitialize={true}
        validate={(values) => {
          const errors = {};

          return errors;
        }}
        onSubmit={(data, {setSubmitting}) => {
          setSubmitting(true);

          updateInvoice(data);
        }}
      >
        {({values, setFieldValue, handleChange}) => (
          <Form autoComplete="false">
            <FieldArray name="items_attributes">
              {({insert, replace, push}) => (
                <div>
                  {values.items_attributes.map((item, index) => {
                      const total_price = item.unit_price * item.quantity;

                      if (!item._destroy) {
                        return (
                          <div className={"d-flex align-items-end"} key={index}>
                            <div className={"mr-1"} style={{width: "500px"}}>
                              <InputText label="Beschreibung" name={`items_attributes.${index}.description`} required
                                         placeholder={"Beschreibung"}/>
                            </div>
                            <div className={"w-80px mr-1"}>
                              <InputText label="Anzahl" name={`items_attributes.${index}.quantity`} required/>
                            </div>
                            <div className={"mr-1"} style={{width: "120px"}}>
                              <InputText label="Einzelpreis" name={`items_attributes.${index}.unit_price`} required/>
                            </div>
                            <div className={"mr-1"} style={{width: "120px"}}>
                              <BTForm.Group className="mb-3">
                                <BTForm.Label>Gesamtpreis</BTForm.Label>
                                <BTForm.Control value={total_price} readOnly={true}/>
                              </BTForm.Group>
                            </div>
                            <Button variant={"danger"} className={"mr-1 mb-3 h-50"} onClick={() => {
                              replace(index, {...item, _destroy: true});
                            }}>
                              Löschen
                            </Button>
                          </div>)
                      }
                    }
                  )}

                  <Button variant={"secondary"} className={"mr-1 mb-3 h-50"} onClick={() => {
                    insert(values.items_attributes.length, {quantity: 1, unit_price: 0, description: ""})
                  }}>
                    Artikel hinzufügen
                  </Button>
                </div>
              )}
            </FieldArray>
            <Button variant="primary" className={"mr-1"} type="submit">
              Speichern
            </Button>
            <Button variant={"warning"} className={"mini"} onClick={() => {
              axios.get(`/api/v1/bookings/${bookingId}/invoices/template.json`).then(function (response) {
                const oldItems = values.items_attributes.map((item) => ({...item, _destroy: true }));
                const newItems = [...oldItems, ...response.data.items]
                setInvoice((prev) => ({...prev, items: newItems}))
              })
            }}>
              Rechnung aus Buchung erstellen
            </Button>
          </Form>
        )}
      </Formik>
    </Card>

  );
};

export default EditBookingInvoice;