import {Button, Col, Row} from "react-bootstrap";
import Card from "../../../../components/Cards/Basic";
import {Form, Formik} from "formik";
import SelectBox from "../../../../components/Forms/SelectBox";
import InputText from "../../../../components/Forms/InputText";
import Sidebar from "../Components/Sidebar";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {DashboardContext} from "../../index";
import axios from "../../../../config/axios";
import {showErrorToast, showSuccessToast} from "../../../../components/Toasts";
import moment from "moment";
import {keepState} from "../../../../components/UiComponents/Buttons/BackButton/helper";
import history from "../../../../global/history";
import BackButton from "../../../../components/UiComponents/Buttons/BackButton";

const EditBooking = () => {
  const { id } = useParams();
  const [dogs, _] = useState([{ value: 0, name: 0 }, { value: 1, name: 1 }, { value: 2, name: 2 }, {
    value: 3,
    name: 3
  }]);

  const [booking, setBooking] = useState(null);
  const [bookingSettings, setBookingSettings] = useState(null);
  const { settings } = useContext(DashboardContext);

  useEffect(() => {
    fetchBooking();
  }, []);

  useEffect(() => {
    axios.get(`/api/v1/bookings/${id}/settings.json`).then(function(response) {
      setBookingSettings(response.data);
    });
  }, [booking]);


  const fetchBooking = () => {
    axios.get(`/api/v1/bookings/${id}.json`)
      .then(function(response) {
        setBooking(response.data);
      }).catch(function(error) {
      console.log(error);
    });
  };

  const updateBooking = async (booking) => {
    axios.put(`/api/v1/bookings/${id}.json`, { booking }).then((response) => {
      setBooking(response.data);
    }).catch(function(error) {
      console.log(error);
    });
  };

  const submit = (booking) => {
    let request = axios.put(`/api/v1/bookings/${id}.json`, { booking });

    request.then(function(response) {
      history.push(`/dashboard/bookings/${response.data.id}`, keepState(history));
      showSuccessToast();
    })
      .catch(function(error) {
        showErrorToast(error.response.data);
      });
  };

  const fillForm = () => {
    const form = {};

    form.nfc_card_uid = booking?.nfc_card_uid || "";
    form.amount_dogs = booking?.amount_dogs || 0;
    form.license_plate = booking?.license_plate || "";
    form.parking_slot_id = booking?.parking_slot_id || "";

    form.booked_from = booking?.booked_from ? moment(booking?.booked_from).format(moment.HTML5_FMT.DATETIME_LOCAL) : moment().format(moment.HTML5_FMT.DATETIME_LOCAL);

    if (booking && booking?.booked_until) {
      form.booked_until = moment(booking.booked_until).format(moment.HTML5_FMT.DATETIME_LOCAL);
    }

    return form;
  };

  if (!booking || !bookingSettings || !settings) {
    return <></>;
  }

  return (
    <Row>
      <Col xs={12} sm={7}>
        <Card title={
          <div className="d-flex w-100 align-items-center text-center">
            <BackButton route={`/dashboard/bookings/${booking?.id}`}/> <p className='mb-0'>Buchungsdaten</p>
          </div>
        }>
          <Formik
            initialValues={fillForm()}
            enableReinitialize={true}
            validate={(values) => {
              const errors = {};

              return errors;
            }}
            onSubmit={(data, { setSubmitting }) => {
              setSubmitting(true);

              submit(data);
            }}
          >
            {({ values, setFieldValue, handleChange }) => (
              <Form autoComplete="false">
                { !booking?.paid && (
                  <>
                    <InputText name="booked_from"
                               label="Buchung von"
                               type="datetime-local"
                               disabled={booking?.booking_type?.id !== "new"}
                               onChange={(value) => {
                                 handleChange(value);
                                 updateBooking({ values, booked_from: value.target.value });
                               }}/>
                    <InputText name="booked_until"
                               label="Buchung bis"
                               type="datetime-local"
                               min={booking.booking_type.id === "new" ? values.booked_from : moment(booking.parent.booked_until).format(moment.HTML5_FMT.DATETIME_LOCAL)}
                               onChange={(value) => {
                                 handleChange(value);
                                 updateBooking({ values, booked_until: value.target.value });
                               }}/>
                  </>
                )}

                { settings?.business?.parking_slots_enabled && (
                  <SelectBox label="Stellplatz auswählen"
                             name="parking_slot_id"
                             options={bookingSettings.parking_slots}
                             hint={"Leer lassen, wenn der Stellplatz nicht geändert werden soll."}
                             option_name="name"
                             option_value="id"
                             required/>
                )}

                <InputText name="nfc_card_uid"
                           label="NFC Kartennummer"
                           hint="Die Kartennummer kann mit einem NFC Lesegerät ausgelesen werden"
                />
                {!booking.paid && settings?.business?.guest_registration_enabled && (
                  <>
                    <SelectBox label="Anzahl an Hunden"
                               name="amount_dogs"
                               options={dogs}
                               option_name="name"
                               option_value="value"
                               required/>

                  </>
                )}
                <Button variant="primary" type="submit">
                  Speichern
                </Button>
              </Form>
            )}
          </Formik>
        </Card>
      </Col>
      <Col xs={12} sm={5}>
        <Sidebar/>
      </Col>
    </Row>
  );
};

export default EditBooking;