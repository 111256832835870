import React, {useEffect, useState} from "react";
import axios from "src/config/axios";
import {showErrorToast, showSuccessToast} from "src/components/Toasts";

const useShellys = ({ business_id, power_pillar_id }) => {
  const [shellys, setShellys] = useState([]);

    const fetchShellys = () => {
      axios.get(`/api/v1/admin/businesses/${business_id}/power_pillars/${power_pillar_id}/shellys.json`)
        .then(function(response) {
          setShellys(response.data.entries);
        })
        .catch(function(error) {
          console.log(error);
        });
    };

    const archive = (shelly) => {
      axios.delete(`/api/v1/admin/businesses/${business_id}/shellys/${shelly.id}.json`)
        .then(function() {
          fetchShellys();
        })
        .catch(function(error) {
          showErrorToast(error.response.data);
        });
  };

  const fetchStatus = (shelly) => {
    axios.get(`/api/v1/admin/businesses/${business_id}/shellys/${shelly.id}/status.json`)
      .then(function() {
        fetchShellys();
        showSuccessToast("Liste aktualisiert");
      })
      .catch(function(error) {
        showErrorToast(error.response.data);
      });
  };

  return { shellys, fetchShellys, archive, fetchStatus }
};

export default useShellys;
