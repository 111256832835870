import React, {createContext, useEffect, useState} from "react";
import OnlineReservationHeader from "././OnlineReservationHeader";
import {Container} from "react-bootstrap";
import axios from "../../config/axios";

export const OnlineReservationContext = createContext(null);

const OnlineReservations = ({children}) => {
  const [settings, setSettings] = useState({});

  useEffect(() => {
    axios.get(`/businesses/me.json`)
      .then(function (response) {
        setSettings({business: response.data});
      })
      .catch(function (error) {
        console.log(error)
      })
  }, []);

  return (
    <OnlineReservationContext.Provider value={settings}>
      <Container className={"mt-3"}>
        <OnlineReservationHeader/>
        {children}
      </Container>
    </OnlineReservationContext.Provider>
  );
};

export default OnlineReservations;