import {toast} from 'react-toastify';

export const showSuccessToast = (text = "Erfolgreich gespeichert") => {
  if (Array.isArray(text)) {
    text = text.join(", ");
  }

  toast.success(text);
};


export const showErrorToast = (text = "Interner Fehler ist aufgetreten") => {
  if (Array.isArray(text)) {
    text = text.join(", ");
  }

  toast.error(text);
};

export const showValidationErrorToast = (error) => {
  const errors = error.response.data;

  const keys = Object.keys(errors);

  if (errors["base"]) {
    toast.error(errors["base"][0]);
  } else if (keys.length > 0) {
    toast.error(errors[keys[0]][0]);
  } else {
    toast.error("Ein unbekannter Fehler ist aufgetreten");
  }
};