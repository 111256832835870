import React from "react";
import Dashboard from "../../index";
import EditBooking from "./editBooking";

const EditBookingPage = () => {
  return (
    <EditBooking/>
  );
};

export default EditBookingPage;
