import React, {useEffect, useState} from "react";
import Card from "src/components/Cards/Basic";
import axios from "src/config/axios";
import {dateTimeFormatterWithDay} from "../../../global";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft as DriveInIcon,
  faAngleDoubleRight as DriveOutIcon,
  faBan as DeniedIcon,
  faCheck as GrantedIcon,
  faFilter, faInfoCircle, faSearch
} from "@fortawesome/free-solid-svg-icons";
import SimplePagination from "../../../components/SimplePagination";
import Legend from "../../../components/Legend";
import LegendItem from "../../../components/Legend/LegendItem";
import {Col, Form, Row} from "react-bootstrap";
import OrderButtonDropdown from "../../../components/UiComponents/Buttons/OrderButtonDropdown";
import TableHeadGen from "../../../components/Tables/TableHeadGen";

const GateLogs = () => {
  const [gateLogs, setGateLogs] = useState();
  const [filterShow, setFilterShow] = useState(false);
  const columns = [
    {value: 'direction', name: 'Durchfahrt'},
    {value: 'guest', name: 'Gast'},
    {value: 'created_at', name: 'Zeit'},
    {value: 'nfc_card_uid', name: 'NFC Karte'}
  ];

  const [queryParams, setQueryParams] = useState({
    page: 1,
    search: "",
    order: 'created_at_asc'
  });

  function getData(page = null) {
    let qP = { ...queryParams };
    if(page != null) {
      qP.page = page;
    }
    axios.get(`/api/v1/gate_logs.json`, { params: qP })
      .then(function(response) {
        setGateLogs(response.data);
      })
      .catch(function(error) {
        console.error(error);
      });
  }

  function searchGateLogs(search) {
    let sanitizedSearch = search;
    sanitizedSearch = sanitizedSearch.replaceAll(":", "");

    const minTextLength = 3;

    if (!search || search.length >= minTextLength) {
      setQueryParams({...queryParams, search: sanitizedSearch, page: 1});
    }
  }


  function renderFilters() {
    if (!filterShow) {
      return (<>
      </>)
    }
    return (<>
      <Row className="optional-filter-container align-items-center">
        { /*Hier zusätzliche vorerst ausgeblendete Filter adden als Col md-3 bzw. xs-12 */ }
      </Row>
    </>)
  }

  useEffect(() => {
    getData({ ...queryParams, page: 1 });
  }, []);

  useEffect(() => {
    getData();
  }, [queryParams]);

  return (
    <>
      <Card title={`Schranken Log (${gateLogs?.pagination?.count ?? 0})`}>
        <div>
          <Row className="filter-container align-items-center">
            <Col md={8} xs={12}>
            </Col>
            <Col md={4} xs={12}>
              <Form.Group className="mb-2">
                <Form.Label>Suche</Form.Label>
                <div className="position-relative d-flex">
                  <div className="position-absolute p-2">
                    <FontAwesomeIcon icon={faSearch}/>
                  </div>
                  <Form.Control placeholder="Nach Gast oder NFC Karte suchen"
                                className="input-icon-p ui-input-bg" type="text"
                                onChange={(e) => searchGateLogs(e.target.value)}/>
                </div>
                <Form.Text className="text-muted">&nbsp;</Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {renderFilters()}
          <div className='ui-table-container mb-3'>
            <table className="" id="dataTable" width="100%" cellSpacing="0">
              <TableHeadGen columns={columns}/>
              <tbody>
              {gateLogs?.entries?.length > 0 ? gateLogs.entries.map(gateLog => (
                  <tr key={gateLog.id}>
                    <td className="p-3">
                      {gateLog.access_status === "granted" ? (
                          <FontAwesomeIcon className="fa-fw mr-2 text-success" icon={GrantedIcon}/>
                      ) : <FontAwesomeIcon className="fa-fw mr-2 text-danger" icon={DeniedIcon}/>
                      }

                      {gateLog.direction === "drive_in" ? (
                          <FontAwesomeIcon className="fa-fw mr-2 text-success" icon={DriveInIcon}/>
                      ) : gateLog.direction === "drive_out" ? (
                          <FontAwesomeIcon className="fa-fw mr-2 text-danger" icon={DriveOutIcon}/>
                      ) : null}

                    </td>
                    <td>{gateLog?.card_holder}</td>
                    <td> {dateTimeFormatterWithDay(gateLog.created_at)} </td>
                    <td>{gateLog.nfc_card_uid}</td>
                  </tr>
              )) : <tr>
                <td>Keine Durchfahrten vorhanden</td>
              </tr>
              }
              </tbody>
            </table>
            <SimplePagination pagination={gateLogs?.pagination} onClick={getData}/>
          </div>
        </div>
        <Legend>
          <LegendItem icon={faInfoCircle} text={<b>Erklärung:</b>} classNames="font-weight-bold" />
          <LegendItem icon={DriveInIcon} text="Einfahrt" classNames="text-success" />
          <LegendItem icon={DriveOutIcon} text="Ausfahrt" classNames="text-danger" />
          <LegendItem icon={GrantedIcon} text="Gültiger Zugriff" classNames="text-success" />
          <LegendItem icon={DeniedIcon} text="Ungültiger Zugriff" classNames="text-danger" />
        </Legend>
      </Card>
    </>
  );
};

export default GateLogs;
