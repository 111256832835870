import React from 'react';
import styles from "./InfoListItem.module.css"
const InfoListItem = ({name, value}) => {
  return (
    <li className={styles.item}>
      <span className={styles.name}>{name}:</span>
      <span className={styles.value}>{value}</span>
    </li>
  );
};

export default InfoListItem;