import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "src/config/axios";
import { Button, Col, Row } from "react-bootstrap";
import Card from "src/components/Cards/Basic";
import { Formik, Form } from "formik";
import history from "src/global/history";
import { showErrorToast, showSuccessToast } from "src/components/Toasts/";
import AdminDashboard from "../../..";
import InputText from "../../../../../components/Forms/InputText";
import UsersSidebar from "./_users-sidebar"

const EditUser = () => {
  const { business_id } = useParams();
  const { id } = useParams();

  const [user, setUser] = useState();
  const [business, setBusiness] = useState();

  const getBusiness = () => {
    axios.get(`/api/v1/admin/businesses/${business_id}`)
    .then(function (response) {
      setBusiness(response.data);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const getUser = () => {
    axios.get(`/api/v1/admin/businesses/${business_id}/users/${id}.json`)
      .then(function(response) {
        setUser(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getBusiness();

    if (id != null) {
      getUser();
    }
  }, []);


  const fillForm = () => {
    const form = {};

    form.first_name = user?.first_name ?? "";
    form.last_name = user?.last_name ?? "";
    form.email = user?.email ?? "";

    return form;
  };

  const submit = (user) => {
    user.password_confirmation = user.password;

    axios.post(`/api/v1/admin/businesses/${business.id}/users/`, { user })
    .then(function() {
      history.push(`/dashboard/businesses/${business.id}/edit`);
      showSuccessToast();
    }).catch(function(error) {
      showErrorToast(error.response.data);
    });
  };

  return (
     <AdminDashboard>
       <>
        <Row>
          <Col>
            <Button className="mb-3" variant="outline-primary" onClick={() => history.push(`/dashboard/businesses/${business_id}/edit`)}>Zurück</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={7}>
            <Card title="">
              <div className="table-responsive">
                <Formik
                  initialValues={fillForm()}
                  enableReinitialize={true}
                  validate={(values) => {
                    const errors = {};

                    return errors;
                  }}
                  onSubmit={(data, { setSubmitting }) => {
                    setSubmitting(true);

                    submit(data);
                  }}
                >
                  {({ values, isSubmitting }) => (
                    <Form autoComplete="false">
                      <InputText label="Vorname" name="first_name" required />
                      <InputText label="Nachname" name="last_name" required />
                      <InputText label="E-Mail" name="email" required />
                      <InputText label="Password" name="password" required />
                      <Button variant="primary" type="submit">
                        Abschicken
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </Col>
          <Col>
            <UsersSidebar business_id={business_id} />
          </Col>
        </Row>
      </>
    </AdminDashboard>
  );
};

export default EditUser;
