import React, {useContext, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import axios from "../../../../config/axios";
import Card from "../../../../components/Cards/Basic";
import {showErrorToast, showSuccessToast} from "../../../../components/Toasts";
import {DashboardContext} from "../../index";
import {faMinus, faPencil as PencilIcon} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../../components/IconButton";
import EditParkingSlotDialog from "./EditParkingSlotDialog";
import useModal from "../../../../components/Modal/hooks/useModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ConfirmDialog from "../../../../components/Modal/ConfirmDialog";

const ParkingSlotsView = () => {
  const [parkingSlots, setParkingSlots] = useState(null);
  const {settings} = useContext(DashboardContext);
  const {isShow: showEdit, toggle: toggleEdit} = useModal();
  const [parkingSlot, setParkingSlot] = useState({});

  const [selectedToggleParkingSlot, setSelectedToggleParkingSlot] = useState(null);
  const [confirmText, setConfirmText] = useState("");

  useEffect(() => {
    reload();
  }, []);


  if (!parkingSlots || !settings) {
    return <></>
  }

  function reload() {
    axios.get(`/api/v1/parking_slots.json`)
      .then(function (response) {
        setParkingSlots(response.data);
      });
  }

  function toggleActive(parkingSlot) {
    axios.put(`/api/v1/parking_slots/${parkingSlot.id}.json`, {
      parking_slot: {
        active: !parkingSlot.active
      }
    })
      .then(function (response) {
        setParkingSlots((prevState) => {
          return {
            ...prevState, entries: prevState.entries.map((entry) => {
              if (entry.id === parkingSlot.id) {
                return response.data;
              } else {
                return entry;
              }
            })
          }
        })
        showSuccessToast();
      })
      .catch(function (e) {
        showErrorToast(e.message)
      })
  }


  return (
    <Card
      title={`Stellplätze (${parkingSlots.entries.length}) (davon aktiv: ${parkingSlots.entries.filter((parking_slot) => parking_slot.active).length})`}>
      <div className="ui-table-container">
        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
          <thead>
          <tr>
            <th>Type</th>
            <th>Stellplatz</th>
            {settings.business.parking_slots_enabled && <th>Tagespreis</th>}
            <th>Optionen</th>
          </tr>
          </thead>
          <tbody>
          {parkingSlots.entries.map(parking_slot => (
            <tr key={parking_slot.id}>
              <td>
                <span><FontAwesomeIcon icon={faMinus}/></span>
                {/* Todo: Hier dann Icon von Wohnmobil, Zelt, Wohnwagen, Hotelzimmer */}
              </td>
              <td>
                <span>{parking_slot.name}</span>
              </td>
              {settings.business.parking_slots_enabled &&
                <td>
                  {parking_slot?.day_price ?? (<span>n.A.</span>)}
                </td>
              }
              <td>
                <Row>
                  {settings.business.parking_slots_enabled && <Col className="col-auto text-primary">
                    <IconButton size="sm" textColor="#2769d8" className="bg-transparent border-0" icon={PencilIcon} onClick={() => {
                      setParkingSlot(parking_slot);
                      toggleEdit();
                    }}
                    />
                  </Col>
                  }
                  <Col className="col-auto">
                    <div className="custom-control custom-switch">
                      <input type="checkbox" className="custom-control-input" id={`id${parking_slot.id}`}
                             checked={parking_slot.active} onChange={() => {
                               setSelectedToggleParkingSlot(parking_slot);
                               setConfirmText("Möchten Sie den Parkingslot '" + (parking_slot?.name ?? "") + "' wirklich "  +(parking_slot.active ? "deaktivieren?" : "aktivieren?"));
                      }}/>
                      <label className="custom-control-label" htmlFor={`id${parking_slot.id}`}>Aktiv</label>
                    </div>
                  </Col>
                </Row>
              </td>
            </tr>
          ))}
          {parkingSlots.entries.length === 0 && (
            <tr>
              <td>Keine Stellplätze vorhanden</td>
            </tr>
          )}
          </tbody>
        </table>
      </div>

      <EditParkingSlotDialog show={showEdit} parking_slot={parkingSlot} toggle={toggleEdit} handleSuccess={() => {
        showSuccessToast();
        reload();
      }}/>

      {!!selectedToggleParkingSlot &&
        <ConfirmDialog handleSubmit={() => {toggleActive(selectedToggleParkingSlot); setSelectedToggleParkingSlot(null)}}
                       handleClose={() => setSelectedToggleParkingSlot(null)}
                       content={confirmText}
        />
      }
    </Card>
  );
}

export default ParkingSlotsView;