import React, {useEffect, useState} from "react";
import Card from "src/components/Cards/Basic";
import {Button} from "react-bootstrap";
import AdminDashboard from "../../index";
import {useParams} from "react-router-dom";
import history from "../../../../global/history";
import useBusiness from "../util/useBusiness";
import EditShellyDialog from "././EditPowerPillarDialog";
import useModal from "../../../../components/Modal/hooks/useModal";
import {showSuccessToast} from "../../../../components/Toasts";
import IconButton from "../../../../components/IconButton";
import {
  faPencil as PencilIcon,
  faTrash as DeleteIcon,
  faEye as DetailsIcon
} from "@fortawesome/free-solid-svg-icons";
import ConfirmDeleteDialog from "../../../../components/Modal/ConfirmDelete";
import usePowerPillars from "./hooks/usePowerPillars";

const PowerPillars = () => {
  const { id } = useParams();
  const business = useBusiness(id);
  const { power_pillars, fetchPowerPillars, archive } = usePowerPillars({ business_id: id });
  const { isShow: showEdit, toggle: toggleEdit } = useModal();
  const { isShow: showArchive, toggle: toggleArchive } = useModal();
  const [shelly, setShelly] = useState({});

  useEffect(() => {
    fetchPowerPillars();
  }, []);

  const handleEdited = () => {
    showSuccessToast();
    fetchPowerPillars();
  };

  const handleDelete = () => {
    archive(shelly);
    toggleArchive();
  };

  return (
    <AdminDashboard>
      <Button className="mb-3 mr-2" variant="outline-primary" onClick={() => history.goBack()}>Zurück</Button>
      <Button className="mb-3" variant="primary" onClick={() => {
        toggleEdit();
        setShelly({});
      }}>
        Stromsäule erstellen
      </Button>
      <Card title={`Stromsäule von ${business?.name}`}>
        <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
            <thead>
            <tr className="tab_name">
              <th>Name</th>
              <th>Optionen</th>
            </tr>
            </thead>
            <tfoot>
            <tr className="tab_name">
              <th>Name</th>
              <th>Optionen</th>
            </tr>
            </tfoot>
            <tbody>
            {power_pillars?.map((power_pillar) => (
              <tr key={power_pillar.id}>
                <td>
                  <div>
                    <div>{power_pillar.name}</div>
                    <small>{power_pillar.shellys.filter((shelly) => shelly.is_online).length} / {power_pillar.shellys.length} online</small>
                  </div>
                </td>
                <td>
                  <IconButton className="mr-2 text-primary mr-3" icon={DetailsIcon} onClick={() => {history.push(`power_pillars/${power_pillar.id}/shellys`)} } />
                  <IconButton className="mr-2 text-primary mr-3" icon={PencilIcon} onClick={() => {
                    setShelly(power_pillar);
                    toggleEdit();
                  }}
                  />
                  <IconButton className="mr-2 text-primary mr-3 text-danger" icon={DeleteIcon} onClick={() => {
                    setShelly(shelly);
                    toggleArchive();
                  }}
                  />
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </Card>
      <EditShellyDialog shelly={shelly} toggle={toggleEdit} show={showEdit} handleSuccess={handleEdited}/>
      <ConfirmDeleteDialog show={showArchive} handleClose={toggleArchive} handleDelete={handleDelete}/>
    </AdminDashboard>
  );
};

export default PowerPillars;
