import React, {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAddressCard, faChartLine,
    faChevronLeft, faKey,
    faUser,
    faUsers,
    faArrowRightArrowLeft, faSquareParking, faFilePdf, faCalendar, faBookmark
} from "@fortawesome/free-solid-svg-icons";
import {DashboardContext} from "../../../pages/Dashboard";
import {faGear} from "@fortawesome/free-solid-svg-icons/faGear";

const Sidebar = (props) => {
  const { settings, logo, name: businessName } = useContext(DashboardContext);

  const businessSettings = settings?.business;

  const [toggled, setToggled] = useState(false);

  return (
      <ul
          className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'}
          id="accordionSidebar">
          <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/dashboard">
              <div className="sidebar-brand-icon">
                  {logo?.url && <img src={logo?.url} alt="Logo"/>}
              </div>
          </Link>

          <hr className="sidebar-divider my-0"/>

          <div className="sidebar-heading mt-3">
              Buchungen
          </div>

          {true === false && <li className="nav-item">
              <Link className="nav-link" to="/dashboard/bookings/overview">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faCalendar}/>
                  <span>Kalender Übersicht</span>
              </Link>
          </li>}

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/bookings/active-bookings">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faUser}/>
                  <span>Aktive Buchungen</span>
              </Link>
          </li>

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/bookings/">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faUsers}/>
                  <span>Alle Buchungen</span>
              </Link>
          </li>

          {settings?.online_reservation?.enabled &&
              <li className="nav-item">
                  <Link className="nav-link" to="/dashboard/reservations/">
                      <FontAwesomeIcon className="fa-fw mr-2" icon={faBookmark}/>
                      <span>Reservierungen</span>
                  </Link>
              </li>
          }

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/gatelogs">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faArrowRightArrowLeft}/>
                  <span>Schranke Log</span>
              </Link>
          </li>

          <hr className="sidebar-divider"/>

          <div className="sidebar-heading">
              Statistiken
          </div>

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/stats">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faChartLine}/>
                  <span>Statistiken</span>
              </Link>
          </li>

          <hr className="sidebar-divider"/>
          {/* <!-- Heading --> */}
          <div className="sidebar-heading">
              Meldungen
          </div>

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/police-reports">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faAddressCard}/>
                  <span>Polizeimeldung</span>
              </Link>
          </li>

          <hr className="sidebar-divider"/>

          <div className="sidebar-heading">
              Berichte
          </div>

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/deposit-cards">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faFilePdf}/>
                  <span>PDF-Dokumente</span>
              </Link>
          </li>

          <hr className="sidebar-divider"/>

          <div className="sidebar-heading">
              Zutrittsschlüssel
          </div>

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/access-keys">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faKey}/>
                  <span>Zutrittsschlüssel</span>
              </Link>
          </li>

          <hr className="sidebar-divider"/>

          <div className="sidebar-heading">
              Einstellungen
          </div>

          <li className="nav-item">
              <Link className="nav-link" to="/dashboard/bookingsettings">
                  <FontAwesomeIcon className="fa-fw mr-2" icon={faGear}/>
                  <span>Allgemein</span>
              </Link>
          </li>

          {businessSettings?.parking_slots_enabled && (
              <li className="nav-item">
                  <Link className="nav-link" to="/dashboard/parking_slots">
                      <FontAwesomeIcon className="fa-fw mr-2" icon={faSquareParking}/>
                      <span>Stellplätze</span>
                  </Link>
              </li>
          )}

          <hr className="sidebar-divider d-none d-md-block"/>

          {/* <!-- Sidebar Toggler (Sidebar) --> */}
          <div className="text-center d-none d-md-inline">
              <button
                  onClick={() => {
                      setToggled(!toggled);
                  }}
                  className="rounded-circle border-0"
                  style={{width: "40px", height: "40px", backgroundColor: "rgba(255,255,255,.2)", color: "white"}}>
                  <FontAwesomeIcon icon={faChevronLeft}/>
              </button>
          </div>
      </ul>
  )
}

export default Sidebar;