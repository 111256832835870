import React from 'react'
import axios from "../config/axios"
import {subdomain} from "../global"
import history from "../global/history"

export const isAuthenticated = () => {
  if(!localStorage.getItem("user_email") || !localStorage.getItem("user_token")) {
    history.push("/signin")
    return false
  }

  return true;
}

export const logout = () => {
  axios.delete("/users/sign_out.json", {
    headers: {
      "X-USER-EMAIL": localStorage.getItem("user_email"),
      "X-USER-TOKEN": localStorage.getItem("user_token"),
      "X-USER-BUSINESS_ID": subdomain
    },
  })
  .then(function () {
    localStorage.clear();
    history.push("/signin")
  })
  .catch(function () {
    history.push("/signin")
  })
}