import React, {useContext, useState} from 'react';
import {Form, Formik} from "formik";
import SelectBox from "../../../../components/Forms/SelectBox";
import {Button, Col, Form as BTForm, Row} from "react-bootstrap";
import Card from "../../../../components/Cards/Basic";
import * as Yup from "yup";
import {dateFormatter} from "../../../../global";
import {Link, useParams} from "react-router-dom";
import history from "../../../../global/history";
import {showErrorToast, showSuccessToast} from "../../../../components/Toasts";
import OnlineReservationDateRangePicker from "src/components/OnlineReservationDateRangePicker";
import useOnlineReservation from "../../OnlineReservations/hooks/useOnlineReservation";
import {DashboardContext} from "../../index";
import OnlineReservationPriceTable from "../../OnlineReservations/OnlineReservationPriceTable";
import InputText from "../../../../components/Forms/InputText";
import moment from "moment";
import {useTranslation} from "react-i18next";
import BackButton from "../../../../components/UiComponents/Buttons/BackButton";
import {keepState} from "../../../../components/UiComponents/Buttons/BackButton/helper";

const EditOnlineReservationView = () => {
  const {id} = useParams();
  const [showDateRange, setShowDateRange] = useState(false);
  const {onlineReservation, save, request: requestReservation} = useOnlineReservation(id);
  const {camp_site_map} = useContext(DashboardContext);
  const {t} = useTranslation("dashboard");

  const handleSubmit = async (values) => {
    try {
      let payload = { ...values }

      if (payload.status === "draft") {
        payload = { ...payload, status: "requested" };
      }

      await save(payload);
      history.push(`/dashboard/reservations`, keepState(history));

      showSuccessToast();
    } catch (e) {
      console.error(e)
      showErrorToast(e.message);
    }
  };

  if (!onlineReservation.id) return <></>;

  return (
    <Card title={
      <div className="d-flex w-100 align-items-center text-center">
        <BackButton route='/dashboard/reservations'/>
        <p className='mb-0'>
          Reservierung Bearbeiten
        </p>
      </div>
    }>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...onlineReservation,
          checkin_time: moment(onlineReservation.from).format(moment.HTML5_FMT.TIME),
          checkout_time: moment(onlineReservation.to).format(moment.HTML5_FMT.TIME)}}
        onSubmit={handleSubmit}
        validationSchema={() => {
          return Yup.object({
            parking_slot_id: Yup.number().min(1, "Stellplatz muss ausgewählt werden.").required("Stellplatz muss ausgewählt werden."),
            amount_guests: Yup.number().min(1, "Anzahl der Gäste muss angegeben werden.").required(),
            amount_kids: Yup.number().min(0, "Anzahl der Kinder muss angegeben werden").required(),
            amount_dogs: Yup.number().min(0, "Anzahl der Hunde muss angegeben werden").required(),
            contact_first_name: Yup.string().required("Vorname wird benötigt."),
            contact_last_name: Yup.string().required("Nachname wird benötigt."),
            contact_email: Yup.string().required("E-Mail wird benötigt."),
            contact_phone: Yup.string().required("Telefonnummer wird benötigt.")
          })
        }
        }>
        {({values, setFieldValue, handleChange}) => {
          function addChildBox(childNr) {
            let ages = [{name: "Unter 1 Jahr", value: 0}];
            ages.push({name: "1 Jahr", value: 1});

            for (let i = 2; i <= 17; i++) {
              ages.push({name: `${i} Jahre`, value: i});
            }

            return (
              <SelectBox
                name={`kid_${childNr}_age`}
                label={`Kind ${childNr}`}
                placeholder={"Alter eingeben"}
                options={ages}
                option_name={"name"}
                option_value={"value"}
                required={true}
                onChange={(e) => {
                  save({...values, [`kid_${childNr}_age`]: e.target.value})
                }}
              />
            )
          }

          return (
            <Form autoComplete="false">
              <Row>
                <Col md={9}>
                  <BTForm.Group className="mb-3">
                    <BTForm.Label>Aufenthalt</BTForm.Label>
                    <div onClick={() => {
                      setShowDateRange(!showDateRange)
                    }}>
                      <BTForm.Control
                        value={`${dateFormatter(onlineReservation.from)} - ${dateFormatter(onlineReservation.to)}`}
                        onChange={() => {

                        }}
                      />
                    </div>
                  </BTForm.Group>

                  {showDateRange && (
                    <OnlineReservationDateRangePicker
                      from={new Date(values.from)}
                      to={new Date(values.to)}
                      setFieldValue={setFieldValue}
                      onChange={(from, to) => {
                        let new_from = new Date(from);
                        let checkin_hour_minute = values.checkin_time.split(":")
                        new_from.setHours(checkin_hour_minute[0]);
                        new_from.setMinutes(checkin_hour_minute[1]);

                        let new_to = new Date(to);
                        let checkout_hour_minute = values.checkout_time.split(":")
                        new_to.setHours(checkout_hour_minute[0]);
                        new_to.setMinutes(checkout_hour_minute[1]);

                        save({...values, from: new_from, to: new_to})
                      }}
                    />
                  )}
                  <Row>
                    <Col>
                      <InputText name="checkin_time" label="Checkin" type={"time"} onBlur={(e) => {
                        let from = new Date(values.from);
                        let checkin_hour_minute = e.target.value.split(":")
                        from.setHours(checkin_hour_minute[0]);
                        from.setMinutes(checkin_hour_minute[1]);
                        save({...values, from: from})
                      }}/>
                    </Col>
                    <Col>
                      <InputText name="checkout_time" label="Checkout" type={"time"} onChange={(e) => {
                        let to = new Date(values.to);
                        let checkout_hour_minute = e.target.value.split(":")
                        to.setHours(checkout_hour_minute[0]);
                        to.setMinutes(checkout_hour_minute[1]);
                        save({...values, to: to})
                      }}/>
                    </Col>
                  </Row>

                  <Row className={"align-items-end"}>
                    <Col className={"mb-3"}>
                      <SelectBox label={"Stellplatz auswählen"}
                                 options={values.configuration.available_parking_slots}
                                 option_value="id"
                                 option_name="name"
                                 name="parking_slot_id"
                                 className={"mb-0"}
                                 required={true}
                                 onChange={(e) => {
                                   save({...values, parking_slot_id: e.target.value})
                                 }}
                      />
                      <Link target={"_blank"} to={{pathname: camp_site_map?.url}}>
                        Lageplan herunterladen
                      </Link>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <SelectBox name="amount_guests" label="Anzahl Erwachsene (18+ Jahre)"
                                 type={"number"}
                                 required={true}
                                 options={[{name: "1 Erwachsener", value: 1}, {
                                   name: "2 Erwachsene",
                                   value: 2
                                 }, {name: "3 Erwachsene", value: 3}, {
                                   name: "4 Erwachsene",
                                   value: 4
                                 }, {name: "5 Erwachsene", value: 5}, {name: "6 Erwachsene", value: 6}]}
                                 option_name={"name"}
                                 option_value={"value"}
                                 onChange={(e) => {
                                   save({...values, amount_guests: e.target.value || 0})
                                 }}
                      >
                      </SelectBox>
                    </Col>
                    <Col>
                      <SelectBox name="amount_kids" label="Anzahl Kinder (< 18 Jahre)"
                                 type={"number"}
                                 required={true}
                                 options={[{name: "Keine Kinder", value: 0}, {
                                   name: "1 Kind",
                                   value: 1
                                 }, {name: "2 Kinder", value: 2}, {name: "3 Kinder", value: 3}, {
                                   name: "4 Kinder",
                                   value: 4
                                 }]}
                                 option_name={"name"}
                                 option_value={"value"}
                                 onChange={(e) => {
                                   save({...values, amount_kids: e.target.value || 0})
                                 }}
                      >
                      </SelectBox>
                    </Col>
                  </Row>
                  {onlineReservation.amount_kids > 0 && (
                    <Row>
                      <Col>
                        {onlineReservation.amount_kids >= 1 && addChildBox(1)}
                        {onlineReservation.amount_kids >= 2 && addChildBox(2)}
                        {onlineReservation.amount_kids >= 3 && addChildBox(3)}
                        {onlineReservation.amount_kids >= 4 && addChildBox(4)}
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col>
                      <SelectBox name="amount_dogs" label="Anzahl Hunde"
                                 type={"number"}
                                 required={true}
                                 options={[{name: "Keine Hunde", value: 0}, {
                                   name: "1 Hund",
                                   value: 1
                                 }, {name: "2 Hunde", value: 2}, {name: "3 Hunde", value: 3}, {
                                   name: "4 Hunde",
                                   value: 4
                                 }]}
                                 option_name={"name"}
                                 option_value={"value"}
                                 onChange={(e) => {
                                   save({...values, amount_dogs: e.target.value || 0})
                                 }}
                      >
                      </SelectBox>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <h3>Kontaktdaten</h3>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <InputText name="contact_first_name" label="Vorname" required={true}/>
                    </Col>
                    <Col xs={12} sm={6}>
                      <InputText name="contact_last_name" label="Nachname" required={true}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={6}>
                      <InputText name="contact_email" label="E-Mail"
                                 hint={"Buchungsinformationen werden an diese E-Mail versendet"} type={"email"}
                                 required={true}/>
                    </Col>
                    <Col xs={12} sm={6}>
                      <InputText name="contact_phone" label="Telefonnummer" hint={"Telefonnummer mit Vorwahl"}
                                 placeholder={"+49 177 450 0000"} type={"tel"} required={true}/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className={"d-flex justify-content-between mb-3"}>
                        <div></div>
                        <Button type={"submit"}>
                          Speichern
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3}>
                  <OnlineReservationPriceTable onlineReservation={onlineReservation}/>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </Card>
  );
}

export default EditOnlineReservationView;