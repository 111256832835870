import React from "react";
import {useState, useEffect} from "react";
import {Button} from "react-bootstrap";
import history from "../../../../../global/history";
import Card from "../../../../../components/Cards/Basic";
import axios from "../../../../../config/axios";

const UsersSidebar = ({business_id}) => {
  const [users, setUsers] = useState([]);

  const getUsers = () => {
    axios.get(`/api/v1/admin/businesses/${business_id}/users`)
    .then(function (response) {
      setUsers(response.data.entries);
    })
    .catch(function (response) {
      console.error(response);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <Card title={`Users`}>
      {users.length > 0 ?
        <>
          <ul>
            {users.map(user => (
              <li key={user.id}>{user.full_name}: {user.email}</li>
            ))
            }
          </ul>
        </>
        : <div>Noch keine User vorhanden</div>
      }
      <br/>
      <Button className="mr-2" variant="primary"
              onClick={() => history.push(`/dashboard/businesses/${business_id}/users/new`)}>User anlegen</Button>
    </Card>
  );
};

export default UsersSidebar;