import React from "react";
import EditOnlineReservationView from "../../views/EditOnlineReservationView";

const EditOnlineReservationPage = () => {

  return(
    <EditOnlineReservationView />
  );
};

export default EditOnlineReservationPage;
