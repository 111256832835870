import {Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import ProtectedRoutes from "../protectedRoutes";
import ActiveBookings from "../pages/Dashboard/Bookings/ActiveBookings";
import PoliceReports from "../pages/Dashboard/PoliceReports";
import AllBookings from "../pages/Dashboard/Bookings/AllBookings";
import EditBookingPage from "../pages/Dashboard/Bookings/Edit";
import BookingDetails from "../pages/Dashboard/Bookings/BookingDetails";
import EditGuest from "../pages/Dashboard/Bookings/BookingDetails/EditGuest";
import BookingSettings from "../pages/Dashboard/BookingSettings";
import GateLogs from "../pages/Dashboard/GateLogs";
import DepositCards from "../pages/Dashboard/DepositCards";
import AccessKeys from "../pages/Dashboard/AccessKeys";
import Stats from "../pages/Dashboard/stats";
import OnlineReservationsPage from "../pages/Dashboard/pages/OnlineReservationsPage";
import Dashboard from "../pages/Dashboard";
import EditOnlineReservationPage from "../pages/Dashboard/pages/EditOnlineReservationPage";
import NewOnlineReservationPage from "../pages/Dashboard/pages/NewOnlineReservationPage";
import ParkingSlotsPage from "../pages/Dashboard/pages/ParkingSlotsPage";
import TestDebugPage from "../pages/Dashboard/pages/TestDebugPage";
import EditBookingInvoice from "../pages/Dashboard/Bookings/EditBookingInvoice";


const DashboardRoutes = () => {
  let {path, url} = useRouteMatch();

  return (
    <Dashboard>
      <Switch>
        <ProtectedRoutes exact path={`${path}/`} component={ActiveBookings}/>
        <ProtectedRoutes path={`${path}/stats`} component={Stats}/>
        <ProtectedRoutes path={`${path}/police-reports`} component={PoliceReports}/>
        <ProtectedRoutes path={`${path}/bookings/active-bookings`} component={ActiveBookings}/>
        <ProtectedRoutes exact path={`${path}/bookings/`} component={AllBookings}/>
        <ProtectedRoutes path={`${path}/bookings/new`} component={EditBookingPage}/>
        <ProtectedRoutes exact path={`${path}/bookings/:id`} component={BookingDetails}/>
        <ProtectedRoutes path={`${path}/bookings/:id/edit`} component={EditBookingPage}/>
        <ProtectedRoutes path={`${path}/bookings/:bookingId/guests/new`} component={EditGuest}/>
        <ProtectedRoutes path={`${path}/bookings/:bookingId/guests/:id/edit`} component={EditGuest}/>
        <ProtectedRoutes path={`${path}/bookings/:bookingId/invoices/new`} component={EditBookingInvoice}/>
        <ProtectedRoutes path={`${path}/bookings/:bookingId/invoices/:id`} component={EditBookingInvoice}/>
        <ProtectedRoutes path={`${path}/bookingsettings`} component={BookingSettings}/>
        <ProtectedRoutes path={`${path}/gatelogs`} component={GateLogs}/>
        <ProtectedRoutes path={`${path}/deposit-cards`} component={DepositCards}/>
        <ProtectedRoutes path={`${path}/access-keys`} component={AccessKeys}/>
        <ProtectedRoutes exact path={`${path}/reservations`} component={OnlineReservationsPage}/>
        <ProtectedRoutes path={`${path}/reservations/new`} component={NewOnlineReservationPage}/>
        <ProtectedRoutes path={`${path}/reservations/:id/edit`} component={EditOnlineReservationPage}/>
        <ProtectedRoutes exact path={`${path}/parking_slots`} component={ParkingSlotsPage}/>
        <ProtectedRoutes exact path={`${path}/test`} component={TestDebugPage} />
        <ProtectedRoutes exact path={`/`} component={ActiveBookings}/>
      </Switch>
    </Dashboard>
  );
};

export default DashboardRoutes;