import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button} from "react-bootstrap";
import classNames from "classnames";


const IconButton = ({icon, text, onClick, size = "md", textColor = "white", ...rest}) => {
  return (
    <Button size={size} {...rest} onClick={onClick}>
      <FontAwesomeIcon icon={icon} color={textColor} className={classNames({["mr-2"]: text})}/>
      {text}
    </Button>
  );
};

export default IconButton;