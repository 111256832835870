import React, {useContext} from "react";
import {Col, Container, Row} from "react-bootstrap";
import styles from "./Header.module.css"
import {OnlineReservationContext} from "../index";

const OnlineReservationHeader = () => {
  const { business } = useContext(OnlineReservationContext);

  return (
    <Row className={"mb-3"}>
      <Col md={9}>
        <img src={business?.logo?.url} alt="Logo" className={styles.logo}/>
      </Col>
      <Col md={3}>
      </Col>
    </Row>
  )
}

export default OnlineReservationHeader;