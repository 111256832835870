import React, { useEffect, useState } from "react";
import Dashboard from "..";
import Card from "src/components/Cards/Basic";
import {Button, Col, Row} from "react-bootstrap";
import {dateFormatter} from "../../../global";
import axios from "../../../config/axios";

const PoliceReports = () => {
  const [policeReportsToday, setPoliceReportsToday] = useState();
  const [policeReportsYesterday, setPoliceReportsYesterday] = useState();
  
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(yesterday.getDate() - 1);
  
  useEffect(() => {
    fetchPoliceReport(today, setPoliceReportsToday);
    fetchPoliceReport(yesterday, setPoliceReportsYesterday);
  }, []);
  
  function fetchPoliceReport(date, setterFunction) {
    axios.get(`/api/v1/police_reports/count.json`, {params: {date: date.toLocaleString()}})
    .then(function (response) {
      setterFunction(response.data);
    })
    .catch(function (error) {
      console.error(error);
    });
  }
  
  const download = (date) => {
    axios({ url: `/api/v1/police_reports.txt?date=${dateFormatter(date)}`, responseType: 'blob', method: "POST"})
      .then(function(response) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'polizeimeldung.txt');
        document.body.appendChild(link);
        link.click();
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <>
      <Row>
        <Col xs={12} sm={3}>
          <Card title={`Polizeimeldung vom ${dateFormatter(today)}` }>
            { policeReportsToday && (
              <p>Polizeimeldung für {policeReportsToday.guests_count} Personen erstellen.</p>
            )}
            <Button onClick={() => download(today)}>Textdatei herunterladen</Button>
          </Card>
          
          <Card title={`Polizeimeldung vom ${dateFormatter(yesterday)}` }>
            { policeReportsYesterday && (
              <p>Polizeimeldung für {policeReportsYesterday.guests_count} Personen erstellen.</p>
            )}
            <Button onClick={() => download(yesterday)}>Textdatei herunterladen</Button>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PoliceReports;
