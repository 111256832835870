import React, {createContext, useEffect, useState} from "react";
import Sidebar from "../../components/Navigation/Sidebar"
import Topbar from "../../components/Navigation/Topbar"
import axios from "../../config/axios";

export const DashboardContext = createContext(null);

const Dashboard = (props) => {
  const [businessSettings, setBusinessSettings] = useState({settings: null});
  const [showSidebar, setShowSidebar] = useState(true);
  useEffect(() => {
    axios.get(`/api/v1/businesses/me.json`)
      .then(function (response) {
        setBusinessSettings(response.data);
      })
      .catch(function (error) {
        console.log(error)
      })
  }, []);

  return (
    <DashboardContext.Provider value={businessSettings}>
      <div id="wrapper">
        {showSidebar && <Sidebar/>}
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Topbar clickMenuOpen={() => setShowSidebar(!showSidebar)}/>
            <div className="p-4">{props.children}</div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto font-weight-normal">
                <span>Copyright &copy; <b><span className='text-color-rura'>Rura</span>Tec</b> 2024</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <a className="scroll-to-top rounded" href="">
        <i className="fas fa-angle-up"></i>
      </a>
    </DashboardContext.Provider>
  )
}

export default Dashboard