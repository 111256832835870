import React, { useEffect, useState } from "react";
import AdminDashboard from "src/pages/AdminDashboard/";
import axios from "src/config/axios";
import { dateFormatter, dateTimeFormatter } from "src/global";
import history from "src/global/history";
import { useParams } from "react-router-dom";
import Card from "src/components/Cards/Basic";
import { Button } from "react-bootstrap";

const AdminAlerts = () => {
  const { id } = useParams();

  const [business, setBusiness] = useState();
  const [alerts, setAlerts] = useState();

  const getBusiness = () => {
    axios.get(`/api/v1/admin/businesses/${id}.json`)
      .then(function(response) {
        setBusiness(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const getAlerts = () => {
    axios.get(`/api/v1/admin/businesses/${id}/alerts.json`)
      .then(function(response) {
        setAlerts(response.data.entries);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getBusiness();
    getAlerts();
  }, []);

  return (
    <AdminDashboard>
      <h1 className="h3 mb-4 text-gray-800">Alerts von {business?.name}</h1>
      <Button className="mb-3 mr-2" variant="outline-primary" onClick={() => history.push("/dashboard")}>Zurück</Button>
      {
        alerts?.map((alert) => (
          <Card key={alert.id} title={`${alert.alert_type} - ${dateTimeFormatter(alert.created_at)}`}>
            {alert.message}
          </Card>
        ))
      }
    </AdminDashboard>
  );
};

export default AdminAlerts;