import React, {useEffect, useState} from "react";
import Dashboard from "..";
import Card from "src/components/Cards/Basic";
import {Button, Col, Row} from "react-bootstrap";
import axios from "../../../config/axios";
import moment from "moment";
import {Formik, Form} from "formik";
import {downloadFile} from "../../../global/FileDownloader";

const DepositCards = () => {
  const downloadPdf = (data) => {
    axios.get(`/api/v1/deposit_cards.pdf?date=${moment(data.date).startOf('month')}`, {responseType: 'arraybuffer'})
      .then(function (response) {
        let blob = new Blob([response.data], {type: 'application/pdf'}), url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <Row>
        <Col xs={12} sm={3}>
          <Card title={`Monatliche Kartenkaution Ein-/Ausgaben`}>
            <Formik
              initialValues={{date: new Date()}}
              onSubmit={(data) => {
                downloadPdf(data);
              }}>
              {({values, handleChange}) => (
                <Form name="booking_setting" autoComplete="false">
                  <input type="month" name="date" onChange={handleChange}
                         value={moment(values.date).format("YYYY-MM")}/>
                  <br/><br/>
                  <Button type="submit">PDF erstellen</Button>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
        <Col xs={12} sm={3}>
          <Card title={`Monatliche Rechnungen`}>
            <Formik
              initialValues={{date: new Date()}}
              onSubmit={(data) => {
                downloadFile(`/api/v1/invoices.xlsx?date=${moment(data.date).startOf('month')}`, `Rechnungen ${moment(data.date).format("YYYY-MM")}.xlsx`);
              }}>
              {({values, handleChange}) => (
                <Form name="booking_setting" autoComplete="false">
                  <input type="month" name="date" onChange={handleChange}
                         value={moment(values.date).format("YYYY-MM")}/>
                  <br/><br/>
                  <Button type="submit">Rechnungen herunterladen</Button>
                </Form>
              )}
            </Formik>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DepositCards;
