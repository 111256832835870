import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle, faInfo} from "@fortawesome/free-solid-svg-icons";
import {dateTimeFormatter} from "../../../global";

const Alert = ({alert, updateAlertSeen}) => {
  let date = new Date(alert.created_at);
  function getBackgroundColor() {
    switch (alert.alert_type) {
      case "info":
        return "bg-info";
      case "warning":
        return "bg-warning";
      case "critical":
        return "bg-danger";
      default:
        return "bg-info";
    }
  }

  function getIcon() {
    switch (alert.alert_type) {
      case "info":
        return faInfo;
      case "warning":
        return faExclamationTriangle;
      case "critical":
        return faExclamationTriangle;
      default:
        return faExclamationTriangle;
    }
  }

  return (
    <a onClick={() => updateAlertSeen(alert)} className="dropdown-item d-flex align-items-center">
      <div className="mr-3">
        <div className={`icon-circle ${getBackgroundColor()}`}>
          <FontAwesomeIcon icon={getIcon()} color="white"/>
        </div>
      </div>
      <div>
        <div className="small text-gray-500">{alert.alert_type}
        </div>
        {alert.message}
        <div className="small text-gray-500">
          <span className="font-weight-bold">
            {dateTimeFormatter(date)}
          </span>
        </div>
      </div>
    </a>
  );
};
export default Alert;