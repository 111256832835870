import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const LegendItem = ({ icon, text, classNames }) => {
  return (
    <div>
      <FontAwesomeIcon className={`fa-fw mr-2 ${classNames}`} icon={icon}/>
      {text}
    </div>
  );
};

export default LegendItem;
