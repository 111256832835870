import React, {useEffect, useRef, useState} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form, Formik} from "formik";
import SelectBox from "../../Forms/SelectBox";
import InputText from "../../Forms/InputText";
import axios from "../../../config/axios";
import {showErrorToast, showSuccessToast} from "../../Toasts";
import moment from "moment";

const PayInvoiceDialog = ({show, onSubmit, onClose, invoice}) => {
  const [settings, setSettings] = useState();

  const update = (data) => {
    axios.put(`/api/v1/invoices/${invoice.id}.json`, {invoice: data})
      .then(function (response) {
        onSubmit(response.data);
        showSuccessToast();
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  };

  useEffect(() => {
    axios.get(`/api/v1/invoices/settings.json`)
      .then(function (response) {
        setSettings(response.data.settings);
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  }, [])


  const formRef = useRef();

  if (!show) {
    return <></>
  }

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header>
        <Modal.Title>Rechnung bezahlen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={{...invoice, paid_at: invoice.paid_at ?? moment().format(moment.HTML5_FMT.DATETIME_LOCAL)}}
          enableReinitialize={true}
          validate={(values) => {
            const errors = {};

            return errors;
          }}
          onSubmit={(data, {setSubmitting}) => {
            setSubmitting(true);
            update(data);
          }}
        >
          {({values, setFieldValue, handleChange, errors}) => (
            <Form autoComplete="false">
              <SelectBox name="status" label="Status"
                         options={[{name: "Unbezahlt", value: "unpaid"}, {name: "Bezahlt", value: "paid"}]}
                         option_name={"name"}
                         option_value={"value"}
                         onChange={(e) => {
                           setFieldValue("status", e.target.value)

                           if (e.target.value === "unpaid") {
                             setFieldValue("paid_at", null)
                           }
                         }}
              >
              </SelectBox>
              {values.status === "paid" && (<>
                <InputText label="Bezahlt am" type={"datetime-local"} name="paid_at"/>
                <SelectBox name="payment_type" label="Zahlungsart"
                           options={settings.payment_types}
                           option_name={"name"}
                           option_value={"value"}
                >
                </SelectBox>
              </>)}
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Schließen
        </Button>
        <Button variant="primary" onClick={() => formRef?.current?.handleSubmit()}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PayInvoiceDialog;