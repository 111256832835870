import React from "react";
import {Card} from "react-bootstrap";
import {euroFormatter} from "src/global";

const OnlineReservationPriceTable = ({onlineReservation}) => {
  return (
    <Card>
      <Card.Body>
        <Card.Title><b>Preisübersicht</b></Card.Title>
        <Card.Text>
          Stellplatzgebühr: {euroFormatter(onlineReservation.day_price)}<br/>
          Preis Personen: {euroFormatter(onlineReservation.person_price)} <br/>
          Preis Ortstaxe: {euroFormatter(onlineReservation.local_tax_price)} <br/>
          Preis Hunde: {euroFormatter(onlineReservation.dog_price)}<br/>
          Gesamtpreis: {euroFormatter(onlineReservation.price)}<br/>
          <small>inkl. MwSt.</small>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default OnlineReservationPriceTable;