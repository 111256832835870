import React, {useEffect, useState} from "react";
import axios from "../../../../config/axios";
import {showErrorToast, showSuccessToast} from "../../../../components/Toasts";

const AccessKeys = () => {
  const [accessKeys, setAccessKeys] = useState([]);

  const fetchAccessKeys = () => {
    axios.get(`/api/v1/access_keys.json`)
      .then(function(response) {
        setAccessKeys(response.data.entries);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const archive = (access_key) => {
    axios.delete(`/api/v1/access_keys/${access_key.id}.json`)
      .then(function() {
        fetchAccessKeys();
      })
      .catch(function(error) {
        showErrorToast(error.response.data);
      });
  };

  useEffect(() => {
    fetchAccessKeys();
  }, []);

  return { accessKeys, fetchAccessKeys, archive }
};

export default AccessKeys;
