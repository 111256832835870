import {useState} from "react";

const useModal = () => {
  const[isShow, setIsShowing] = useState(false);

  function toggle() {
    setIsShowing(!isShow);
  }

  return { isShow, toggle }
}

export default useModal;