import React, {useState} from "react"
import {Form} from "react-bootstrap"
import { Typeahead, withAsync } from 'react-bootstrap-typeahead';
import axios from "../../../config/axios";
import {useField} from "formik"

const AsyncTypeahead = withAsync(Typeahead);

const CountrySelectBox = ({label, required = false, ...rest}) => {
  const [field, meta, helper] = useField(rest)
  const [isLoading, setIsLoading] = useState(false)
  const [options, setOptions] = useState([])

  return (
    <Form.Group className="mb-3" controlId={`form-${name}`}>
      <Form.Label>{label} {required && "*"}</Form.Label>
      <AsyncTypeahead
        id={field.name}
        isLoading={isLoading}
        labelKey={option => `${option.name}`}
        onSearch={(name) => {
          setIsLoading(true)
          axios.get(`/api/v1/countries/autocomplete.json?name=${name}`)
          .then(function (response) {
            setIsLoading(false)
            setOptions(response.data)
          })
          .catch(function (error) {
            console.log(error)
          })
        }}
        placeholder="Nach Länder suchen..."
        options={options}
        className="mb-3"
        onChange={(selected) => {
          helper.setValue(selected[0]);
        }}
        useCache={false}
        selected={field.value ? [field.value] : []}
        required={required}
      />
      <span className="text-danger">{meta.error}</span>
    </Form.Group>
  )
}

export default CountrySelectBox