import {Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import Businesses from "../pages/AdminDashboard/Businesses";
import EditBusiness from "../pages/AdminDashboard/Businesses/Edit";
import AdminBookingSettings from "../pages/AdminDashboard/Businesses/AdminBookingSettings";
import AdminAlerts from "../pages/AdminDashboard/Businesses/Alerts";
import EditUser from "../pages/AdminDashboard/Businesses/Users/Edit";
import PowerPillars from "../pages/AdminDashboard/Businesses/PowerPillars";
import Shellys from "../pages/AdminDashboard/Businesses/Shellys";
import ProtectedRoutes from "../protectedRoutes";

const AdminRoutes = () => {
  let { path, url } = useRouteMatch();

  return (
    <Switch>
      <ProtectedRoutes exact path="/" component={Businesses}/>
      <ProtectedRoutes exact path={`${path}`} component={Businesses}/>
      <ProtectedRoutes path={`${path}/businesses/new`} component={EditBusiness}/>
      <ProtectedRoutes path={`${path}/businesses/:id/edit`} component={EditBusiness}/>
      <ProtectedRoutes path={`${path}/businesses/:id/bookingsettings`} component={AdminBookingSettings}/>
      <ProtectedRoutes path={`${path}/businesses/:id/alerts`} component={AdminAlerts}/>
      <ProtectedRoutes path={`${path}/businesses/:business_id/users/new`} component={EditUser}/>
      <ProtectedRoutes exact path={`${path}/businesses/:id/power_pillars/`} component={PowerPillars}/>
      <ProtectedRoutes path={`${path}/businesses/:business_id/power_pillars/:id/shellys`} component={Shellys}/>
    </Switch>
  );
};

export default AdminRoutes;