import React, {useRef} from "react";
import {Button, Modal} from "react-bootstrap";
import axios from "src/config/axios";
import {FieldArray, Form, Formik} from "formik";
import InputText from "src/components/Forms/InputText";

import {useParams} from "react-router-dom";

const EditShellyDialog = ({ show, toggle, shelly = {}, handleSuccess = null }) => {
  const formRef = useRef();
  const { business_id, id } = useParams();

  const fillForm = () => {
    const form = {};

    form.shelly = {};
    form.shelly.name = shelly.name ?? "";
    form.shelly.device_id = shelly.device_id ?? "";
    form.shelly.channels_attributes = shelly.channels ?? [];
    form.shelly.power_pillar_id = shelly.power_pillar_id ?? id;
    form.shelly.ip = shelly.ip ?? "";
    form.shelly.order = shelly.order ?? 0;
    return form;
  };

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header>
        <Modal.Title>{!shelly ? "Erstellen" : "Bearbeiten"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={fillForm()}
          enableReinitialize={true}
          validate={(values) => {
            const errors = {};

            if (!values.shelly.device_id) {
              errors.shelly = { device_id: "Geräte id ist ein Pflichtfeld" };
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            let request;

            if (shelly.id) {
              request = axios.put(`/api/v1/admin/businesses/${business_id}/shellys/${shelly.id}.json`, values);
            }
            else {
              request = axios.post(`/api/v1/admin/businesses/${business_id}/shellys.json`, values);
            }

            request.then(function() {
              toggle();

              if (handleSuccess) {
                handleSuccess();
              }
            })
              .catch(function(e) {
                console.error(e);
              });
          }}
        >
          {({ values, handleChange, setFieldValue, setFieldTouched }) => (
            <Form autoComplete="false">
              <InputText name="shelly.name" label="Name"/>
              <InputText name="shelly.device_id" label="Geräte id"/>
              <InputText name="shelly.ip" label="IP-Adresse"/>
              <FieldArray
                name="shelly.channels_attributes"
                render={arrayHelpers => (
                  <div>
                    {values.shelly.channels_attributes && values.shelly.channels_attributes.length > 0 && (
                      values.shelly.channels_attributes.map((channel, index) => (
                        <div key={index}>
                          {!channel._destroy && (
                            <>
                              <InputText name={`shelly.channels_attributes[${index}].identifier`} label="Channel ID"/>
                              <InputText name={`shelly.channels_attributes[${index}].order`} label="Nr. Steckdose"/>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  setFieldValue(`shelly.channels_attributes[${index}]._destroy`, true);
                                  setFieldTouched(`shelly.channels_attributes[${index}]._destroy`);
                                }}
                              >
                                -
                              </Button>
                            </>
                          )}
                        </div>
                      ))
                    )}
                    <Button variant="secondary" onClick={() => arrayHelpers.push({ identifier: values.shelly.channels_attributes.length })}>
                      Channel hinzufügen
                    </Button>
                  </div>
                )}
              />
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Schließen
        </Button>
        <Button variant="primary" onClick={() => formRef.current.handleSubmit()}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditShellyDialog;