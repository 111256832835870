import React, {useRef} from "react";
import axios from "../../../../../../config/axios";
import {showErrorToast, showSuccessToast} from "src/components/Toasts";
import {Form, Formik} from "formik";
import {Button, Modal} from "react-bootstrap";
import InputText from "src/components/Forms/InputText";
import moment from "moment";

const EditSmartPowerBookingModuleDialog = ({show, handleSubmit, handleClose, booking}) => {
  const formRef = useRef();
  const smart_power_booking = booking?.smart_power_booking;

  function onSubmit(values) {
    if (values.id) {
      axios.put(`/api/v1/smart_power_bookings/${values.id}.json`, {smart_power_booking: values})
        .then(function (response) {
          showSuccessToast();
          handleSubmit(response.data);
        })
        .catch(function (error) {
          showErrorToast(error.response.data);
        });
    }
    else {
      axios.post(`/api/v1/bookings/${booking.id}/smart_power_bookings.json`, {smart_power_booking: values})
        .then(function (response) {
          showSuccessToast();
          handleSubmit(response.data);
        })
        .catch(function (error) {
          showErrorToast(error.response.data);
        });
    }
  }

  const fillForm = () => {
    const form = smart_power_booking ?? {};
    if (!smart_power_booking) {
      form.from = booking.booked_from ?? new Date()
      form.to = booking.booked_until ?? new Date()

      form.from = moment(form.from).format(moment.HTML5_FMT.DATETIME_LOCAL);
      form.to = moment(form.to).format(moment.HTML5_FMT.DATETIME_LOCAL);
    }

    return form;
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Smart Power</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={fillForm()}
          enableReinitialize={true}
          innerRef={formRef}
          onSubmit={(data, {setSubmitting}) => {
            setSubmitting(true);

            onSubmit(data);
          }}
        >
          {({values}) => (
            <Form autoComplete="false">
              <InputText name="from" label="Von" type="datetime-local"/>
              <InputText name="to" label="Bis" type="datetime-local"/>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Schließen
        </Button>
        <Button variant="primary" type="submit" onClick={() => formRef.current.handleSubmit()}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditSmartPowerBookingModuleDialog;