import React from "react";
import ParkingSlotsView from "../../views/ParkingSlotsView";


const ParkingSlotsPage = () => {

  return(
    <ParkingSlotsView />
  );
};

export default ParkingSlotsPage;
