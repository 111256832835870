import React, {useContext, useState} from "react";
import {Button} from "react-bootstrap";
import {dateTimeFormatter, euroFormatter} from "../../../../../global";
import Card from "../../../../../components/Cards/Basic";
import axios from "../../../../../config/axios";
import {showSuccessToast, showErrorToast} from "../../../../../components/Toasts";
import ConfirmDeleteDialog from "src/components/Modal/ConfirmDelete";
import {DashboardContext} from "../../../index";
import {useTranslation} from "react-i18next";
import {keepState} from "../../../../../components/UiComponents/Buttons/BackButton/helper";
import {useHistory} from "react-router-dom";

const Sidebar = ({booking}) => {
  const history = useHistory();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const {settings} = useContext(DashboardContext);
  const {t} = useTranslation("dashboard");

  const openDeleteDialog = () => {
    setShowDeleteDialog(true);
  };

  const archive = () => {
    axios.delete(`/api/v1/bookings/${booking.id}.json`)
      .then(function () {
        history.push("/dashboard/bookings/active-bookings", keepState(history));
        showSuccessToast("Erfolgreich gelöscht!");
      })
      .catch(function (error) {
        showErrorToast(error.response.data);
      });
  };

  return (
    <>
      <Card title={`Buchungsdetails`}>
        {booking ?
          <>
            <ul>
              <li>Status: {t(`booking.statuses.${booking.state}`)}</li>
              {settings?.business?.parking_slots_enabled && <li>Stellplatz: {booking.parking_slot?.name}</li>}
              <li>Buchungsart: {booking.booking_type.name}</li>
              {booking.booked_from == null && booking.booked_until == null && <li>Stunden: {booking.hours}</li>}
              {booking.booked_from != null && <li>Von: {dateTimeFormatter(booking.booked_from)}</li>}
              {booking.booked_until != null && <li>Bis: {dateTimeFormatter(booking.booked_until)}</li>}
              {booking.was_extended_at != null && <li>Verlängert am: {dateTimeFormatter(booking.was_extended_at)}</li>}
              {booking.paid_at != null && <li>Bezahlt um: {dateTimeFormatter(booking.paid_at)}</li>}
              {booking.license_plate && <li>Autokennzeichen: {booking.license_plate}</li>}
              {booking.checkout_date && <li>Checkout am: {dateTimeFormatter(booking.checkout_date)}</li>}
              {booking.amount_dogs > 0 && <li>Anzahl Hunde: {booking.amount_dogs}</li>}
              {booking.nfc_card_uid && <li>NFC Karte {booking.nfc_card_uid}</li>}
              {booking.hours > 0 && (<li>Stunden: {booking.hours}</li>)}
              {booking.price && <li>Gesamtpreis: {euroFormatter(booking.price.total_price)}</li>}
              <li>Herkunft: {t(`booking.sources.${booking.source}`)}</li>
              {booking?.online_reservation?.contact_person && <li>Kontaktdaten: {booking.online_reservation.contact_person}</li>}
              {booking?.online_reservation?.contact_phone && <li>Telefonnummer: {booking.online_reservation.contact_phone}</li>}
              {booking?.online_reservation?.contact_email && <li>E-mail: {booking.online_reservation.contact_email}</li>}
            </ul>
                <Button className="mr-2" variant="primary"
                        onClick={() => history.push(`/dashboard/bookings/${booking.id}/edit`, keepState(history))}>Bearbeiten</Button>
                {
                  !booking.paid && (
                    <>
                      {booking.booking_type.id == "new" &&
                        <Button className="mr-2" variant="danger" onClick={openDeleteDialog}>Löschen</Button>}
                    </>
                  )
                }
          </>
          : <div>Noch keine Buchungsdetails vorhanden</div>
        }
      </Card>

      <ConfirmDeleteDialog show={showDeleteDialog} handleClose={() => setShowDeleteDialog(false)}
                           handleDelete={archive}/>
    </>
  );
};

export default Sidebar;