import {useEffect, useState} from "react";
import axios from "../../../../config/axios";

const useOnlineReservation = (id) => {
  const [onlineReservation, setOnlineReservation] = useState({from: new Date().toISOString(), to: new Date(new Date().setDate(new Date().getDate() + 1)).toISOString()});

  useEffect(() => {
    if (id) {
      axios.get(`api/v1/online_reservations/${id}.json`)
        .then(function(response) {
          setOnlineReservation(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }, [id]);

  const save = async (onlineReservation) => {
    let response = null;

    if (onlineReservation.id) {
      response = await axios.put(`/api/v1/online_reservations/${onlineReservation.id}.json`, {online_reservation: onlineReservation})
    }
    else {
      response = await axios.post(`/api/v1/online_reservations.json`, {online_reservation: onlineReservation})
    }

    setOnlineReservation(response.data);
    return response.data;
  };

  const request = async (onlineReservation) => {
    const response = await axios.post(`/api/v1/online_reservations/${onlineReservation.id}/request_reservation.json`);

    setOnlineReservation(response.data);
    return response.data;
  };

  return { save, request, onlineReservation }
}

export default useOnlineReservation;
