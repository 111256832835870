import React, {useRef} from "react";
import axios from "../../../../../config/axios";
import {showErrorToast, showSuccessToast} from "../../../../../components/Toasts";
import {Button, Modal} from "react-bootstrap";
import {Form, Formik} from "formik";
import InputText from "../../../../../components/Forms/InputText";
import * as Yup from "yup";
import {dateTimeFormatter} from "../../../../../global";
import moment from "moment";

const BookingCheckoutDialog = ({booking, handleClose, handleSubmit}) => {
  const formRef = useRef();
  return (
    <>
      <Modal show={true} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>Checkout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            innerRef={formRef}
            initialValues={{...booking, checkout_date: (booking.checkout_date && moment(booking.checkout_date).format(moment.HTML5_FMT.DATETIME_LOCAL)) ?? moment(new Date()).format(moment.HTML5_FMT.DATETIME_LOCAL)}}
            validationSchema={() => {
              let allCheckinDates;
              if (booking.guests.length > 0) {
                allCheckinDates = booking.guests.map((g) => new Date(g.local_tax_report.checkin_at))
              }
              else {
                allCheckinDates = [new Date(booking.booked_from)];
              }

              const minCheckoutDate = new Date(Math.max(...allCheckinDates));

              return Yup.object({
                checkout_date: Yup.date().min(minCheckoutDate, `muss größer als das Checkin Datum ${dateTimeFormatter(minCheckoutDate)} sein`)
                  .max(new Date(), "Das Checkout Datum darf nicht in der Zukunft liegen")
              })
            }}
            onSubmit={(data, {setSubmitting, setStatus}) => {
              setSubmitting(true);

              axios.post(`/api/v1/bookings/${booking.id}/manual_checkout.json?checkout_date=${data.checkout_date}`)
                .then(function (response) {
                  handleSubmit(response.data);
                })
                .catch(function (error) {
                  console.log(error.response.data)
                  setSubmitting(false);
                });
            }}
          >
            {({errors}) => (
              <Form>
                {/*<GeneralFormError statuses={errors}/>*/}
                <InputText name="checkout_date" label="Checkout" type="datetime-local"/>
              </Form>
            )}
          </Formik>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Schließen
          </Button>
          <Button onClick={() => formRef?.current?.handleSubmit()}>
            Speichern
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default BookingCheckoutDialog;