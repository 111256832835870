import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../../../../services/AuthService";

class AdminTopbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-gray-600 small">Valerie Luna</span>
              <img className="img-profile rounded-circle" src="https://source.unsplash.com/QAB-WJcbgJk/60x60"/>
            </a>
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <div className="dropdown-divider"></div>
              <div className="dropdown-item" onClick={() => logout()}>
                <FontAwesomeIcon className="fa-fw mr-2 text-gray-400" icon={faSignOutAlt}/>
                <span>Logout</span>
              </div>
            </div>
          </li>
        </ul>
      </nav>
    );
  }
}
export default AdminTopbar;