import React, {useEffect, useState} from "react";
import axios from "src/config/axios";
import {showErrorToast, showSuccessToast} from "src/components/Toasts";

const usePowerPillars = ({ business_id }) => {
  const [power_pillars, setPowerPillars] = useState([]);

  const fetchPowerPillars = () => {
    axios.get(`/api/v1/admin/businesses/${business_id}/power_pillars.json`)
      .then(function(response) {
        setPowerPillars(response.data.entries);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const archive = (power_pillar) => {
    axios.delete(`/api/v1/admin/businesses/${business_id}/power_pillars/${power_pillar.id}.json`)
      .then(function() {
        fetchPowerPillars();
      })
      .catch(function(error) {
        showErrorToast(error.response.data);
      });
  };

  return { power_pillars, fetchPowerPillars, archive }
};

export default usePowerPillars;
