import React from "react";

const TableHeadGen = (props = {}) => {
    const columns = props.columns || [{ name: "ID", value: "id" }];
    return (
        <thead>
            <tr>
                {columns?.map((column) => (
                    <th key={column.value}>{column.name}</th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeadGen;