import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import axios from "src/config/axios";
import {Button, Col, Row, Form as BTForm} from "react-bootstrap";
import Card from "src/components/Cards/Basic";
import {Formik, Form} from "formik";
import history from "src/global/history";
import {showErrorToast, showSuccessToast} from "src/components/Toasts/";
import AdminDashboard from "../../index";
import InputText from "../../../../components/Forms/InputText";
import SelectBox from "../../../../components/Forms/SelectBox";
import UsersSidebar from "../Users/Edit/_users-sidebar";
import moment from "moment/moment";

const EditBusiness = () => {
  const {id} = useParams();

  const [business, setBusiness] = useState();
  const pricing_models = [{name: "Camping", id: "camping"}, {name: "Parking", id: "parking"}];

  const getBusiness = () => {
    axios.get(`/api/v1/admin/businesses/${id}.json`)
      .then(function (response) {
        setBusiness(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    if (id != null) {
      getBusiness();
    }
  }, []);


  const fillForm = () => {
    const form = {};

    form.name = business?.name ?? "";
    form.expire_at = business?.expire_at ?? "";
    form.subdomain = business?.subdomain ?? "";
    form.contact_person = business?.contact_person ?? "";
    form.contact_nr = business?.contact_nr ?? "";
    form.pricing_model = business?.pricing_model ?? "";
    form.email = business?.email ?? "";
    form.printer_logo_width = business?.printer_logo_width ?? "";
    form.printer_logo = business?.printer_logo ?? "";
    form.camp_site_map = business?.camp_site_map ?? "";
    form.logo = business?.logo ?? "";

    return form;
  };

  const submit = (values) => {
    let request;

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
    var formData = new FormData();
    formData.append('business[name]', values.name);
    formData.append('business[subdomain]', values.subdomain);
    formData.append('business[expire_at]', moment(values.expire_at).format(moment.HTML5_FMT.DATE));
    formData.append('business[contact_person]', values.contact_person);
    formData.append('business[contact_nr]', values.contact_nr);
    formData.append('business[email]', values.email);
    formData.append('business[pricing_model]', values.pricing_model);
    formData.append('business[remove_printer_logo]', values.remove_printer_logo);
    formData.append('business[printer_logo_width]', values.printer_logo_width);

    if (values.logo && values.logo instanceof File) {
      formData.append('business[logo]', values.logo);
    }

    if (values.printer_logo && values.printer_logo instanceof File) {
      formData.append('business[printer_logo]', values.printer_logo);
    }

    if (values.camp_site_map && values.camp_site_map instanceof File) {
      formData.append('business[camp_site_map]', values.camp_site_map);
    }

    if (id) {
      request = axios.put(`/api/v1/admin/businesses/${id}.json`, formData, config)
    } else {
      request = axios.post(`/api/v1/admin/businesses/`, formData, config)
    }

    request.then(function () {
      history.push(`/dashboard`);
      showSuccessToast();
    }).catch(function (error) {
      showErrorToast(error.response.data);
    });
  };

  return (
    <AdminDashboard>
      <>
        <Row>
          <Col>
            <Button className="mb-3 mr-2" variant="outline-primary"
                    onClick={() => history.push("/dashboard")}>Zurück</Button>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={7}>
            <Card title={business?.name ?? "Neu"}>
              <div className="table-responsive">
                <Formik
                  initialValues={fillForm()}
                  enableReinitialize={true}
                  validate={(values) => {
                    const errors = {};

                    return errors;
                  }}
                  onSubmit={(data, {setSubmitting}) => {
                    setSubmitting(true);

                    submit(data);
                    setSubmitting(false);
                  }}
                >
                  {({values, isSubmitting, setFieldValue}) => (
                    <Form autoComplete="false">
                      <InputText label="Name" name="name" required/>
                      <InputText label="Subdomain" name="subdomain" required/>
                      <InputText type="date" label="Ablaufdatum" name="expire_at" required/>
                      <InputText label="Kontaktperson" name="contact_person" required/>
                      <InputText label="Kontaktnummer" name="contact_nr" required/>
                      <InputText label="E-Mail" name="email" required/>
                      <InputText label="Printer Logo Breite" name="printer_logo_width"/>
                      <SelectBox label="Modell" name="pricing_model" options={pricing_models} option_value="id"
                                 option_name="name" required/>

                      <BTForm.Group className="mb-3">
                        <BTForm.Label>Logo</BTForm.Label>
                        <div className={"d-flex"}>
                          <BTForm.Control name="logo" type="file" className={"form-control-file"} onChange={(e) => {
                            setFieldValue("remove_logo", false);
                            setFieldValue("logo", e.currentTarget.files[0]);
                          }}/>
                          <Button variant={"danger"} onClick={() => {
                            setFieldValue("remove_logo", true);
                          }}>
                            Löschen
                          </Button>
                        </div>
                        {values?.logo && <div>Current file: {values?.logo.name}</div>}
                      </BTForm.Group>

                      <BTForm.Group className="mb-3">
                        <BTForm.Label>Lageplan</BTForm.Label>
                        <div className={"d-flex"}>
                          <BTForm.Control name="camp_site_map" type="file" className={"form-control-file"}
                                          onChange={(e) => {
                                            setFieldValue("remove_camp_site_map", false);
                                            setFieldValue("camp_site_map", e.currentTarget.files[0]);
                                          }}/>
                          <Button variant={"danger"} onClick={() => {
                            setFieldValue("remove_camp_site_map", true);
                          }}>
                            Löschen
                          </Button>
                        </div>
                        {values?.camp_site_map && <div>Current file: {values?.camp_site_map.name}</div>}
                      </BTForm.Group>

                      <BTForm.Group className="mb-3">
                        <BTForm.Label>Logo für Drucker</BTForm.Label>
                        <div className={"d-flex"}>
                          <BTForm.Control name="printer_logo" type="file" className={"form-control-file mr-2"}
                                          onChange={(e) => {
                                            setFieldValue("remove_printer_logo", false);
                                            setFieldValue("printer_logo", e.currentTarget.files[0]);
                                          }}/>
                          <Button variant={"danger"} onClick={() => {
                            setFieldValue("remove_printer_logo", true);
                          }}>
                            Löschen
                          </Button>
                        </div>

                        {values?.printer_logo && <div>Current file: {values?.printer_logo.name}</div>}
                      </BTForm.Group>

                      <Button disabled={isSubmitting} variant="primary" type="submit">
                        Abschicken
                      </Button>
                    </Form>
                  )}
                </Formik>
              </div>
            </Card>
          </Col>
          <Col>
            <UsersSidebar business_id={id}/>
          </Col>
        </Row>
      </>
    </AdminDashboard>
  );
};

export default EditBusiness;
