import React from 'react';
import {Form, Formik} from "formik";
import {Button, Col, Row} from "react-bootstrap";
import Card from "../../../../components/Cards/Basic";
import OnlineReservationDateRangePicker from "src/components/OnlineReservationDateRangePicker";
import {showErrorToast} from "../../../../components/Toasts";
import useOnlineReservation from "../../OnlineReservations/hooks/useOnlineReservation";
import history from "src/global/history";


const NewOnlineReservationView = () => {
  const {onlineReservation, save} = useOnlineReservation();

  const handleSubmit = async (values) => {
    try {
      const onlineReservation = await save(values);
      history.push(`/dashboard/reservations/${onlineReservation.id}/edit`);
    } catch (e) {
      console.error(e)
      showErrorToast(e.message);
    }
  };

  return (
    <Card title="Neue Reservierung">
      <Formik
        enableReinitialize={true}
        initialValues={{...onlineReservation}}
        onSubmit={handleSubmit}>
        {({values, setFieldValue, setValues}) => {
          return (
            <Form autoComplete="false">
              <Row>
                <Col md={12} className="d-flex justify-content-center overflow-auto">
                  <OnlineReservationDateRangePicker
                    from={null}
                    to={new Date(null)}
                    setFieldValue={setFieldValue}
                    onChange={(from, to) => {
                      setValues({
                        from: from,
                        to: to
                      })
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className={"d-flex justify-content-center mb-3 mt-2"}>
                    <div></div>
                    <Button type={"submit"} className="d-flex align-content-center">
                      Weiter
                      <svg className="ui-w-px-20 text-white" aria-hidden="true"
                           xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
                              d="m9 5 7 7-7 7"/>
                      </svg>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
    </Card>
  );
}

export default NewOnlineReservationView;