// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".InfoList-module__infoList___3BZdw {\n    display: flex;\n    flex-direction: column;\n}", "",{"version":3,"sources":["InfoList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;AAC1B","file":"InfoList.module.css","sourcesContent":[".infoList {\n    display: flex;\n    flex-direction: column;\n}"]}]);
// Exports
exports.locals = {
	"infoList": "InfoList-module__infoList___3BZdw"
};
module.exports = exports;
