import React from "react";
import {Button, Modal} from "react-bootstrap";

const ConfirmDialog = ({handleSubmit, handleClose = (() =>  {}), title = "Aktion bestätigen",  content = "Möchten Sie fortfahren?", backBtnText = "Schließen", confirmBtnText = "Speichern"}) => {
  return (
    <Modal show={true}>
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {content}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          {backBtnText}
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          {confirmBtnText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;