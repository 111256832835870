import React, {useRef} from "react";
import {Button, Modal, Form as BTForm} from "react-bootstrap";
import axios from "src/config/axios";
import {Form, Formik} from "formik";
import InputText from "../../../../components/Forms/InputText";

const EditAccessKeyModal = ({ show, toggle, access_key = {}, handleSuccess = null }) => {
  const formRef = useRef();

  const handleSubmit = () => {
    formRef.current.handleSubmit();
  };

  const fillForm = () => {
    const form = {};

    form.name = access_key.name ?? "";
    form.active = access_key.active ?? true;
    form.nfc_card_uid = access_key.nfc_card_uid ?? "";
    form.valid_from = access_key.valid_from ?? "";
    form.valid_until = access_key.valid_until ?? "";

    return form;
  };

  return (
    <Modal show={show} onHide={toggle}>
      <Modal.Header>
        <Modal.Title>{!access_key ? "Erstellen" : "Bearbeiten"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={fillForm()}
          enableReinitialize={true}
          validate={(values) => {
            const errors = {};

            if (!values.nfc_card_uid) {
              errors.nfc_card_uid = "NFC Nummer ist ein Pflichtfeld";
            }

            return errors;
          }}
          onSubmit={(values, { setSubmitting, setErrors }) => {
            setSubmitting(true);

            let request;

            if (access_key.id) {
              request = axios.put(`/api/v1/access_keys/${access_key.id}.json`, { access_key: values });
            }
            else {
              request = axios.post(`/api/v1/access_keys.json`, { access_key: values });
            }

            request.then(function() {
              toggle();
              handleSuccess();
            })
              .catch(function(e) {
                setErrors(e.response.data);
              });
          }}
        >
          {({ values, handleChange }) => (
            <Form autoComplete="false">
              <InputText name="name" label="Name"/>
              <InputText name="nfc_card_uid" label="NFC Nummer" hint="Die Nummer kann mit einem NFC Lesegerät ausgelesen werden" required="true"/>
              <InputText name="valid_until" label="Gültig bis" type="datetime-local"/>
              <BTForm.Check label="Aktiv" name="active" type="checkbox" id="active" value={values.active} onChange={handleChange} checked={values.active}/>
            </Form>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Schließen
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditAccessKeyModal;