import React from "react";
import {Button, Modal} from "react-bootstrap";

const ConfirmDeleteDialog = ({show, handleDelete, handleClose}) => {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header>
        <Modal.Title>Eintrag löschen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Möchten Sie diesen Eintrag wirklich löschen?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Schließen
        </Button>
        <Button variant="danger" onClick={handleDelete}>
          Löschen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDeleteDialog;