import i18n from "i18next";
import {modifyState} from "../components/UiComponents/Buttons/BackButton/helper";
import history from "./history";

export const navigateTo = (path) => {
  history.push(linkBuilder(path), {...modifyState(history, location.pathname)});
}

export const linkBuilder = (path) => {
  const lng = i18n.language !== i18n.options.fallbackLng[0] ? `/${i18n.language}` : "";
  return `${lng}${path}`;
}