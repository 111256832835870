import {Route, Switch, useRouteMatch} from "react-router-dom";
import React from "react";
import NewOnlineReservationPage from "../pages/OnlineReservations/pages/NewOnlineReservationPage";
import OnlineReservations from "../pages/OnlineReservations";
import EditOnlineReservationBookingDetailsPage from "../pages/OnlineReservations/pages/EditBookingDetailsPage";
import EditOnlineReservationContactDetailsPage from "../pages/OnlineReservations/pages/EditContactDetailsPage";
import EditOnlineReservationOverviewPage from "../pages/OnlineReservations/pages/EditOnlineReservationOverviewPage";
import OnlineReservationThankYouPage from "../pages/OnlineReservations/pages/OnlineReservationThankYouPage";

const ReservationRoutes = () => {
  let {path, url} = useRouteMatch();

  window.dataLayer = window.dataLayer || [];

  return (
    <OnlineReservations>
      <Switch>
        <Route exact path={`${path}`} component={NewOnlineReservationPage}/>
        <Route path={`${path}/:token/booking-details`} component={EditOnlineReservationBookingDetailsPage}/>
        <Route path={`${path}/:token/contact-details`} component={EditOnlineReservationContactDetailsPage}/>
        <Route path={`${path}/:token/overview`} component={EditOnlineReservationOverviewPage}/>
        <Route path={`${path}/thank-you`} component={OnlineReservationThankYouPage}/>
      </Switch>
    </OnlineReservations>
  );
};

export default ReservationRoutes;