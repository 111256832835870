import React from "react"
import {Route, Redirect} from "react-router-dom"
import {isAuthenticated} from "./services/AuthService"

const ProtectedRoutes = ({component: Component, ...rest}) => {
  return (
    <Route {...rest} render={(props) => {
      if (isAuthenticated()) {
        return <Component/>
      } else {
        return <Redirect to={{pathname: "/signin", state: {from: props.location}}}/>
      }
    }}/>
  )
}

export default ProtectedRoutes