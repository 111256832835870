import React from "react";

const CardBasic = ({title, children, footer}) => {
  return (
      <div className="card shadow mb-4">
          <div className="card-header py-3 text-primary font-weight-bold m-0 h6">
              {title}
          </div>
          <div className="card-body">
              {children}
          </div>
          {footer && <div className="card-footer py-3">
              {footer}
          </div>}
      </div>
  );
};

export default CardBasic;