import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MHLogo from 'src/images/mhteam.png';
import {faChevronLeft,faTachometerAlt} from "@fortawesome/free-solid-svg-icons";

class AdminSidebar extends Component {
  render() {
    const { clickMenuOpen, toggled } = this.props;
    return (
      <ul className={toggled ? 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled' : 'navbar-nav bg-gradient-primary sidebar sidebar-dark accordion'} id="accordionSidebar">
        <a className="sidebar-brand d-flex align-items-center justify-content-center" href="#">
          <div className="sidebar-brand-icon ">
            <img className="w-75" src={MHLogo} />
          </div>
          <div className="sidebar-brand-text mx-3 text-nowrap">MH-Team</div>
        </a>

        <hr className="sidebar-divider my-0" />
        <li className="nav-item">
          <Link className="nav-link" to="/dashboard">
            <FontAwesomeIcon className="fa-fw mr-2" icon={faTachometerAlt} />
            <span>Alle Kunden</span>
          </Link>
        </li>

        <hr className="sidebar-divider d-none d-md-block" />

        {/* <!-- Sidebar Toggler (Sidebar) --> */}
        <div className="text-center d-none d-md-inline">
          <button
            onClick={() => { clickMenuOpen() }}
            className="rounded-circle border-0"
            style={{width: "40px", height: "40px", backgroundColor: "rgba(255,255,255,.2)", color: "white"}}>
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
        </div>
      </ul>)
  }
}

export default AdminSidebar;