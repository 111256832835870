import React, {useEffect} from "react"
import {Form} from "react-bootstrap"
import {useField} from "formik"
import classNames from "classnames";
import {useTranslation} from "react-i18next";

const SelectBox = ({ label, options, option_name, option_value, onChange = null, required = false, hint="", className = "mb-3", ...rest}) => {
  const [field, meta] = useField(rest)
  const {t} = useTranslation("common");
  return (
    <Form.Group className={className}>
      <Form.Label>{label} {required && "*"}</Form.Label>
      <Form.Select className={`form-control`} {...field} value={field.value || 0} onChange={onChange ?? field.onChange}>
        <option value="null">{t("components.selectBox.select")}</option>

        {options && options.map((option, index) => (
          <option key={index} value={option[option_value]}>{option[option_name]}</option>
        ))}
      </Form.Select>
      <Form.Text className="text-muted">
        {hint}
      </Form.Text>
      <span className="text-danger">{meta.error}</span>
    </Form.Group>
  )
}

export default SelectBox