import React, {useState} from "react";
import classNames from "classnames";

export default function OrderButtonDropdown({selected, orders, callback}) {
  const [showMenu, setShowMenu] = useState(false);

  function changeValue(selected_value) {
    callback(selected_value);
    setShowMenu(false);
  }

  return <>
    <button type="button" className="btn btn-outline-secondary btn-sm ui-order-dropdown-btn"
            onClick={() => setShowMenu(!showMenu)}>
      <svg className="text-gray-800 dark:text-white ui-icon-normal" aria-hidden="true"
           xmlns="http://www.w3.org/2000/svg"
           width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M7 4v16M7 4l3 3M7 4 4 7m9-3h6l-6 6h6m-6.5 10 3.5-7 3.5 7M14 18h4"/>
      </svg>
    </button>
    {showMenu && (<div className="ui-order-dropdown-container">
      {orders.map((order) => (
        <div key={order.key}
             className={classNames("w-100 ui-order-dropdown-item", {"bg-primary text-white": selected === order.key})}
             onClick={() => changeValue(order.key)}>
          {order.value}
        </div>
      ))}
    </div>)}
  </>
}

