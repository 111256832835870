import React from 'react';

export default function MultiSelectBoxItem({selected, onSelect, label, value, color}) {
  return (
    <div className="dropdown-item d-flex align-items-center" onClick={onSelect}>
      <div className="form-check">
        <input className="form-check-input" type="checkbox" checked={selected} readOnly={true} value={value}/>
        <span className="form-check-label force-pointer">
        {label}
        </span>
      </div>
      <div className="ui-mini-box ui-ml-0_4" style={{background: color}}></div>
    </div>
  )
}