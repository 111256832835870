import React from "react"
import {Form} from "react-bootstrap"
import {useField} from "formik"

const InputText = ({ label, required = false, disabled = false, hint="", placeholder = null, type="text", min = null, onChange = null, onBlur = null, ...rest}) => {
  const [field, meta] = useField(rest)

  const defaultValue = () => {
    return typeof field.value === "number" ? 0 : "";
  }

  return (
    <Form.Group className="mb-3" >
      <Form.Label>{label} {required && "*"}</Form.Label>
      <Form.Control {...field} type={type} disabled={disabled} placeholder={placeholder} min={min} onBlur={onBlur ?? field.onBlur} onChange={onChange ?? field.onChange} value={field.value || defaultValue()}/>
      <Form.Text className="text-muted">
        {hint}
      </Form.Text>

      <small className="text-danger">{meta.error}</small>
    </Form.Group>
  )
}

export default InputText