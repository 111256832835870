import React, {useState} from "react";
import {Badge, Button, Row} from "react-bootstrap";
import useAccessKeys from "./hooks/useAccessKeys";
import Card from "src/components/Cards/Basic";
import useModal from "../../../components/Modal/hooks/useModal";
import EditAccessKeyModal from "./EditAccessKeyModal";
import {showSuccessToast} from "../../../components/Toasts";
import {dateTimeFormatter} from "../../../global";
import ConfirmDeleteDialog from "../../../components/Modal/ConfirmDelete";
import {faKey, faPencil as PencilIcon, faTrash as DeleteIcon} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../components/IconButton";
import FabPlateau from "../../../components/UiComponents/Buttons/FabPlateau";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const AccessKeys = () => {
  const { archive, accessKeys, fetchAccessKeys } = useAccessKeys();
  const { isShow: showArchive, toggle: toggleArchive } = useModal();
  const { isShow: showEdit, toggle: toggleEdit } = useModal();
  const [selectedAccessKey, setSelectedAccessKey] = useState({});

  const handleEdited = () => {
    showSuccessToast();
    fetchAccessKeys();
  };

  const handleDelete = () => {
    archive(selectedAccessKey);
    toggleArchive();
  };

  return (
    <>
      <Card title={`Alle Zutrittsschlüssel (${accessKeys.length ?? 0})`}>
        <div className="table-responsive">
          {/*<Button className="mb-3" variant="primary" onClick={() => {
            toggleEdit();
            setSelectedAccessKey({});
          }}>Zutrittsschlüssel erstellen</Button>*/}
          <div className='ui-table-container'>
            <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
              <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Gültig bis</th>
                <th>NFC Nummer</th>
                <th>Optionen</th>
              </tr>
              </thead>
              <tbody>
              {accessKeys && accessKeys.map((access_key) => {
                  return (
                    <tr key={access_key.id}>
                      <td>
                        {access_key.id}
                        &nbsp;
                        {access_key.active ? <Badge pill bg="primary" text="light">Aktiv</Badge> :
                          <Badge pill bg="danger" text="light">Nicht aktiv</Badge>}
                      </td>
                      <td>{access_key.name}</td>
                      <td>{access_key.valid_until && dateTimeFormatter(access_key.valid_until)}</td>
                      <td>{access_key.nfc_card_uid}</td>
                      <td>
                        <IconButton className="mr-1 bg-transparent border-0 mr-3" textColor="var(--primary)" icon={PencilIcon} onClick={() => {
                          setSelectedAccessKey(access_key);
                          toggleEdit();
                        }}
                        />
                        <IconButton className="mr-2 mr-3 bg-transparent border-0" textColor="var(--danger)" icon={DeleteIcon} onClick={() => {
                          setSelectedAccessKey(access_key);
                          toggleArchive();
                        }}
                        />
                      </td>
                    </tr>
                  );
                }
              )}
              {accessKeys?.length === 0 && (
                <tr>
                  <td>Keine Zutrittsschlüssel vorhanden</td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
        <Row className="justify-content-end mr-2 mt-5">
          <FabPlateau child={(<Button className="float-right p-2 pl-3 pr-3" variant="primary" onClick={() => {
            toggleEdit();
            setSelectedAccessKey({});
          }}>
            <FontAwesomeIcon icon={faKey}/> &nbsp;Zutrittsschlüssel anlegen
          </Button>)}/>
        </Row>
      </Card>
      <EditAccessKeyModal show={showEdit} toggle={toggleEdit} access_key={selectedAccessKey}
                          handleSuccess={handleEdited}/>
      <ConfirmDeleteDialog show={showArchive} handleClose={toggleArchive} handleDelete={handleDelete}/>
    </>
  );
};

export default AccessKeys;
