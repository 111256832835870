import React, {useRef} from "react";
import {Button, Modal} from "react-bootstrap";
import {Form, Formik} from "formik";
import InputText from "../../../../components/Forms/InputText";
import axios from "../../../../config/axios";

const OnlineReservationDeclineDialog = ({onlineReservationId, isShow, setIsShow, handleClose, handleSubmit}) => {
  const formRef = useRef();

  return (
    <Modal onHide={handleClose} show={isShow}>
      <Modal.Header closeButton>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          innerRef={formRef}
          initialValues={{cancellation_reason: ""}}
          enableReinitialize={true}
          onSubmit={async (values) => {
            await axios.post(`/api/v1/online_reservations/${onlineReservationId}/decline.json`, { cancellation_reason: values.cancellation_reason })
            setIsShow(false);
            handleSubmit();
          }}
        >
          <Form autoComplete="false">
            <InputText name={"cancellation_reason"} label={"Begründung"} hint={"Die Begründung wird dem Gast per E-Mail zugeschickt,"}/>
          </Form>
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Zurück
        </Button>

        <Button onClick={() => formRef.current.handleSubmit()}>
          Stornieren
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default OnlineReservationDeclineDialog;
