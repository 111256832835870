import React from "react";
import AdminSidebar from "./Components/Navigation/AdminSidebar";
import AdminTopbar from "./Components/Navigation/AdminTopbar";

const AdminDashboard = (props) => {
  return (
    <div>
      <div id="wrapper">
        <AdminSidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <AdminTopbar />
            <div className="p-4">{props.children}</div>
          </div>
          <footer className="sticky-footer bg-white">
            <div className="container my-auto">
              <div className="copyright text-center my-auto font-weight-normal">
                <span>Copyright &copy; <b><span className='text-color-rura'>Rura</span>Tec</b> 2024</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>
    </div>
  );
};

export default AdminDashboard;