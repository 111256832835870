export function keepState(history) {
  //wenn hier ausgeführt, wird einmal alle routen von hinten aus dem LocStack removen bis die momentane route erreicht ist.
  const currentRoute = history?.location?.pathname ?? '/';
  const prevState = history?.location?.state ?? {};
  let locationStack = prevState?.locationStack ?? [];
  for(let i = locationStack.length - 1; i >= 0; i--) {
    if(locationStack[i] === currentRoute) {
      locationStack.splice(i, locationStack.length - i);
      break;
    }
  }
  return {...prevState, locationStack: locationStack};
}

export function modifyState(history, route) {
  const state = keepState(history);
  state?.locationStack?.push(route);
  return state;
}