import React, { useEffect, useState } from "react";
import Dashboard from "..";
import Card from "src/components/Cards/Basic";
import axios from "../../../config/axios";
import { Button, Col, Row } from "react-bootstrap";
import InputText from "../../../components/Forms/InputText";
import { Formik, Form } from "formik";
import { showErrorToast, showSuccessToast } from "../../../components/Toasts/";

const BookingSettings = () => {
  const [bookingSettings, setBookingSettings] = useState([]);

  useEffect(() => {
    fetchBookingSettings();
  }, []);

  const fetchBookingSettings = () => {
    axios.get("/api/v1/booking_settings.json")
      .then(function(response) {
        setBookingSettings(response.data.entries);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <>
      <Row>
        {bookingSettings?.map((bookingSetting, index) => (
          <Col key={index} xs={12} sm={3}>
            <Card title={bookingSetting.formatted_name}>
              <Formik
                initialValues={bookingSetting}
                enableReinitialize={true}
                onSubmit={(booking_setting, { setSubmitting }) => {
                  setSubmitting(true);

                  axios.put(`/api/v1/booking_settings/${bookingSetting.id}.json`, { booking_setting: booking_setting, id: booking_setting.id })
                    .then(function() {
                      showSuccessToast();
                    })
                    .catch(function() {
                      showErrorToast();
                    });
                }}
              >
                {({ values }) => (
                  <Form name="booking_setting" autoComplete="false">
                    <input type="hidden" value={bookingSetting.id} />

                    <InputText name="value" label="Wert" type="text" />
                    <Button type="submit" variant="primary">Bestätigen</Button>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default BookingSettings;
