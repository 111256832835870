import React from 'react';
import styles from "./InfoList.module.css"

const InfoList = ({children}) => {
  return (
    <ul className={styles.infoList}>
      {children}
    </ul>
  );
};

export default InfoList;