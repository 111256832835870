import axios from "src/config/axios";
import {useEffect, useState} from "react";

const useBusiness = (id) => {
  const [business, setBusiness] = useState();

  useEffect(() => {
    if (id) {
      axios.get(`/api/v1/admin/businesses/${id}.json`)
        .then(function(response) {
          setBusiness(response.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    }
  }, [id]);

  return business;
};

export default useBusiness;