import React from "react";
import OnlineReservationsView from "../../views/OnlineReservationsView";


const OnlineReservationsPage = () => {

  return(
    <OnlineReservationsView />
  );
};

export default OnlineReservationsPage;
