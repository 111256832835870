import axios from 'axios';
import {subdomain} from "../global";
import moment from "moment";
import i18n from "i18next";

const lng = i18n.language !== i18n.options.fallbackLng[0] ? `/${i18n.language}` : "";

const instance = axios.create({
  baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.port}${lng}`
});

const regexIso8601 = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;


instance.interceptors.response.use(originalResponse => {
  originalResponse.data = findIso8601ValuesInObject(originalResponse.data);
  return originalResponse;
}, (error) => {
  if (error.response.status === 401) {
    localStorage.clear();
    window.location.href = "/signin";
  }

  return Promise.reject(error);
});

const findIso8601ValuesInObject = (response) => {
  for (let key in response) {
    let value = response[key];

    if (typeof value !== "object") {
      let match;

      if (typeof value === "string" && (match = value.match(regexIso8601))) {
        value = convertToFormattedDate(value);
        response[key] = value;
      }
    }
    else {
      findIso8601ValuesInObject(value);
    }
  }

  return response;
};

const convertToFormattedDate = (date) => {
  return moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL);
};

instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.headers.common['X-USER-EMAIL'] = localStorage.getItem('user_email') ?? '';
instance.defaults.headers.common['X-USER-TOKEN'] = localStorage.getItem('user_token') ?? '';
instance.defaults.headers.common['X-USER-BUSINESS_ID'] = subdomain ?? '';

export default instance;
