import React from "react";
import { ToastContainer } from 'react-toastify';
import Routes from "./routes";

const App = () => {
  return (
    <>
      <Routes />
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
};
export default App;
