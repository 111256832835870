import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import axios from "../../config/axios";

const OnlineReservationsDateRangePicker = ({from, to, setFieldValue, onChange = null}) => {
  const [availabilities, setAvailabilities] = useState({});

  const [dateRange, setDateRange] = useState([{
    startDate: from,
    endDate: to,
    key: "selection",
  }]);

  const [changeSelection, setChangeSelection] = useState("start");

  useEffect(() => {
    const from = dateRange[0].startDate ? dateRange[0].startDate : new Date();
    fetchAvailabilities(from)
  }, []);

  function fetchAvailabilities(from) {
    let start_of_month = moment(from).startOf("month");
    axios.get("online_reservations/availabilities.json?from=" + start_of_month.toDate()).then((response) => {
      const availabilities = response.data;
      axios.get("online_reservations/availabilities.json?from=" + start_of_month.add(1, "month").toDate()).then((response) => {
        setAvailabilities({...availabilities, ...response.data});
      })
    })
  }

  const windowWidth = window.innerWidth;
  const minTabletWidth = 768;

  if (availabilities.length === 0) {
    return <></>
  }

  return (<>
        <DateRange
            disabledDateInputs={!availabilities}
            editableDateInputs={true}
            minDate={new Date()}
            onChange={(item) => {
              setDateRange([item.selection]);
              if (from !== item.selection.startDate) {
                setFieldValue("from", item.selection.startDate)
                setChangeSelection("end")
              }

              if (to !== item.selection.endDate && item.selection.startDate !== item.selection.endDate) {
                setFieldValue("to", item.selection.endDate)
                setChangeSelection("start")
              }

              if (onChange) {
                onChange(item.selection.startDate, item.selection.endDate)
              }
            }}
            disabledDay={(day) => {
              const currentKey = moment(day).format('YYYY-MM-DD')

              if (changeSelection === "start") {
                return !availabilities[currentKey]
              } else {
                const startKey = moment(dateRange[0].startDate).format('YYYY-MM-DD')
                return !availabilities[startKey] || !availabilities[startKey]["departures"].includes(currentKey.toString())
              }
            }}
            onShownDateChange={fetchAvailabilities}
            moveRangeOnFirstSelection={false}
            months={2}
            direction={windowWidth < minTabletWidth ? "vertical" : "horizontal"}
            ranges={dateRange}
        />
  </>
  );
}

export default OnlineReservationsDateRangePicker;