import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";

export default function TableIconButton({onClick, icon, variant = "secondary"}) {

    return (
        <button className={classNames(`m-2 ui-icon-button`, {
          "text-primary": variant === "primary",
          "text-secondary": variant === "secondary"
        })} onClick={onClick}>
            <FontAwesomeIcon icon={icon} />
        </button>
    )
}