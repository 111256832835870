import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import styles from "./OnlineReservationSteps.module.css"
import classNames from "classnames";
import {Link, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const OnlineReservationSteps = ({step}) => {
  const {token} = useParams();
  const {t} = useTranslation("reservations");

  return (
    <Row>
      <Col md={9}>
        <div className={styles.container}>
          <div className={styles.dotContainer} to={`/reservations/${token}/booking-details`}>
            <span className={classNames(styles.dot, {[`${styles.active}`]: step === 1})}>1</span>
            <label className={styles.label}>{t("steps.bookingDetails")}</label>
          </div>
          <span className={`${styles.label} ${styles.arrow}`}>&rarr;</span>
          <div className={styles.dotContainer} to={`/reservations/${token}/contact-details`}>
            <span className={classNames(styles.dot, {[`${styles.active}`]: step === 2})}>2</span>
            <label className={styles.label}>{t("steps.contactDetails")}</label>
          </div>
          <span className={`${styles.label} ${styles.arrow}`}>&rarr;</span>
          <div className={styles.dotContainer} to={`/reservations/${token}/overview`}>
            <span className={classNames(styles.dot, {[`${styles.active}`]: step === 3})}>3</span>
            <label className={styles.label}>{t("steps.overview")}</label>
          </div>
        </div>
      </Col>
      <Col md={3}>

      </Col>
    </Row>
  )
}

export default OnlineReservationSteps;