import {useEffect, useState} from "react";
import axios from "../../../config/axios";
import {useParams} from "react-router-dom";

const useOnlineReservation = () => {
  const [onlineReservation, setOnlineReservation] = useState(null);
  const { token } = useParams();

  useEffect(() => {
    loadOnlineReservation();
  }, []);

  const loadOnlineReservation = () => {
    axios.get(`/online_reservations/${token}.json`)
      .then(function(response) {
        setOnlineReservation(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const update = async (onlineReservation) => {
    const response = await axios.put(`/online_reservations/${onlineReservation.token}.json`, {online_reservation: onlineReservation})
    setOnlineReservation(response.data);
    return response.data;
  };

  return { update, onlineReservation}
}
export default useOnlineReservation;