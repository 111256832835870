import React from "react";
import axios from "../../../../config/axios";
import BookingTable from "../Components/BookingTable";

const ActiveBookings = () => {
  const getBookings = async (params = {}) => {
    return await axios.get("/api/v1/bookings.json", { params: { ...params, active: true } });
  };

  return (
    <BookingTable title="Aktive Buchungen" getBookings={getBookings}/>
  );
};

export default ActiveBookings;
