import React from "react";
import axios from "../../../../config/axios";
import BookingTable from "../Components/BookingTable";

const AllBookings = () => {
  const getBookings = async (params) => {
    return await axios.get("/api/v1/bookings.json", { params: params });
  };

  return (
    <BookingTable title="Alle Buchungen" getBookings={getBookings}/>
  );
};

export default AllBookings;
