import React from "react"
import axios from "../../config/axios"
import {useFormik} from "formik"
import MHLogo from "../../images/mhteam.png"
import LoginBackground from "../../images/login-background.jpg"
import LoginCamper from "../../images/login-camper.png"
import {subdomain} from "../../global"
import history from "../../global/history"

const SignIn = () => {
  const submit = (values) => {
    axios.post("/users/sign_in.json", {
      user: {
        email: values.email,
        password: values.password,
        business_id: subdomain
      }
    })
    .then(function (response) {
      localStorage.setItem("user_email", values.email)
      localStorage.setItem("user_token", response.data.authentication_token)
      axios.defaults.headers.common['X-USER-EMAIL'] = localStorage.getItem('user_email') ?? ''
      axios.defaults.headers.common['X-USER-TOKEN'] = localStorage.getItem('user_token') ?? ''
      history.push("/dashboard")
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  const validate = (values) => {
    const errors = {}

    if (!values.email) {
      errors.email = "E-Mail wird benötigt"
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = "Ungültige E-Mail"
    }

    if (!values.password) {
      errors.password = "Password wird benötigt"
    }
    return errors
  }

  const form = useFormik({
    initialValues: {email: "", password: ""},
    validate,
    onSubmit: (values) => {
      submit(values)
    }
  })


  return (
    <div style={{
      backgroundImage: `url(${LoginBackground})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      height: "100vh"
    }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-10 col-lg-12 col-md-9">
            <div className="card o-hidden border-0 shadow-lg my-5">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-lg-6 d-none d-lg-block bg-login-image" style={{backgroundImage: `url(${LoginCamper})`}}></div>
                  <div className="col-lg-6">
                    <div className="p-5">
                      <div className="text-center">
                        <img src={MHLogo} alt="MH-Team" className="w-50"/>
                        <h1 className="h4 text-gray-900 mb-4">Willkommen!</h1>
                      </div>
                      <form onSubmit={form.handleSubmit}>
                        <div className="form-group">
                          <input
                            className="form-control form-control-user"
                            type="email"
                            name="email"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.email}
                          />
                          {form.errors.email && form.touched.email && form.errors.email}
                        </div>
                        <div className="form-group">
                          <input
                            className="form-control form-control-user"
                            type="password"
                            name="password"
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            value={form.values.password}
                          />
                          {form.errors.password && form.touched.password && form.errors.password}
                        </div>
                        <button type="submit" className="btn btn-primary btn-user btn-block">
                          Anmelden
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignIn