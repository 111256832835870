import React from 'react';
import history from "../../../../global/history";
import {Link} from "react-router-dom";
import {keepState} from "./helper";

export default function BackButton({route, fallbackRoute = '/', additional_data = {}}) {
  const prevState = keepState(history);
  const locStack = prevState?.locationStack ?? [route];
  const r = route ?? (locStack.length > 0 ? locStack[locStack.length - 1] : fallbackRoute);
  const state = {...prevState, locationStack: locStack, ...additional_data};

  return (
    <Link to={{
      pathname: r,
      state: state
    }} className='btn'>
      <svg className="text-primary" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
           width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3"
              d="m15 19-7-7 7-7"/>
      </svg>
    </Link>
  )
}