import React, {useContext, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBell,
  faSignOutAlt,
  faUser, faEyeSlash, faBars, faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import {logout} from "../../../services/AuthService";
import axios from "../../../config/axios";
import Alert from "./_alerts";
import {DashboardContext} from "../../../pages/Dashboard";

const Topbar = ({clickMenuOpen}) => {
  const [alerts, setAlerts] = useState([]);
  const [alertCount, setAlertCount] = useState(0);

  const dashboardContext = useContext(DashboardContext)

  const getAlerts = () => {
    var filter = { seen: false }

    axios.get(`/api/v1/alerts.json`, { params: { filter: filter }})
    .then(function (response) {
      setAlerts(response.data.entries);
      setAlertCount(response.data.count);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const updateAlertSeen = (alert) => {
    alert.seen = true;

    axios.put(`/api/v1/alerts/${alert.id}/read.json`, { alert })
    .then(function () {
      getAlerts();
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  const updateAllAlertsSeen = () => {
    axios.put(`/api/v1/alerts/read_all.json`)
        .then(function () {
          getAlerts();
        })
        .catch(function (error) {
          console.log(error);
        });
  };

  useEffect(() => {
    getAlerts();
  }, []);

  return (
      <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
        <button onClick={() => clickMenuOpen()} id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
          <FontAwesomeIcon icon={faBars}/>
        </button>
        {/*<button type="button" className="btn btn-outline-secondary d-none d-md-block btn-sm ml-2"*/}
        {/*        onClick={() => history.back()}>*/}
        {/*  <FontAwesomeIcon icon={faArrowLeft}/>*/}
        {/*  <span className="ml-2">Zurück</span>*/}
        {/*</button>*/}

        <ul className="navbar-nav ml-auto">
          <li className="nav-item dropdown no-arrow mx-1">
            <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <FontAwesomeIcon icon={faBell}/>
              {alertCount > 0 && <span className="badge badge-danger badge-counter">{alertCount}</span>}
            </a>
            <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                 aria-labelledby="alertsDropdown">
              <div className="dropdown-header d-flex justify-content-between align-content-center text-center">
                <h6 className="mt-1 mb-0 pb-0 p-1">
                  Benachrichtigungen
                </h6>
                <button className="btn btn-link m-0 mt-0 mb-0 px-2 py-1 rounded text-light btn-primary"
                        onClick={updateAllAlertsSeen}>
                  <FontAwesomeIcon icon={faEyeSlash}/>
                </button>
              </div>
              {alerts.map((alert) => (
                  <Alert key={alert.id} alert={alert} updateAlertSeen={updateAlertSeen}/>
              ))}
            </div>
          </li>

          <div className="topbar-divider d-none d-sm-block"></div>
          <li className="nav-item dropdown no-arrow">
            <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" data-toggle="dropdown"
               aria-haspopup="true" aria-expanded="false">
              <span className="mr-2 d-none d-lg-inline text-gray-600">{dashboardContext?.name}</span>
              <FontAwesomeIcon className="fa-fw mr-2 text-gray-400" icon={faUser}/>
            </a>
            <div className="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
              <div className="dropdown-item text-danger ui-hover-danger force-pointer" onClick={() => logout()}>
                <FontAwesomeIcon className="fa-fw mr-2 text-danger" icon={faSignOutAlt}/>
                <span>Logout</span>
              </div>
            </div>
          </li>
        </ul>
      </nav>
  );
};

export default Topbar;