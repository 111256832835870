import React from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

const OnlineReservationThankYouPage = () => {
  const {t} = useTranslation("reservations");

  return (
    <Row>
      <Col>
        <h1>{t("thankYou.title")}</h1>
        <p>
          {t("thankYou.paragraph1")}
          <br/>
          {t("thankYou.paragraph2")}
        </p>
      </Col>
    </Row>
  );
};


export default OnlineReservationThankYouPage;