import React from "react";
import NewOnlineReservationView from "../../views/NewOnlineReservationView";

const NewOnlineReservationPage = () => {

  return(
    <NewOnlineReservationView />
  );
};

export default NewOnlineReservationPage;
