import React, {useContext, useEffect, useState} from "react";
import {Button, Badge, Form, Row, Col} from "react-bootstrap";
import {dateFormatter, dateTimeFormatter, dateTimeFormatterWithDay, timeFormatter} from "../../../../../global";
import history from "../../../../../global/history";
import Card from "src/components/Cards/Basic";
import InfoModal from "src/components/Modal/Info";
import axios from "src/config/axios";
import CashIcon from "src/images/icons/cash.png";
import CreditCardIcon from "src/images/icons/credit-card.png";
import {
  faEye as DetailsIcon,
  faClockRotateLeft as HistoryIcon,
  faSearch,
  faAdd,
  faPencil as EditIcon,
  faTriangleExclamation as WarnIcon,
  faInfoCircle,
  faAngleDoubleLeft as DriveInIcon,
  faAngleDoubleRight as DriveOutIcon,
  faCheck as GrantedIcon, faBan as DeniedIcon
} from "@fortawesome/free-solid-svg-icons";
import SimplePagination from "../../../../../components/SimplePagination";
import {DashboardContext} from "../../../index";
import {useTranslation} from "react-i18next";
import TableHeadGen from "../../../../../components/Tables/TableHeadGen/index.jsx";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TableIconButton from "../../../../../components/UiComponents/Buttons/TableIconButton";
import OrderButtonDropdown from "../../../../../components/UiComponents/Buttons/OrderButtonDropdown";
import FabPlateau from "../../../../../components/UiComponents/Buttons/FabPlateau";
import OnlineReservationDetailsDialog from "../../../views/OnlineReservationsView/OnlineReservationDetailsDialog";
import {modifyState} from "../../../../../components/UiComponents/Buttons/BackButton/helper";
import FilterResetButton from "../../../../../components/UiComponents/Buttons/FilterResetButton";
import LegendItem from "../../../../../components/Legend/LegendItem";
import Legend from "../../../../../components/Legend";
import {Link} from "react-router-dom";

const BookingTable = ({title, getBookings}) => {
  const [bookings, setBookings] = useState();
  const [showBookingHistory, setShowBookingHistory] = useState(false);
  const [bookingHistory, setBookingHistory] = useState();
  const {settings} = useContext(DashboardContext);
  const {t} = useTranslation("dashboard");
  const [parkingSlots, setParkingSlots] = useState([]);
  const [filterShow, setFilterShow] = useState(false);
  const businessSettings = settings?.business;
  const [onlineReservation, setOnlineReservation] = useState(null);

  const showOnlineReservationDialog = onlineReservation !== null;
  const [loading, setLoading] = useState(true);

  let columns = [
    {value: 'buyer', name: 'Käufer'},
    {value: 'booked_from', name: 'Anreise'},
    {value: 'booked_until', name: 'Abreise'},
    {value: "booking_type", name: "Typ"},
    {value: 'paid', name: 'Bezahlt'},
    {value: 'created_at', name: 'Erstellt am'},
    {value: "", name: 'Optionen'}
  ];

  const parkingSlotColumnPos = 1;
  if (businessSettings?.parking_slots_enabled) columns.splice(parkingSlotColumnPos, 0, {
    value: 'parking_slot',
    name: 'Stellplatz'
  });

  const defaultFilter = {
    page: 1,
    search: "",
    active: false,
    parking_slot_id: "",
    order: 'created_at_desc'
  };
  const [filter, setFilter] = useState(defaultFilter);

  async function applyFilter() {
    setLoading(true);
    const result = await getBookings(filter);
    setBookings(result.data);
    setLoading(false);
  }

  const searchBookings = (search) => {
    let sanitizedSearch = search;
    sanitizedSearch = sanitizedSearch.replaceAll(":", "");

    setFilter({...filter, search: sanitizedSearch, page: 1});
  };

  const goToPage = (page) => {
    setFilter({...filter, page: page});
  };

  useEffect(() => {
    applyFilter();
  }, [filter]);


  useEffect(() => {
    if (businessSettings?.parking_slots_enabled) {
      axios.get("/api/v1/parking_slots.json").then(function (response) {
        setParkingSlots(response.data.entries);
      })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [businessSettings])

  function showBookingHistoryDialog(bookingId) {
    axios.get(`/api/v1/bookings/${bookingId}/booking_history.json`)
      .then(function (response) {
        setBookingHistory(response.data.entries);
        setShowBookingHistory(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function renderFilters() {
    if (!filterShow) {
      return (<>
      </>)
    }
    return (<>
      <Row className="optional-filter-container align-items-center">
      </Row>
    </>)
  }

  const header = (<div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
    <span>{title} ({bookings?.pagination?.count ?? 0})</span>
    <FilterResetButton resetCallback={() => {
      setFilter(defaultFilter);
    }}/>
  </div>);

  return (
    <>
      <Card title={header}>
        <div className="mb-3">
          <Row className="filter-container align-items-center">
            <Col md={6} xs={12} className="d-flex mb-4 mb-sm-0 justify-content-between justify-content-sm-start">
              <OrderButtonDropdown selected={filter.order} orders={[
                {key: "created_at_desc", value: "Neueste zuerst"},
                {key: "created_at_asc", value: "Älteste zuerst"},
                {key: "booked_from_asc", value: "Anreisedatum aufsteigend"},
                {key: "booked_from_desc", value: "Anreisedatum absteigend"}
              ]} callback={(selected_value) => {
                setFilter({
                  ...filter,
                  order: selected_value
                });
              }}/>
            </Col>
            <Col md={3} xs={12} className={"d-none d-sm-block"}>
              {businessSettings?.parking_slots_enabled && parkingSlots.length > 0 && (
                <Form.Group className="mb-2">
                  <Form.Label>Stellplatz</Form.Label>
                  <Form.Select className="form-control ui-input-bg" value={filter.parking_slot_id ?? ""} onChange={(e) => {
                    setFilter({
                      ...filter,
                      parking_slot_id: e.target.value === "null" ? undefined : e.target.value,
                      page: 1
                    });
                  }}>
                    <option value="null">Alle</option>
                    {parkingSlots.map((parkingSlot) => (
                      <option key={parkingSlot.id} value={parkingSlot.id}>{parkingSlot.name}</option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-muted">&nbsp;</Form.Text>
                </Form.Group>
              )}
            </Col>
            <Col md={3} xs={12}>
              <Form.Group className="mb-2">
                <Form.Label>Suche</Form.Label>
                <div className="position-relative d-flex">
                  <div className="position-absolute p-2">
                    <FontAwesomeIcon icon={faSearch}/>
                  </div>
                  <Form.Control placeholder="Vorname, Nachname, Karte ..."
                                className="input-icon-p ui-input-bg" type="text"
                                value={filter.search}
                                onChange={(e) => searchBookings(e.target.value)}/>
                </div>
                <Form.Text className="text-muted">&nbsp;</Form.Text>
              </Form.Group>
            </Col>
          </Row>
          {renderFilters()}
          <div className='ui-table-container'>
            <table>
              {loading ?
                <thead>
                <tr>
                  <th>Käufer</th>
                  <th>Anreise</th>
                  <th>Abreise</th>
                  <th>Typ</th>
                  <th>Optionen</th>
                </tr>

                </thead> : <TableHeadGen columns={columns}/>
              }
              <tbody>
              {loading ? (
                  <>
                    {
                      Array.from({length: 10}, (_, i) => (
                        <tr key={i}>
                          <td>
                            <div className={"preview w-100px h-25px"}></div>
                          </td>
                          <td>
                            <div className={"preview w-100px h-25px"}></div>
                          </td>
                          <td>
                            <div className={"preview w-100px h-25px"}></div>
                          </td>
                          <td>
                            <div className={"preview w-100px h-25px"}></div>
                          </td>
                          <td>
                            <div className={"preview w-100px h-25px"}></div>
                          </td>
                        </tr>
                      ))
                    }
                  </>
                )
                : <>
                  {
                    bookings && bookings.entries.map(booking => (
                      <tr key={booking.id}>
                        <td>
                          <span>{(booking?.buyer?.full_name ?? booking?.online_reservation?.contact_person ?? "Gast unbekannt")}</span>
                          {(booking.state === "active" && !booking.checkin_at) && <span>
                            &nbsp;
                            <FontAwesomeIcon icon={WarnIcon} className={"text-warning"}/>
                          </span>}
                          <br/>
                          <Badge pill bg={booking.state === 'draft' ? 'secondary' : 'primary'}
                                 text="light">{t(`booking.statuses.${booking.state}`)}
                          </Badge>
                        </td>
                        {businessSettings?.parking_slots_enabled && <td>{booking.parking_slot?.name}</td>}
                        <td>
                          {booking.booked_from && (<>
                            <div>{dateTimeFormatterWithDay(booking.booked_from)}</div>
                          </>)}
                        </td>
                        <td>
                          {booking.booked_until && (<>
                            <div>{dateTimeFormatterWithDay(booking.booked_until)}</div>
                          </>)}
                          {booking.checkout_date && (<>
                            <strong>Checkout: {dateTimeFormatter(booking.checkout_date)}</strong>
                          </>)}
                        </td>
                        <td>
                          <div>{booking.booking_type.name}</div>
                        </td>
                        <td>
                          {booking.paid && (
                            booking.invoice?.payment_type === "cash" ?
                              <img className="w-25px" src={CashIcon} alt="cash"/> :
                              <img className="w-25px" src={CreditCardIcon} alt="cash"/>
                          )}
                        </td>
                        <td>
                          {booking.created_at && (<>
                            <div>{dateFormatter(booking.created_at)}</div>
                          </>)}
                        </td>
                        <td>
                          <div>
                            {
                              ["reservation_requested", "reservation_accepted"].includes(booking.state) ? (
                                  <>
                                    <TableIconButton icon={DetailsIcon} variant={"primary"}
                                                     onClick={() => setOnlineReservation(booking.online_reservation)}/>
                                    <TableIconButton icon={EditIcon}
                                                     onClick={() => history.push(`/dashboard/reservations/${booking?.online_reservation_id}/edit`, modifyState(history, location.pathname))}/>
                                  </>
                                ) :
                                (
                                  <>
                                    <Link to={{pathname: `/dashboard/bookings/${booking.id}`, state: modifyState(history, location.pathname)}}>
                                      <TableIconButton icon={DetailsIcon} variant={"primary"}/>
                                    </Link>

                                    {booking.buyer &&
                                      <TableIconButton icon={HistoryIcon}
                                                       onClick={() => showBookingHistoryDialog(booking.id)}/>
                                    }
                                  </>
                                )}
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                  {bookings?.entries.length === 0 && (
                    <tr>
                      <td colSpan={columns?.length || 9}>Keine Buchungen vorhanden</td>
                    </tr>
                  )}
                </>
              }
              </tbody>
            </table>
          </div>
        </div>
        <SimplePagination pagination={bookings?.pagination} onClick={(page) => goToPage(page)}/>
          <FabPlateau child={(<Button className="float-right p-2 pl-3 pr-3" variant="primary" onClick={() => {
            axios.post(`/api/v1/bookings.json`, {})
              .then(function (response) {
                history.push(`/dashboard/bookings/${response.data.id}/edit`);
              });
          }}>
            <FontAwesomeIcon icon={faAdd}/> &nbsp;Neue Buchung
          </Button>)}/>

        <Legend>
          <LegendItem icon={faInfoCircle} text={<b>Erklärung:</b>} classNames="font-weight-bold" />
          <LegendItem icon={WarnIcon} text="Check-In ausständig" classNames="text-warning" />
        </Legend>
      </Card>

      <InfoModal handleClose={() => setShowBookingHistory(false)} title="Buchungsverlauf" show={showBookingHistory}>
        {bookingHistory?.map((booking, i) => (<div key={booking.id}>
          {booking.buyer ? <>Buchung von: {booking.buyer.full_name}<br/></> : <></>}
          Buchungsnummer: {booking.id}<br/>
          Buchungstyp: {booking.booking_type.name}<br/>
          {booking.booked_from && (<>Von: {dateTimeFormatter(booking.booked_from)} <br/></>)}
          {booking.booked_until && (<>Bis: {dateTimeFormatter(booking.booked_until)}<br/></>)}
          {booking.checkout_date && (<>Checkout: {dateTimeFormatter(booking.checkout_date)}<br/></>)}
          {booking.parking_slot_id && (<>Stellplatz: {booking.parking_slot.name}<br/></>)}
          Herkunft: {t(`booking.sources.${booking.source}`)}<br/>
          {booking.online_reservation ? "Reservierung " + booking.online_reservation.id : <></>}<br/>
          Bezahlt: {booking.paid ? "Ja" : "Nein"}<br/>
          {bookingHistory.length - 1 !== i && <hr className="sidebar-divider"/>}
        </div>))}
      </InfoModal>

      {showOnlineReservationDialog &&
        <OnlineReservationDetailsDialog onlineReservationId={onlineReservation.id}
                                        handleClose={() => setOnlineReservation(null)}
                                        handleSubmit={() => {
                                          applyFilter();
                                          setOnlineReservation(null);
                                        }}/>
      }
    </>);
};

export default BookingTable;
