import React from 'react';
import {Card} from "react-bootstrap";
import MultiSelectBox from "../../../../components/UiComponents/MultiSelectBox";

export default function TestDebugPage() {

  function change(c) {
    console.log("change", c);
  }
    return (
        <Card>
            <Card.Body>
              <Card.Title><b>Test Seite</b></Card.Title>
              <Card.Text>
                Für Debugging Zwecke :)
              </Card.Text>
              <MultiSelectBox onChange={change} defaultValues={['blue', 'yellow']} label="Farbe" options={[
                {name: "Blau", value: "blue", color: "blue"},
                {name: "Rot", value: "red", color: "#e62e4d"},
                {name: "Gelb", value: "yellow"},
                {name: "Grün", value: "green"}
              ]} />
            </Card.Body>
        </Card>
    )
}