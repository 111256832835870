import React from "react";
import {Button, Col, Row} from "react-bootstrap";
import axios from "../../../../config/axios";
import {showErrorToast} from "../../../../components/Toasts";
import {Form, Formik} from "formik";
import history from "src/global/history";
import OnlineReservationDateRangePicker from "../../OnlineReservationDateRangePicker";
import {useTranslation} from "react-i18next";
import {navigateTo} from "../../../../global/navigationHelper";

const NewOnlineReservationPage = () => {
  const {t, i18n} = useTranslation("reservations");

  const handleSubmit = (values) => {
    axios.post(`/online_reservations.json`, {online_reservation: values})
      .then(function (response) {
        navigateTo(`/reservations/${response.data.token}/booking-details`)
      })
      .catch(function () {
        showErrorToast(t("errors.reservation.from_to.missing", { ns: "errors" }));
      });
  };

  return (
    <Formik
      initialValues={{from: null, to: new Date("") }}
      onSubmit={handleSubmit}>
      {({values, setFieldValue}) => (
        <Form autoComplete="false">
          <Row>
            <Col>
              <h2 className={"mb-3"}>{t("newOnlineReservation.bookNow")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <OnlineReservationDateRangePicker from={values.from} to={values.to} setFieldValue={setFieldValue} />
              </div>
              <Button
                variant="primary"
                type={"submit"}
              >
                {t("newOnlineReservation.searchAvailabilities")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};


export default NewOnlineReservationPage;