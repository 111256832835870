import moment from "moment/moment";
import React, {useEffect, useState} from "react";
import axios from "../../../config/axios";
import {DateRange} from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import i18n from "i18next";
import { it, de, enUS as en } from 'date-fns/locale'
const OnlineReservationsDateRangePicker = ({from, to, setFieldValue, onChange = null}) => {
  const [availabilities, setAvailabilities] = useState([]);

  const [dateRange, setDateRange] = useState([{
    startDate: from,
    endDate: to,
    key: "selection",
  }]);

  const [changeSelection, setChangeSelection] = useState("start");

  useEffect(() => {
    const from = dateRange[0].startDate ? dateRange[0].startDate : new Date();
    fetchAvailabilities(from)
  }, []);

  function fetchAvailabilities(from) {
    const min_date = moment(from).isBefore(new Date()) ? new Date() : from;
    const start_month = moment(min_date).format("YYYY-MM-DD");

    if (!availabilities[start_month]) {
      axios.get("online_reservations/availabilities.json?from=" + start_month).then((response) => {
        setAvailabilities(prevState => ({...prevState, ...response.data}));
      });
    }

    const next_month = moment(from).add(1, "month").startOf("month").format("YYYY-MM-DD");

    if (!availabilities[next_month]) {
      axios.get("online_reservations/availabilities.json?from=" + next_month).then((response) => {
        setAvailabilities(prevState => ({...prevState, ...response.data}));
      });
    }
  }

  const windowWidth = window.innerWidth;
  const minTabletWidth = 768;

  return (
    <DateRange
      editableDateInputs={true}
      minDate={new Date()}
      onChange={(item) => {
        setDateRange([item.selection]);
        if (from !== item.selection.startDate) {
          setFieldValue("from", item.selection.startDate)
          setChangeSelection("end")
        }

        if (to !== item.selection.endDate && item.selection.startDate !== item.selection.endDate) {
          setFieldValue("to", item.selection.endDate)
          setChangeSelection("start")
        }

        if (onChange) {
          onChange(item.selection.startDate, item.selection.endDate)
        }
      }}
      disabledDay={(day) => {
        const currentKey = moment(day).format('YYYY-MM-DD')
        const today = moment().format('YYYY-MM-DD')

        if (currentKey < today) {
          return true;
        }

        if (changeSelection === "start") {
          return !availabilities[currentKey]
        } else {
          const startKey = moment(dateRange[0].startDate).format('YYYY-MM-DD')
          return !availabilities[startKey] || !availabilities[startKey]["departures"].includes(currentKey.toString())
        }
      }}
      onShownDateChange={fetchAvailabilities}
      moveRangeOnFirstSelection={false}
      months={2}
      direction={windowWidth < minTabletWidth ? "vertical" : "horizontal"}
      ranges={dateRange}
      locale={i18n.language === "de" ? de : i18n.language === "it" ? it : en}
    />
  );
}

export default OnlineReservationsDateRangePicker;