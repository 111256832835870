// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".Header-module__logo___2ihYG {\n  width: 150px;\n}", "",{"version":3,"sources":["Header.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","file":"Header.module.css","sourcesContent":[".logo {\n  width: 150px;\n}"]}]);
// Exports
exports.locals = {
	"logo": "Header-module__logo___2ihYG"
};
module.exports = exports;
