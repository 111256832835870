import React, {useEffect, useState} from "react";
import Card from "src/components/Cards/Basic";
import {Badge, Button} from "react-bootstrap";
import AdminDashboard from "../../index";
import {useParams} from "react-router-dom";
import history from "../../../../global/history";
import useBusiness from "../util/useBusiness";
import EditShellyDialog from "./EditShellyDialog";
import useModal from "../../../../components/Modal/hooks/useModal";
import {showSuccessToast} from "../../../../components/Toasts";
import IconButton from "../../../../components/IconButton";
import {
  faPencil as PencilIcon,
  faTrash as DeleteIcon,
  faRotateRight as RotateRightIcon
} from "@fortawesome/free-solid-svg-icons";
import ConfirmDeleteDialog from "../../../../components/Modal/ConfirmDelete";
import useShellys from "./hooks/useShellys";
import {dateTimeFormatter} from "../../../../global";

const Shellys = () => {
  const { business_id, id } = useParams();
  const business = useBusiness(business_id);
  const { shellys, fetchShellys, archive, fetchStatus } = useShellys({ business_id: business_id, power_pillar_id: id });
  const { isShow: showEdit, toggle: toggleEdit } = useModal();
  const { isShow: showArchive, toggle: toggleArchive } = useModal();
  const [shelly, setShelly] = useState({});

  useEffect(() => {
    fetchShellys();
  }, []);

  const handleEdited = () => {
    showSuccessToast();
    fetchShellys();
  };

  const handleDelete = () => {
    archive(shelly);
    toggleArchive();
  };

  return (
    <AdminDashboard>
      <Button className="mb-3 mr-2" variant="outline-primary" onClick={() => history.goBack()}>Zurück</Button>
      <Button className="mb-3" variant="primary" onClick={() => {
        toggleEdit();
        setShelly({});
      }}>
        Shelly erstellen
      </Button>
      <Card title={`Shellys von ${business?.name}`}>
        <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
            <thead>
            <tr className="tab_name">
              <th>Name</th>
              <th>IP-Adresse</th>
              <th>Geräte id</th>
              <th>Optionen</th>
            </tr>
            </thead>
            <tfoot>
            <tr className="tab_name">
              <th>Online-Status</th>
              <th>IP-Adresse</th>
              <th>Geräte id</th>
              <th>Optionen</th>
            </tr>
            </tfoot>
            <tbody>
            {shellys?.map((shelly) => (
              <tr key={shelly.id}>
                <td>
                  {shelly.is_online ? <Badge pill bg="primary" text="light">Online</Badge> :
                    (<>
                      { shelly.last_online_at && <p>{dateTimeFormatter(shelly.last_online_at)}</p> }
                        <Badge pill bg="danger" text="light">Offline</Badge>
                    </>)
                  }
                </td>
                <td>{shelly.ip}</td>
                <td>{shelly.device_id}</td>
                <td>
                  <IconButton className="mr-2 text-primary mr-3" icon={PencilIcon} onClick={() => {
                    setShelly(shelly);
                    toggleEdit();
                  }}
                  />
                  <IconButton className="mr-2 text-primary mr-3 text-danger" icon={DeleteIcon} onClick={() => {
                    setShelly(shelly);
                    toggleArchive();
                  }}
                  />
                  <IconButton className="mr-2 text-primary mr-3 text-secondary" icon={RotateRightIcon} onClick={() => { fetchStatus(shelly) }}/>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </Card>
      <EditShellyDialog shelly={shelly} toggle={toggleEdit} show={showEdit} handleSuccess={handleEdited}/>
      <ConfirmDeleteDialog show={showArchive} handleClose={toggleArchive} handleDelete={handleDelete}/>
    </AdminDashboard>
  );
};

export default Shellys;
