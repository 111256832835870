import React from "react";
import {Router, Route, Switch, Redirect} from "react-router-dom";
import DashboardRoutes from "./DashboardRoutes";
import SignIn from "../pages/SignIn";
import ReservationRoutes from "./ReservationRoutes";
import {subdomain} from "../global";
import NotFound from "../pages/NotFound";
import history from "src/global/history";
import ProtectedRoutes from "../protectedRoutes";
import AdminRoutes from "./AdminRoutes";
import Businesses from "../pages/AdminDashboard/Businesses";

const Routes = () => {
  if (subdomain === "admin") {
    return (
      <Router history={history}>
        <Switch>
          <Route path={"/dashboard"} component={AdminRoutes}></Route>
          <Route exact path="/signin" component={SignIn}/>
          <ProtectedRoutes exact path="/" component={Businesses}/>
        </Switch>
      </Router>
    );
  }

  return (
    <Router history={history}>
      <Switch>
        <Route path={"/dashboard"} component={DashboardRoutes}></Route>
        {/* Deactivate Reservation routes if not active */}
        <Route path={"/:lng(de|en|it)?/reservations"} component={ReservationRoutes}></Route>
        <Route exact path="/">
          <Redirect to="/dashboard"/>
        </Route>
        <Route exact path="/signin" component={SignIn}/>
        <Route path="*" component={NotFound}/>
      </Switch>
    </Router>
  );
};

export default Routes;
