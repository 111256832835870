import React, {useEffect, useState} from "react"
import CardInfo from "src/components/Cards/Info"
import Dashboard from "./index"
import {faDog, faEuroSign, faParking, faUsers} from "@fortawesome/free-solid-svg-icons"
import axios from "../../config/axios"
import moment from "moment"

const Stats = () => {
  const [stats, setStats] = useState()

  useEffect(() => {
    fetchStats()
  }, [])

  const fetchStats = () => {
    axios.get(`/api/v1/stats.json`)
    .then(function (response) {
      setStats(response.data.table)
    })
    .catch(function (error) {
      console.log(error)
    })
  }

  return (
    <>
      {stats && (
        <>
          <h1 className="mb-3">Statistiken {moment().year()}</h1>
          <div className="row">
            <CardInfo title="Umsatz pro Tag"
                      icon={faEuroSign}
                      color="success"
                      value={stats.revenue_per_day}
                      hint={`Gestern: ${stats.revenue_yesterday}`}/>

            <CardInfo title="Umsatz / Monat"
                      icon={faEuroSign}
                      color="success"
                      value={stats.revenue_per_month}
                      hint={`Letztes Monat: ${stats.revenue_last_month}`}/>

            <CardInfo title="Umsatz / Jahr"
                      icon={faEuroSign}
                      color="success"
                      value={stats.revenue_per_year}
                      hint={`Letztes Jahr: ${stats.revenue_last_year}`}/>
          </div>

          <div className="row">
            <CardInfo title="Gäste pro Tag"
                      icon={faUsers}
                      color="warning"
                      value={stats.guests_per_day}/>

            <CardInfo title="Gäste pro Monat"
                      icon={faUsers}
                      color="warning"
                      value={stats.guests_per_month}/>

            <CardInfo title="Gäste pro Jahr"
                      icon={faUsers}
                      color="warning"
                      value={stats.guests_per_year}/>
          </div>

          <div className="row">
            <CardInfo title="Besetzte Parkplätze"
                      icon={faParking}
                      color="info"
                      value={`${stats.occupied_parking_slots_count} / ${stats.max_parking_slots}`}/>
            <CardInfo title="Hunde pro Jahr"
                      icon={faDog}
                      color="info"
                      value={`${stats.dogs_per_year}`}
                      hint={`Letztes Jahr: ${stats.dogs_last_year}`}/>
          </div>
        </>
      )}
    </>
  )
}

export default Stats