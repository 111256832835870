import React, {useContext, useEffect, useState} from "react";
import Card from "src/components/Cards/Basic";
import {useOnlineReservations} from "./hooks/useOnlineReservations";
import {dateTimeFormatter} from "../../../../global";
import {faEye as DetailsIcon, faSearch, faPencil as EditIcon, faAdd} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import OnlineReservationDetailsDialog from "./OnlineReservationDetailsDialog";
import {Badge, Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import axios from "../../../../config/axios";
import history from "../../../../global/history";
import SimplePagination from "../../../../components/SimplePagination";
import {DashboardContext} from "../../index";
import OrderButtonDropdown from "../../../../components/UiComponents/Buttons/OrderButtonDropdown";
import TableIconButton from "../../../../components/UiComponents/Buttons/TableIconButton";
import FabPlateau from "../../../../components/UiComponents/Buttons/FabPlateau";
import {keepState} from "../../../../components/UiComponents/Buttons/BackButton/helper";
import {Link} from "react-router-dom";
import FilterResetButton from "../../../../components/UiComponents/Buttons/FilterResetButton";

const OnlineReservationsView = () => {
  const defaultFilter = keepState(history)?.filter_data ?? {status: "requested", page: 1, order: "created_at_desc", parking_slot_id: ""};
  const [filter, setFilter] = useState(defaultFilter);
  const {collection: onlineReservations, fetchCollection, pagination, loading} = useOnlineReservations()
  const [selected, setSelected] = useState(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState(false);
  const {t} = useTranslation("dashboard");
  const [parkingSlots, setParkingSlots] = useState([]);
  const {settings} = useContext(DashboardContext);
  const [filterShow, setFilterShow] = useState(false);
  const [rerender, setRerender] = useState(1);

  const businessSettings = settings?.business;

  const showOnlineReservationDialog = selected !== null;

  useEffect(() => {
    fetchCollection(filter)
  }, [filter]);

  useEffect(() => {
    if (businessSettings?.parking_slots_enabled) {
      axios.get("/api/v1/parking_slots.json").then(function (response) {
        setParkingSlots(response.data.entries);
      })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [businessSettings])

  function renderFilters() {
    if (!filterShow) {
      return (<>
      </>)
    }
    return (<>
      <Row className="optional-filter-container align-items-center">
        {businessSettings?.parking_slots_enabled && parkingSlots.length > 0 && (
          <Col md={3} xs={12}>
            <Form.Group className="mb-3">
              <Form.Label>Stellplatz</Form.Label>
              <Form.Select className="form-control ui-input-bg" value={filter.parking_slot_id} onChange={(e) => {
                setFilter({
                  ...filter,
                  parking_slot_id: e.target.value === "null" ? undefined : e.target.value,
                  page: 1
                });
              }}>
                <option value="null">Alle</option>
                {parkingSlots.map((parkingSlot) => (
                  <option key={parkingSlot.id} value={parkingSlot.id}>{parkingSlot.name}</option>
                ))}
              </Form.Select>
              <Form.Text className="text-muted">&nbsp;</Form.Text>
            </Form.Group>
          </Col>
        )}
      </Row>
    </>)
  }

  const goToPage = (page) => {
    setFilter((prev) => {
      return {...prev, page: page}
    })
  };

  const header = (<div className="d-flex flex-wrap w-100 justify-content-between align-items-center">
    <span>Reservierungen</span>
    <FilterResetButton resetCallback={() => {setFilter(defaultFilter);}} />
  </div>);


  return (
    <>
      <Card title={header}>
        <Row className="filter-container">
          <Col md={3} xs={12} className="d-flex mb-4 mb-sm-0 justify-content-between justify-content-sm-start">
            <OrderButtonDropdown selected={filter.order} orders={[
              {key: "created_at_desc", value: "Neueste zuerst"},
              {key: "created_at_asc", value: "Älteste zuerst"},
              {key: "from_asc", value: "Anreisedatum aufsteigend"},
              {key: "from_desc", value: "Anreisedatum absteigend"}
            ]} callback={(selected_value) => {
              setFilter({
                ...filter,
                order: selected_value
              });
            }}/>
            {/*<button type="button" className="btn btn-outline-secondary btn-sm ml-2"*/}
            {/*        onClick={() => setFilterShow(!filterShow)}>*/}
            {/*  <FontAwesomeIcon icon={faFilter}/>*/}
            {/*  <span className="ml-2">{!filterShow ? "Einblenden" : "Ausblenden"}</span>*/}
            {/*</button>*/}
          </Col>
          <Col md={3} xs={12}>
            <Form.Group className={"mb-3"}>
              <Form.Label>Status</Form.Label>
              <Form.Select className={`form-control ui-input-bg`} value={filter.status}
                           onChange={e => {
                             setFilter({...filter, status: e.target.value === "null" ? undefined : e.target.value})
                           }}>
                <option value="null">Alle</option>
                <option value="requested">Angefragt</option>
                <option value="accepted">Akzeptiert</option>
                <option value="declined">Abgelehnt</option>
                <option value="redeemed">Check-In</option>
              </Form.Select>
              <Form.Text className="text-muted">&nbsp;</Form.Text>
            </Form.Group>
          </Col>
          {businessSettings?.parking_slots_enabled && parkingSlots.length > 0 && (
            <Col md={3} xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Stellplatz</Form.Label>
                <Form.Select className={`form-control ui-input-bg`} value={filter.parking_slot_id} onChange={(e) => {
                  setFilter({
                    ...filter,
                    parking_slot_id: e.target.value === "null" ? null : e.target.value,
                    page: 1
                  });
                }}>
                  <option value="null">Alle</option>
                  {parkingSlots.map((parkingSlot) => (
                    <option key={parkingSlot.id} value={parkingSlot.id}>{parkingSlot.name}</option>
                  ))}
                </Form.Select>
                <Form.Text className="text-muted">&nbsp;</Form.Text>
              </Form.Group>
            </Col>
          )}
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Nach Reservierungen suchen</Form.Label>
              <div className="position-relative d-flex">
                <div className="position-absolute p-2">
                  <FontAwesomeIcon icon={faSearch}/>
                </div>
                <Form.Control placeholder="Nach Vorname, Nachname, E-Mail oder Stellplatz suchen" type="text"
                              className="ui-input-bg input-icon-p"
                              onChange={(e) => setFilter({...filter, search: e.target.value, page: 1})}/>
              </div>
              <Form.Text className="text-muted">&nbsp;</Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <div className='ui-table-container'>
          <table>
            <thead>
            <tr>
              <th>#</th>
              <th>Stellplatz</th>
              <th>Kontaktperson</th>
              <th>E-Mail</th>
              <th>Anreise</th>
              <th>Abreise</th>
              <th>Erstellt am</th>
              <th>Optionen</th>
            </tr>
            </thead>
            <tbody>
            {
              loading ? (
                  <>
                    {Array.from({length: 10}, (_, i) => (
                      <tr key={i}>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                        <td>
                          <div className={"preview w-100px h-25px"}></div>
                        </td>
                      </tr>
                    ))}
                  </>
                )
                :
                <>
                  {
                    onlineReservations.map((onlineReservation) => (
                      <tr key={onlineReservation.id}
                          style={{backgroundColor: onlineReservation.to < new Date() ? "#ffd5cf" : "none"}}>
                        <td>
                          <div>{onlineReservation.id}</div>
                          <Badge pill bg={onlineReservation.state === 'requested' ? 'secondary' : 'primary'}
                                 text="light">{t(`online_reservation.statuses.${onlineReservation.status}`)}</Badge>
                        </td>
                        <td>{onlineReservation.parking_slot?.name}</td>
                        <td>{onlineReservation.contact_person}</td>
                        <td>{onlineReservation.contact_email}</td>
                        <td>{dateTimeFormatter(onlineReservation.from)}</td>
                        <td>{dateTimeFormatter(onlineReservation.to)}</td>
                        <td>{dateTimeFormatter(onlineReservation.created_at)}</td>
                        <td>
                          <TableIconButton icon={DetailsIcon} variant={"primary"} onClick={() => {
                            setSelected(onlineReservation);
                            setShowDetailsDialog(true)
                          }}/>

                          { onlineReservation.status !== "redeemed" &&
                            <Link to={{
                              pathname: `/dashboard/reservations/${onlineReservation.id}/edit`,
                              state: {...keepState(history), filter_data: filter}
                            }}>
                              <TableIconButton icon={EditIcon}/>
                            </Link>
                          }
                        </td>
                      </tr>
                    ))
                  }
                </>
            }
            </tbody>
          </table>
          <SimplePagination pagination={pagination} onClick={(page) => goToPage(page)}/>
        </div>
        {/*<Row className="justify-content-end mr-2 mt-5">*/}
        {/*  <FabPlateau child={(<Button className="float-right p-2 pl-3 pr-3" variant="primary" onClick={() => {*/}
        {/*    history.push("/dashboard/reservations/new");*/}
        {/*  }}>*/}
        {/*    <FontAwesomeIcon icon={faAdd}/> &nbsp;Reservierung erstellen*/}
        {/*  </Button>)}/>*/}
        {/*</Row>*/}
      </Card>

      {showOnlineReservationDialog &&
        <OnlineReservationDetailsDialog onlineReservationId={selected.id}
                                        handleClose={() => setSelected(null)}
                                        handleSubmit={() => {
                                          fetchCollection(filter);
                                          setSelected(null);
                                        }}/>

      }
    </>
  )
    ;
};

export default OnlineReservationsView;
