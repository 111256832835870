import React, {useEffect, useState} from "react";
import Dashboard from "src/pages/Dashboard";
import AdminDashboard from "src/pages/AdminDashboard/";
import axios from "../../../config/axios";
import {dateFormatter, dateTimeFormatter} from "../../../global";
import {domain} from "../../../global";
import {Button, Card} from "react-bootstrap";
import history from "../../../global/history";

const Businesses = () => {
  const [businesses, setBusinesses] = useState();

  const getBusinesses = () => {
    axios.get(`/api/v1/admin/businesses.json`)
    .then(function (response) {
      setBusinesses(response.data.entries);
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  useEffect(() => {
    getBusinesses();
  }, []);

  return (
    <AdminDashboard>
      <h1 className="h3 mb-4 text-gray-800">Alle Kunden</h1>

      <Button className="mb-3" variant="primary" onClick={() => history.push("/dashboard/businesses/new")}>Kunde anlegen</Button>
      <Card title={`Alle Kunden`}>
        <div className="table-responsive">
          <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
            <thead>
            <tr className="tab_name">
              <th>ID</th>
              <th>Name</th>
              <th>Kontakt Nr.</th>
              <th>Ablaufdatum</th>
              <th>Subdomain</th>
              <th>Modell</th>
              <th>Erstellt</th>
              <th>Aktualisiert</th>
              <th>Optionen</th>
            </tr>
            </thead>
            <tfoot>
            <tr className="tab_name">
              <th>ID</th>
              <th>Name</th>
              <th>Kontakt Nr.</th>
              <th>Ablaufdatum</th>
              <th>Subdomain</th>
              <th>Modell</th>
              <th>Erstellt</th>
              <th>Aktualisiert</th>
              <th>Optionen</th>
            </tr>
            </tfoot>
            <tbody>
            {businesses?.map((business) => (
              <tr key={business.id} className="tab_name">
                <td>{business.id}</td>
                <td>{business.name}</td>
                <td>{business.contact_nr}</td>
                <td>{dateFormatter(business.expire_at)}</td>
                <td><a href={`https://${business.subdomain}.${domain}/dashboard`}>{business.subdomain}</a></td>
                <td>{business.pricing_model}</td>
                <td>{dateTimeFormatter(business.created_at)}</td>
                <td>{dateTimeFormatter(business.updated_at)}</td>
                <td>
                  <Button className="mr-2" type="submit" variant="primary" onClick={() => history.push(`/dashboard/businesses/${business.id}/alerts`)}>Alerts</Button>
                  <Button className="mr-2" type="submit" variant="primary" onClick={() => history.push(`/dashboard/businesses/${business.id}/edit`)}>Bearbeiten</Button>
                  <Button type="submit" variant="secondary" onClick={() => history.push(`/dashboard/businesses/${business.id}/bookingsettings`)}>Einstellungen</Button>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      </Card>
    </AdminDashboard>
  );
};

export default Businesses;