import React, {useEffect, useState} from 'react';
import MultiSelectBoxItem from "./MultiSelectBoxItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown, faCaretUp} from "@fortawesome/free-solid-svg-icons";


export default function MultiSelectBox({label = "", options = [], onChange, defaultValues = [], isLoading = false, optAllSelectNothing = true, applyButton = false, applyFilterText = "Anwenden", optIdPrefix = null, optAllSelectedLabel = ""}) {
  const [selectedValues, setSelectedValues] = useState(
    options.filter(option => defaultValues.includes(option.value)).map(option => option.value)
  );

  const [showMenu, setShowMenu] = useState(false);
  const idPrefix = optIdPrefix ?? label.replace(' ', '');
  const selectedValueObjects = options.filter(item => selectedValues.includes(item.value)).map(item => {return {name: item.name, color: item?.color ?? 'var(--dark)', value: item.value}});

  const SelectAll = () => {
    setSelectedValues(options.map(option => option.value));
  };

  function UnSelectAll() {
    setSelectedValues([]);
  }

  function notify() {
    if (optAllSelectNothing && selectedValues.length === options.length) {
      onChange([]);
    } else {
      onChange(selectedValues);
    }
  }

  useEffect(() => {
    if (!applyButton || !isLoading) {
      notify();
    }
  }, [selectedValues]);

  function clickAway(e) {
    if ((document.getElementById(idPrefix + 'multiSelectBox'))?.contains(e.target) || (document.getElementById(idPrefix + 'multiSelectButton'))?.contains(e.target)) {
      return;
    }
    setShowMenu(false);
  }

  function onSelect(value) {
    if (value === 'all') {
      if (selectedValues.length !== options.length) {
        SelectAll();
      } else {
        UnSelectAll();
      }
    } else if (!selectedValues.includes(value)) {
      setSelectedValues(prev => [...prev, value]);
    } else {
      setSelectedValues(prev => prev.filter(item => item !== value));
    }
  }

  useEffect(() => {
    window.addEventListener('click', clickAway);
    return () => {
      window.removeEventListener('click', clickAway);
    }
  }, []);

  return (
    <div className="dropdown">
      <button id={idPrefix + "multiSelectButton"} className="btn btn-white border border-secondary"
              type="button"
              onClick={() => setShowMenu(!showMenu)}>
        <b>Filter: </b>{label}
        {!showMenu && <FontAwesomeIcon className="ml-2" icon={faCaretDown}/>}
        {showMenu && <FontAwesomeIcon className="ml-2" icon={faCaretUp}/>}
      </button>
      {(selectedValueObjects.length !== options.length && selectedValueObjects.length !== 0) && <p className="ui-font-small font-weight-normal ui-mt-0_5 ui-ml-0_2">
        Gewählt:&nbsp;
        <span className="font-weight-bold">
          {selectedValueObjects.map((selectedItem, index) => (
            <span style={{color: selectedItem.color}} key={selectedItem.value}>{selectedItem.name}{index !== selectedValueObjects.length-1 && ', '}</span>
          ))}
        </span>
      </p>}
      {(selectedValueObjects.length === options.length || selectedValueObjects.length === 0) && <p className="ui-font-small font-weight-normal ui-mt-0_5 ui-ml-0_2">
        &nbsp;{optAllSelectedLabel}
      </p>}
      {showMenu && (
        <div className="dropdown-menu d-block" id={idPrefix + "multiSelectBox"}>
          {options.length > 1 && (
            <div className="border-bottom ui-border-color-c-gray"
                 onClick={() => onSelect('all')}>
              <MultiSelectBoxItem label="Alle" value="all" selected={selectedValues.length === options.length} onClick={() => onSelect('all')} />
            </div>
          )}
          {options.map((option) => (
              <MultiSelectBoxItem key={option.value} selected={selectedValues.includes(option.value)} onSelect={() => onSelect(option.value)} label={option.name} value={option.value} color={option?.color ?? 'transparent'} />
          ))}
          {applyButton && (
            <div className="w-100 d-flex p-1 px-2 justify-content-end">
              <button className="btn btn-sm btn-primary rounded-lg d-flex justify-content-center align-items-center"
                      type="button" onClick={() => {
                notify();
                setShowMenu(!showMenu)
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                     stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                     className="icon-tabler icons-tabler-outline icon-tabler-filter-check mr-1">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                  <path
                    d="M11.18 20.274l-2.18 .726v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v3"/>
                  <path d="M15 19l2 2l4 -4"/>
                </svg>
                {applyFilterText}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}