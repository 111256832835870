import React, { useEffect, useState } from "react";
import Card from "src/components/Cards/Basic";
import { Button, Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";
import AdminDashboard from "../../index";
import axios from "src/config/axios";
import {showErrorToast, showSuccessToast} from "../../../../components/Toasts";
import InputText from "../../../../components/Forms/InputText";
import {useParams} from "react-router-dom";
import history from "../../../../global/history";

const AdminBookingSettings = () => {
  const {id} = useParams();
  const [bookingSettings, setBookingSettings] = useState([]);
  const [business, setBusiness] = useState();

  useEffect(() => {
    getBusiness();
    fetchBookingSettings();
  }, []);

  const getBusiness = () => {
    axios.get(`/api/v1/admin/businesses/${id}.json`)
      .then(function(response) {
        setBusiness(response.data);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  const fetchBookingSettings = () => {
    axios.get(`/api/v1/admin/businesses/${id}/booking_settings.json`)
      .then(function(response) {
        setBookingSettings(response.data.entries);
      })
      .catch(function(error) {
        console.log(error);
      });
  };

  return (
    <AdminDashboard>
      <Row>
        <Col>
          <h1 className="h3 mb-4 text-gray-800">Einstellungen von {business?.name}</h1>
          <Button className="mb-3 mr-2" variant="outline-primary" onClick={() => history.push("/dashboard")}>Zurück</Button>
        </Col>
      </Row>
      <Row>
        {bookingSettings?.map((bookingSetting, index) => (
          <Col key={index} xs={12} sm={3}>
            <Card title={bookingSetting.formatted_name}>
              <Formik
                initialValues={bookingSetting}
                enableReinitialize={true}
                onSubmit={(booking_setting, { setSubmitting }) => {
                  setSubmitting(true);

                  axios.put(`/api/v1/admin/businesses/${id}/booking_settings/${bookingSetting.id}.json`, { booking_setting: booking_setting, id: booking_setting.id })
                    .then(function() {
                      showSuccessToast();
                    })
                    .catch(function() {
                      showErrorToast();
                    });
                }}
              >
                {({ values }) => (
                  <Form name="booking_setting" autoComplete="false">
                    <input type="hidden" value={bookingSetting.id} />

                    <InputText name="value" label="Wert" type="text" />
                    <Button type="submit" variant="primary">Bestätigen</Button>
                  </Form>
                )}
              </Formik>
            </Card>
          </Col>
        ))}
      </Row>
    </AdminDashboard>
  );
};

export default AdminBookingSettings;
